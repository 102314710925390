import styled, { keyframes } from 'styled-components';

interface InputProps {
  size?: number;
  showPassword?: boolean;
}

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const TextBoxLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 100%;
  height: 3em;
`;

export const Container = styled.div`
`;

export const Label = styled.label`
  color: ${(props) => props.theme.color.dark};
  letter-spacing: 1px;
  align-self: flex-start;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Altere para space-between ou space-around */
  background-color: white;
  border: ${(props) => props.theme.color.borderUnfocused};
  height: 3.5em;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  padding-left: 1em;
  padding-right: 0.5em;
  gap: 1em;
  &:focus {
    border-color: ${(props) => props.theme.color.primary};
  }
`;

export const ContainerText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center; /* Alinha o conteúdo verticalmente ao centro */
`;


export const PositionContainer = styled.div`
  color: ${(props) => props.theme.color.inputEndIconColor};
  opacity: 50%;
  font-size: 1em;
`;

export const IconContainer = styled.div`
  color: ${(props) => props.theme.color.inputEndIconColor};
  font-size: 1.5em;
`;

export const ErrorMessage = styled.small<{ showPassword?: boolean }>`
  color: ${(props) => props.theme.color.error};
  padding-top: ${(props) => (props.showPassword ? '0' : '6px')};
  letter-spacing: 1px;
  align-self: flex-start;
`;
