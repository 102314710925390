import IUseCase from '../IUseCase';
import * as colaboratorService from '../../infrastructure/api/services/colaboratorService';

export default class DeleteColaboratorUseCase
  implements IUseCase<number, Promise<any>>
{
  execute(colaboratorId: number) {
    return colaboratorService.deleteCoworker(colaboratorId);
  }
}
