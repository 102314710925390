import {
  faClock,
  faDollar,
  faFileText,
  faPeopleGroup,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import GetCategories from '../../@core/application/category/getCategories';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import {
  default as DeleteServiceImageInput,
  default as DeleteServiceImageUseCase,
} from '../../@core/application/services/deleteServiceImage';
import GetServiceById from '../../@core/application/services/getServicesbyId';
import UpdateService from '../../@core/application/services/updateService';
import UploadServiceImage from '../../@core/application/services/uploadServiceImage';
import Category from '../../@core/domain/model/Category';
import Colaborator from '../../@core/domain/model/Colaborator';
import ErrorApi from '../../@core/domain/model/ErrorApi';
import Service from '../../@core/domain/model/Service';
import ImageGallery from '../../@core/domain/ui/ImageGallery';
import RegisterServiceForm, {
  MapToService,
} from '../../@core/domain/ui/RegisterServiceForm';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import { GalleryImages } from '../../components/Gallery';
import MultipleSelect from '../../components/MultipleSelect';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import SelectBox from '../../components/Selectbox';
import Textarea from '../../components/Textarea';
import Textbox from '../../components/TextboxMUI';
import { TextBoxLoader } from '../../components/TextboxMUI/styles';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Container,
  GalleryImagesLoader,
  GalleryImagesLoaderContainer,
  Header,
  HeaderContainer,
  IconContainer,
  Instructiontext
} from './styles';
import validationServiceForm from './validation';
import FooterMenu from '../../components/Menus/FooterMenu';
import { MdOutlineAttachMoney } from "react-icons/md";
import { DragGallery } from '../../components/DragGallery';
import Positions, { Position } from '../../@core/domain/model/Positions';
import OrganizeServiceImages from '../../@core/application/services/organizeServiceImages'
import OrganizeServicesInput from '../../@core/application/services/organizeServiceImages';

const initialService: RegisterServiceForm = {
  name: '',
  description: '',
  collaboratorsOptions: [],
  price: 0,
  genre: '',
  duration: '',
  ageGroup: '',
  categoryId: 0,
  specializationService: false,
};
export default function ServiceUpdate() {
  const { id } = useParams();

  const [serviceForm, setServiceForm] = useState<RegisterServiceForm>(initialService);
  const [serviceId, setServiceId] = useState<number>(Number(id));
  const [service, setService] = useState<Service>();

  const [newImages, setNewImages] = useState<ImageGallery[]>([]);
  const [currentImages, setCurrentImages] = useState<ImageGallery[]>([]);
  const [removedImages, setRemovedImages] = useState<ImageGallery[]>([]);
  const [images, setImages] = useState<ImageGallery[]>([]);

  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [serviceLoading, setServiceLoading] = useState(true);
  const [categories, setCategories] = useState([] as Category[]);

  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const navigate = useNavigate();


  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: serviceForm,
    validationSchema: validationServiceForm,
    onSubmit: async (values: RegisterServiceForm) => {
      const service = MapToService(values);
      service.id = serviceId;


      const colaboratorsIds = values?.collaboratorsOptions?.map(name => {
        return colaborators.filter(colaborator => colaborator.name == name)[0].id ?? 0
      }).filter(item => item !== 0);

      service.collaboratorsIds = colaboratorsIds

      await updateService(service, newImages, removedImages);
    },
  });

  useEffect(() => {
    console.log("[tracking] imagesPositions", images)
  }, [images]);

  const findServiceById = useCallback(
    async (id: Number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }


      const getUseCase = new GetServiceById();
      await getUseCase
        .execute(id)
        .then((response: any) => {
          var service = response.data;
          console.log("configurando valores iniciais ", service)
          console.log("configurando valores iniciais - colaboradores ", service.specializedColaborators?.map((sc: any) => sc.name))

          setService(service);
          setServiceLoading(false);
          setLoading(false);
          setCurrentImages(service.images);
          setServiceForm({
            id: service.id,
            name: service.name,
            description: service.description,
            price: service.price,
            genre: service.genre,
            duration: service.duration.toString(),
            ageGroup: service.ageGroup,
            categoryId: service.category.id,
            specializationService: service.specializationService,
            collaboratorsOptions: service.specializedColaborators?.map((sc: any) => sc.name),
          });
        })
        .catch((err: any) => {
          setLoading(false);
          setServiceLoading(false);
          if (err?.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [setService, setLoading]
  );

  const findCategories = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getCategoriesUseCase = new GetCategories();
    getCategoriesUseCase
      .execute()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        console.log('Erro ao requisitar categorias', err);
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getColaboratorsUseCase = new GetColaborator();
    getColaboratorsUseCase
      .execute()
      .then((response: any) => {
        setColaborators(response.data);
      })
      .catch((err) => {
        console.log('Erro ao recuperar colaboradores');
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  useEffect(() => {
    setServiceId(serviceId);
    findServiceById(serviceId);
    findColaborators();
    findCategories();
  }, [serviceId]);


  const removeServiceImages = useCallback(
    async (images: ImageGallery[], serviceId: number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      if (images.length == 0) return;

      const deleteServiceUseCase = new DeleteServiceImageUseCase();

      for (let i = 0; i < images.length; i++) {
        const imageId = images[i].id ?? 0;

        await deleteServiceUseCase
          .execute({
            serviceId: serviceId,
            imageId: imageId,
          } as DeleteServiceImageInput)
          .then((res) => {
            console.log('Imagem removida com sucesso: ', imageId);
          })
          .catch((err) => {
            console.log('Falha ao remover imagem: ', imageId);
            if (err.response.status == 401) {
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          });
      }
    },
    []
  );

  const updatePositions = useCallback(async (service: any, uploadedImages: any) => {

    console.log("imagesPositions", images)
    
    const positions = images.map((position: any, index) => {
        let id = 0;
        
        if(position.isNew){
          console.log("position nova", position)
          const uploadedImage = uploadedImages.filter((uploadedImage: any) => uploadedImage.imageValue === position.valueImage)[0]
          console.log("uploadedImage", uploadedImage)

          id = uploadedImage?.storageResult?.filesSuccessfully?.length > 0 ? uploadedImage.storageResult.filesSuccessfully[0].id : undefined;
          console.log("idGerado", id)
        } else {
          id = position.id; 
        }

        return {
          id: id,
          position: index
        } as Position
    });
    
    const organizeServiceImagesUseCase = new OrganizeServiceImages();

    await organizeServiceImagesUseCase.execute({
      serviceId: service.id ?? 0,
      positions: {
        positions: positions
      } as Positions
    } as OrganizeServicesInput).then(res => {
    })
  }, [images])

  const uploadServiceImage = useCallback(
    async (images: ImageGallery[], serviceId: number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      if (images.length == 0) return;

      const uploadImage = new UploadServiceImage();

      const formData = new FormData();
      formData.append('establishmentId', getEstablishmentId() ?? '');
      formData.append('serviceId', serviceId.toString());

      images.forEach((img) => {
        formData.append('fileImages', img.file || '');
        formData.append('position', img.position?.toString() || '');
      });

      return await uploadImage
        .execute(formData)
        .then((res) => { 
          return res.data
        })
        .catch((err) => {
          alert('Erro ao fazer upload da imagem');
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    []
  );

  const updateService = useCallback(
    async (
      service: Service,
      newImages: ImageGallery[],
      removedImages: ImageGallery[]
    ) => {
      setServiceLoading(true)
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const updateService = new UpdateService();
      setLoading(true);
      await removeServiceImages(removedImages, service.id!);
      setRemovedImages([]);

      await updateService
        .execute(service)
        .then(async (response) => {
          const uploadedImages = [] as any[];

          console.log("newImages", newImages)

          for (let i = 0; i < newImages.length; i++) {
            let newImageIndex = 0
            
            //Obter posição
            for (let j = 0; j < images.length; j++) {
              if(images[j].valueImage == newImages[i].valueImage){
                newImageIndex = j
              }
            }
            
            //Realizar upload da imagem
            const storageResult = await uploadServiceImage([{
              ...newImages[i],
              position: newImageIndex
            }], service.id!);

            //Salvar resultado com ID da imagem
            uploadedImages.push({
              ...storageResult,
              imageValue: newImages[i].valueImage
            });
          }

          await updatePositions(service, uploadedImages)

          setNewImages([])
          await findServiceById(serviceId);  
          
          showAlert.success('Serviço atualizado com sucesso');
          setServiceLoading(false)
        })
        .catch((errorResponse: AxiosError<ErrorApi>) => {
          errorResponse.response?.data.erros.forEach((error) => {
            showAlert.error(error);
          });

          if (errorResponse?.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };

          setLoading(false);
        });
    },
    [setLoading, removeServiceImages, navigate, updatePositions, findServiceById, serviceId, uploadServiceImage, images, showAlert]
  );

  const addImageCallback = useCallback((newImageValue: string | ArrayBuffer | null, file: File) => {
    if (validateImage(file)) {
      let newImg = { 
        id: Math.floor(Math.random() * 1000000),
        valueImage: newImageValue, 
        isNew: true, 
        file 
      } as ImageGallery;
      setNewImages(prevImages => [...prevImages, newImg]);
    }
  }, [setNewImages]);

  const validateImage = (image: File) => {
    const MAX_FILE_SIZE_MB = 8;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    console.log("tamanho imagem: ", image.size)
    console.log("MAX_FILE_SIZE_BYTES: ", MAX_FILE_SIZE_BYTES)

    if (image.size > MAX_FILE_SIZE_BYTES) {
      console.log(`Imagem excede o tamanho máximo de ${MAX_FILE_SIZE_MB}mb.`)
      showAlert.error('Imagem excede o tamanho máximo de 8mb.');
      return false;
    }

    return true
  };

  const removeImageCallback = useCallback((image: ImageGallery) => {
    if (image.isNew) {
      setNewImages(prevImages => prevImages.filter(img => img.valueImage !== image.valueImage));
    }

    if (!image.isNew) {
      setCurrentImages(prevImages => prevImages.filter(img => img.id !== image.id));
      setRemovedImages((prevImages => [...prevImages, image]));
    }

  }, [setNewImages]);


  useEffect(() => {
    setImages([
      ...currentImages.sort((a: any, b: any) => a.position - b.position),
      ...newImages,
    ])
  }, [currentImages, newImages])

  return (
    <Container>
      <HeaderContainer>
        <Header>Serviços</Header>
        <Instructiontext>Edição do serviço</Instructiontext>
      </HeaderContainer>
     
      {serviceLoading ? (
        <GalleryImagesLoaderContainer>
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
        </GalleryImagesLoaderContainer>
      ) : (
        <DragGallery
          images={images}
          imagesPositionsCallback={setImages}
          addImageCallback={addImageCallback}
          removeImageCallback={removeImageCallback}
        />
      )}

      <Form>
        {serviceLoading ? (
          <>
            <TextBoxLoader />
            <TextBoxLoader />
            <TextBoxLoader />
            <TextBoxLoader />
            <TextBoxLoader />
            <TextBoxLoader />
          </>
        ) : (
          <>
            <Textbox
              placeholder="Nome do serviço"
              name="name"
              icon={faUser}
              value={registerForm.values.name}
              setValue={registerForm.handleChange}
              error={registerForm.errors.name}
            />

            <Textarea
              placeholder="Descrição do serviço"
              name="description"
              value={registerForm.values.description}
              setValue={registerForm.handleChange}
              error={registerForm.errors.description}
              icon={faFileText}
              limit={255}
            />

            <Textbox
              placeholder="Preço do serviço"
              name="price"
              type="number"
              icon={faDollar}
              value={registerForm.values.price}
              setValue={registerForm.handleChange}
              error={registerForm.errors.price}
              iconx={<IconContainer>
                <MdOutlineAttachMoney />
              </IconContainer>}
            />

            <Textbox
              placeholder="Duração do serviço"
              name="duration"
              type="number"
              icon={faClock}
              value={registerForm.values.duration}
              setValue={registerForm.handleChange}
              error={registerForm.errors.duration}
            />
            {/* <SelectBox
              placeholder="Categoria do serviço"
              name="categoryId"
              value={registerForm.values.categoryId}
              setValue={registerForm.handleChange}
              icon={faLayerGroup}
              error={registerForm.errors.categoryId}
            >
              <option value={0}>Escolha a categoria</option>
              {categories.map((category) => (
                <option value={category.id}>{category.category}</option>
              ))}
            </SelectBox> */}

            <MultipleSelect
              label="Escolha os colaboradores"
              name="collaboratorsOptions"
              value={serviceForm.collaboratorsOptions}
              setValue={(value: string) => registerForm.setFieldValue("collaboratorsOptions", value)}
              icon={faUsers}
              options={colaborators.map((colaborator) => {
                return { value: colaborator.id, label: colaborator.name };
              })}
            />

            <SelectBox
              label="Genero"
              placeholder="Genero"
              name="genre"
              value={registerForm.values.genre}
              setValue={registerForm.handleChange}
              error={registerForm.errors.genre}
              items={[
                {
                  "value": "MASCULINE",
                  "label": "Masculino"
                },
                {
                  "value": "FEMALE",
                  "label": "Feminino"
                },
                {
                  "value": "NON_BINARY",
                  "label": "Não-Binário"
                },
                {
                  "value": "AGENDER",
                  "label": "Agênero"
                },
                {
                  "value": "BIGENDER",
                  "label": "Bigênero"
                },
                {
                  "value": "OTHERS",
                  "label": "Outros"
                },
                {
                  "value": "ALL_GENRE",
                  "label": "Todos"
                }
            ]}
            />

            <SelectBox
              placeholder=""
              name="ageGroup"
              value={registerForm.values.ageGroup}
              setValue={registerForm.handleChange}
              icon={faPeopleGroup}
              error={registerForm.errors.ageGroup}
              items={[
                {
                  value: "ADULT",
                  label: "Adulto"
                },
                {
                  value: "ADOLESCENT",
                  label: "Adolescente"
                },
                {
                  value: "KID",
                  label: "Criança"
                },
                {
                  value: "ALL",
                  label: "Todos"
                }
              ]}
            />
          </>
        )}

        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Confirmar
        </Button>
      </Form>
      <FooterMenu />
    </Container>
  );
}
