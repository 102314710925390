import { Container, LeftSide, RightSide, ShowContent } from './styles';

interface OffsiteParams {
  children: React.ReactNode;
}

export default function Offsite({ children }: OffsiteParams) {
  return (
    <Container>
      <LeftSide>{children}</LeftSide>
    </Container>
  );
}
