import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as associatePreviousService from '../../infrastructure/api/services/previousServiceService';

export default interface AssociatePreviousServiceInput {
  formData: FormData;
  finalClientId: number;
}

export default class AssociatePreviousServiceUseCase
  implements IUseCase<AssociatePreviousServiceInput, Promise<any>>
{
  execute(input: AssociatePreviousServiceInput) {
    return associatePreviousService.associatePreviousService(
      input.formData,
      input.finalClientId
    );
  }
}
