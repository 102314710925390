import IUseCase from '../IUseCase';
import * as finalClientService from '../../infrastructure/api/services/finalClientService';
import { AxiosResponse } from 'axios';
import Schedule from '../../domain/model/Schedule';
import FinalClient from '../../domain/model/FinalClient';

export default interface SearchFinalClientInput {
  terms?: string;
  perPage?: number;
  page?: number;
  sort?: string;
}

export default class GetFinalClient
  implements
    IUseCase<
      SearchFinalClientInput | undefined,
      Promise<AxiosResponse<FinalClient[]>>
    >
{
  execute(searchFinalClientInput?: SearchFinalClientInput) {
    return finalClientService.getFinalClients(searchFinalClientInput);
  }
}
