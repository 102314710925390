import {
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useCallback } from 'react';
import { Option } from 'react-multi-select-component';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import {
  Container,
  Content,
  ErrorMessage,
  MultiSelect
} from './styles';

import { Theme, useTheme } from '@mui/material/styles';

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value: any;
  setValue: (e: any) => void;
  name?: string;
  icon?: IconDefinition;
  options: Option[];
}

export default function MultipleSelect({
  label,
  id,
  error,
  value,
  setValue,
  name,
  icon,
  options,
}: TextboxParams) {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();
  const [currentItems, setCurrentItems] = React.useState<string[]>([]);

  // const handleChange = (event: SelectChangeEvent<typeof currentItems>) => {
  //   const { target: { value } } = event;
  //   setCurrentItems(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const handleChange = (event: SelectChangeEvent<typeof currentItems>) => {
    const { target: { value } } = event;

    setValue(value);

    setCurrentItems(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  React.useEffect(() => {
    if(value){
      setCurrentItems(value)
    }

  }, [])


  function getStyles(name: string, currentItems: readonly string[], theme: Theme) {
    return {
      fontWeight:
        currentItems.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <Container id={`${id}-container`}>
      <Content>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={currentItems}
          onChange={handleChange}
          input={<MultiSelect label={label} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {options.map((options) => (
            <MenuItem key={name} value={options.label}>
              <Checkbox checked={currentItems.indexOf(options.label) > -1} />
              <ListItemText primary={options.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>



        {/* {icon && (
          <FontAwesomeIcon
            style={{ position: 'absolute', top: '18px', left: '15px' }}
            color="#5D17EB"
            icon={icon}
          />
        )}
        <SelectContainer>
          <MultiBox
            options={options}
            value={value}
            onChange={(newValue: any) => setValue(newValue)}
            labelledBy="Select"
            overrideStrings={{ selectSomeItems: label ?? '' }} // <- to override strings
          />
        </SelectContainer> */}
       
       

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Content>
    </Container>
  );
}
