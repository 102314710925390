import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { FiMapPin } from "react-icons/fi";
import { IoStorefrontOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  Container,
  Header,
  HeaderContainer,
  IconContainer,
  Instructiontext,
  TabContainer
} from './styles';

import EstablishmentAddressForm from './EstablishmentAddressForm';
import EstablishmentDataForm from './EstablishmentDataForm';
import FooterMenu from '../../components/Menus/FooterMenu';



export default function EstablishmentInformations() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [tabSelected, setTabSelected] = useState(1);
  const { theme } = useContext(ThemeContext);
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <HeaderContainer>
        <Header>Estabelecimento</Header>
        <Instructiontext>Gerencie as informações do seu estabelecimento</Instructiontext>
      </HeaderContainer>


      <TabContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{
            display: 'flex',
            borderBottom: 1,
            borderColor: 'InfoText',
            justifyContent: 'center',
            borderWidth: '0.1em'
          }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon label tabs example"
              textColor="inherit"
              indicatorColor="primary"
            >
              <Tab onClick={() => setTabSelected(0)} sx={{ width: "50%", fontWeight: "bold", color: `${tabSelected == 1 ? `${theme?.globalStyles.colors.primary}` : `${theme?.pages.profile.textColor}`}` }}
                icon={<IconContainer><IoStorefrontOutline /></IconContainer>} label="Dados cadastrais" />
              <Tab onClick={() => setTabSelected(1)} sx={{ width: "50%", fontWeight: "bold", color: `${tabSelected == 2 ? `${theme?.globalStyles.colors.primary}` : `${theme?.pages.profile.textColor}`}` }}
                icon={<IconContainer><FiMapPin /></IconContainer>} label="Endereço" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0} >
            <EstablishmentDataForm />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <EstablishmentAddressForm />
          </CustomTabPanel>
        </Box>
      </TabContainer>
      <FooterMenu />

    </Container>
  );
}
