import { AxiosResponse } from 'axios';
import * as addressService from '../../infrastructure/api/services/addressService';
import IUseCase from '../IUseCase';

export default class GetStatesUseCase implements IUseCase<number, Promise<AxiosResponse>>
{
  execute() {
    return addressService.getAllStates();
  }
}
