import moment from 'moment';
import SaveSchedule from '../model/SaveSchedule';
import Service from '../model/Service';

export default interface RegisterScheduleForm {
  services: string[];
  date: string | null;
  observation: string | null;
  colaboratorId: string;
  scheduleType: string;
  paymentForm: string;
  establishmentId: number;
  finalClientId: string;
  finalClientNotRegisteredName?: string;
  finalClientNotRegisteredEmail?: string;
  serviceId?: string;
}
export const MapToSaveSchedule = (form: RegisterScheduleForm, services: Service[]): SaveSchedule => {

  const servicesId = form.services
    .map(serviceName => services.filter(s => s.name === serviceName)[0]?.id ?? null)
    .filter(service => service != null)

  return {
    services: servicesId as number[],
    date: moment(form.date).format('yyyy-MM-DDTHH:mm:ss'),
    observation: form.observation ?? "",
    colaboratorId: parseInt(form.colaboratorId),
    scheduleType: form.scheduleType,
    paymentForm: form.paymentForm,
    establishmentId: form.establishmentId,
    finalClientId: Number(form.finalClientId),
    finalClientNotRegistered: {
      name: form.finalClientNotRegisteredName,
      email: form.finalClientNotRegisteredEmail,
    }
  };
};
