import { getEstablishment } from '../../infrastructure/api/services/storageService';
import Notification from '../model/Notification';
import Service from '../model/Service';
import { Option } from 'react-multi-select-component';

export default interface SendNotificationForm {
  title: string;
  content: string;
  type: string;
  target: string;
  idFinalClient: number | null;
  categoryId: number;
  initialDate: string;
  establishmentId: number;
  saveCache: boolean;
}


export const MapToNotification = (form: SendNotificationForm): Notification => {
  return {
    ...form
  };
};
