import { ButtonStyle } from './styles';

interface ButtonParams {
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  colorType: 'primary' | 'secondary' | string;
  border?: 'radius' | 'none' | string;
  type?: 'button' | 'submit';
  width?: string | number;
  disabled?: boolean;
}
export default function Button(props: ButtonParams) {
  return (
    <ButtonStyle
      disabled={props.disabled}
      style={{ width: props.width ?? '100%' }}
      type={props.type ? props.type : 'button'}
      colorType={props.colorType}
      border={props.border}
      onClick={props.onClick}
    >
      {props?.children}
    </ButtonStyle>
  );
}
