import SearchFinalClientInput from '../../../application/final-client/getFinalClients';
import FinalClient from '../../../domain/model/FinalClient';
import api from '../api';
import { getEstablishmentId } from './storageService';

export const getFinalClients = (
  searchFinalClientInput?: SearchFinalClientInput
) => {
  return api.get<FinalClient[]>(`/final-clients`, {
    params: {
      terms: searchFinalClientInput?.terms,
      page: searchFinalClientInput?.page,
      perPage: searchFinalClientInput?.perPage,
      sort: searchFinalClientInput?.sort
    },
    headers: {
      establishmentId: getEstablishmentId(),
    },
  });
};
