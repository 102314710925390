import { useFormik } from 'formik';
import { useCallback, useContext, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { AiOutlineMail } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import LoginUseCase from '../../@core/application/auth/loginUseCase';
import GetEstablishmentBySite from '../../@core/application/establishment/getEstablishmentBySite';
import LoginForm, { MapToLogin } from '../../@core/domain/ui/LoginForm';
import {
  setEstablishment,
  setLoginData,
  setTenantStorage,
} from '../../@core/infrastructure/api/services/storageService';
import fromBee8b from '../../assets/images/from_BEE8B.png';
import Textbox from '../../components/TextboxMUI';
import TextboxPassword from '../../components/TextboxPasswordMUI';
import dictionary from '../../config/dictionary';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Bee8bBaseboardContainer,
  Content,
  DoNotHaveAccount,
  DoNotHaveAccountLink,
  ForgetPassword,
  Form,
  Header,
  HeaderContainer,
  Image,
  LoginButton,
  Subheader,
} from './styles';
import validationLogin from './validation';

export default function LoginPage() {
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const { nameSite } = useParams();

  const navigate = useNavigate();

  const formState: LoginForm = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const loginForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: validationLogin,
    onSubmit: (value: LoginForm) => {
      setLoading(true);
      doLogin(value);
    },
  });

  const doLogin = useCallback(
    (value: LoginForm) => {
      const login = MapToLogin(value);

      const loginUseCase = new LoginUseCase();
      loginUseCase
        .execute(login)
        .then((response) => {
          setLoading(false);
          setLoginData(response.data);
          setTenantStorage(nameSite || '');
          navigate('/inicio');
        })
        .catch(() => {
          setLoading(false);
          showAlert.error('Login ou senha incorretos!');
        });
    },
    [nameSite]
  );

  const handleCheckbox = useCallback(
    (value: boolean) => {
      loginForm.setFieldValue('rememberMe', value);
    },
    [loginForm]
  );

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      loginForm.submitForm();
    },
    [loginForm]
  );

  useEffect(() => {
    const getEstablishment = new GetEstablishmentBySite();

    getEstablishment
      .execute(nameSite as string)
      .then((response) => {
        const establishment = response.data;
        console.log(establishment);
        setEstablishment(JSON.stringify(establishment));
      })
      .catch(() => {});
  }, [nameSite]);

  return (
    <Content>
      <HeaderContainer>
        <Header>{dictionary.login.welcomeTitle}</Header>
        <Subheader>{dictionary.login.welcomeSubtitle}</Subheader>
      </HeaderContainer>
      <Form>
        <Textbox
          label={''}
          name="email"
          type="email"
          placeholder={dictionary.login.placeholderEmail}
          value={loginForm.values.email}
          setValue={loginForm.handleChange}
          error={loginForm.errors.email}
          iconx={<AiOutlineMail />}
        />
        <TextboxPassword
          label={''}
          name="password"
          type="password"
          placeholder={dictionary.login.placeholderPassword}
          isPassword
          value={loginForm.values.password}
          setValue={loginForm.handleChange}
          error={loginForm.errors.password}
          iconx={<RiLockPasswordLine />}
        />
        {/* <Checkbox
          label={dictionary.login.rememberPassword}
          name="rememberMe"
          value={loginForm.values.rememberMe}
          setValue={handleCheckbox}
        /> */}
        <ForgetPassword to={`/${nameSite}/esqueci-minha-senha`}>
          {dictionary.login.forgetPassword}
        </ForgetPassword>

        <LoginButton onClick={handleSubmit}>
          <span>{dictionary.login.enter}</span>
        </LoginButton>

        <DoNotHaveAccount>
          {dictionary.login.doNotHaveAccount}
          <DoNotHaveAccountLink to="/registre-se">
            {dictionary.login.clickHere}
          </DoNotHaveAccountLink>
        </DoNotHaveAccount>
      </Form>
      <Bee8bBaseboardContainer>
        <Image src={fromBee8b} />
      </Bee8bBaseboardContainer>
    </Content>
  );
}
