import { AxiosResponse } from 'axios';
import * as oauthService from '../../infrastructure/api/services/oauthService';
import IUseCase from '../IUseCase';
import { getEstablishment } from '../../infrastructure/api/services/storageService';
import Establishment from '../../domain/model/Establishment';

export interface ForgotUseCaseInput {
  login: string;
  loginType: string;
}

export default class ForgotUseCase
  implements IUseCase<ForgotUseCaseInput, Promise<AxiosResponse<void>>>
{
  execute(input: ForgotUseCaseInput) {
    var establishment = getEstablishment() as Establishment;

    var body = {
      ...input,
      establishmentId: Number(establishment.id),
    };

    return oauthService.forgotPassword(body);
  }
}
