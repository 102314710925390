import GetServicesInput from '../../../application/services/getServices';
import Positions, { Position } from '../../../domain/model/Positions';
import Service from '../../../domain/model/Service';
import ServiceCreateResponse from '../../../domain/model/ServiceCreateResponse';
import api from '../api';
import { getEstablishment, getEstablishmentId } from './storageService';

export const getServices = (filters?: GetServicesInput) => {
  return api.get(`/establishments/${getEstablishmentId()}/services`, {
    params: {
      ...filters
    }
  });
};

export const getServiceById = (id: Number) => {
  return api.get(`/establishments/${getEstablishmentId()}/services/${id}`);
};

export const createServices = (service: Service) => {
  return api.post<ServiceCreateResponse>(
    `/establishments/${getEstablishmentId()}/services`,
    service
  );
};

export const updateServices = (service: Service) => {
  return api.patch(
    `/establishments/${getEstablishmentId()}/services/${service.id}`,
    service
  );
};

export const organizeServiceImages = (serviceId: number, positions: Positions) => {
  return api.post(
    `/establishments/${getEstablishmentId()}/services/${serviceId}/image-positions`,
    positions
  );
};

export const organizeServices = (positions: Positions) => {
  return api.post(
    `/establishments/${getEstablishmentId()}/services-positions`,
    positions
  );
};

export const deleteService = (id: number) => {
  return api.delete(`/establishments/${getEstablishmentId()}/services/${id}`);
};

export const uploadImage = (data: FormData) => {
  return api.post(`/storage/service-images`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteServiceImage = (serviceId: Number, imageId: Number) => {
  return api.delete(`/storage/service-images/service/${serviceId}/image/${imageId}`)
};
