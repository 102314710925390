import {
  faCircle,
  faFont,
  faSquare,
  faSun,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { HexColorPicker } from 'react-colorful';
import { AiOutlineFontSize } from "react-icons/ai";
import { CgDarkMode } from "react-icons/cg";
import { CiImageOn } from "react-icons/ci";
import { LuPalette } from "react-icons/lu";
import { TbPlaystationSquare } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import GetCustomization from '../../@core/application/theme/getCustomization';
import SaveTheme from '../../@core/application/theme/saveTheme';
import UploadBackground from '../../@core/application/theme/uploadBackground';
import UploadLogo from '../../@core/application/theme/uploadLogo';
import SaveThemeJson from '../../@core/domain/model/SaveTheme';
import CustomizeLayoutForm, {
  MapToForm,
  MapToTheme,
} from '../../@core/domain/ui/CustomizeLayoutForm';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import FooterMenu from '../../components/Menus/FooterMenu';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import SelectBox from '../../components/Selectbox';
import LoadingContextContent from '../../contexts/LoadingContext';
import CustomView from './customView';
import {
  ColorComponentWrapper,
  ColorPickerContainer,
  Container,
  ContainerInput,
  FormLoader,
  FormLoaderContainer,
  IconAndTextContainer,
  IconContainer,
  ImagesComponentWrapper,
  SquareColored,
  TextWithSquareColored
} from './styles';
import { useAlert } from 'react-alert';

const initialValues: CustomizeLayoutForm = {
  theme: 'dark',
  color: '#EA7000',
  font: 'Sedan SC',
  border: 'radius',
  background: '',
  logo: '',
  logoToUpload: '',
  backToUpload: '',
  loadingCustomizations: true
};
export default function CustomizeLayout() {
  const [customizeData, setCustomizeData] = useState<CustomizeLayoutForm>(initialValues);
  const [customizedDateLoading, setCustomizedDateLoading] = useState<boolean>(true);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const logoFile = useRef<HTMLInputElement>(null);
  const backgroundFile = useRef<HTMLInputElement>(null);
  const { setLoading } = useContext(LoadingContextContent);
  const navigate = useNavigate();
  const showAlert = useAlert();


  useEffect(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    setCustomizeData({...customizeData, loadingCustomizations: true});

    const getCustomization = new GetCustomization();

    getCustomization
      .execute()
      .then((response) => {
        console.log("res", response)
        const mappedToFormValues = MapToForm(response);
        mappedToFormValues.loadingCustomizations = false
        console.log(response);
        
        setCustomizedDateLoading(false)
        setCustomizeData({...mappedToFormValues, loadingCustomizations: false});
      })
      .catch((err) => {
        console.log("erro")
        setCustomizedDateLoading(false)
        setCustomizeData({...customizeData, loadingCustomizations: false});
        if(err.response.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };        
      });
  }, [setCustomizedDateLoading]);

  const upBackground = useCallback(
    async (values: CustomizeLayoutForm) => {

      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const avatar = backgroundFile.current?.files
        ? backgroundFile.current?.files[0]
        : null;

      if (avatar !== undefined) {
        const formData = new FormData();
        formData.append('establishmentId', getEstablishmentId() ?? '');
        formData.append('imageType', 'BACKGROUND');
        formData.append('fileImages', avatar ?? '');

        const upBackground = new UploadBackground();

        await upBackground
          .execute(formData)
          .then((response) => {
            const fileUrl = response.data.storageResult.filesSuccessfully[0].fileName;
            values.backToUpload = fileUrl;
          })
          .catch((err) => {
            if(err.response.status == 401){
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          });
      }
    },
    [backgroundFile]
  );

  const customizeLayoutForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: customizeData,

    onSubmit: async (values: CustomizeLayoutForm) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }
      
      const useCase = new SaveTheme();
      setLoading(true);
      await upLogo(values);
      await upBackground(values);

      const theme = MapToTheme(values);
      const saveThemeJson: SaveThemeJson = {
        styles: JSON.stringify(theme),
      };
      await useCase
        .execute(saveThemeJson)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if(err.response.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
  });
  
  const upLogo = useCallback(
    async (values: CustomizeLayoutForm) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }
      
      const avatar = logoFile.current?.files
        ? logoFile.current?.files[0]
        : null;

      if (avatar !== undefined) {
        const formData = new FormData();
        formData.append('establishmentId', getEstablishmentId() ?? '');
        formData.append('imageType', 'LOGO');
        formData.append('fileImages', avatar ?? '');

        const upLogoCase = new UploadLogo();
        await upLogoCase
          .execute(formData)
          .then((response) => {
            const fileUrl = response.data.storageResult.filesSuccessfully[0].fileName;
            values.logoToUpload = fileUrl;
          })
          .catch((err) => {
            if(err.response.status == 401){
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          });
      }
    },
    [logoFile, customizeLayoutForm]
  );

  const changeLayoutImage = useCallback((file: File, formField: string) => {
    if (validateImage(file)) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        customizeLayoutForm.setFieldValue(formField, fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }

    
  }, []);

  const validateImage = (image: File) => {
    const MAX_FILE_SIZE_MB = 8;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    console.log("tamanho imagem: ", image.size)
    console.log("MAX_FILE_SIZE_BYTES: ", MAX_FILE_SIZE_BYTES)

    if (image.size > MAX_FILE_SIZE_BYTES) {
      console.log(`Imagem excede o tamanho máximo de ${MAX_FILE_SIZE_MB}mb.`)
      showAlert.error('Imagem excede o tamanho máximo de 8mb.');
      return false;
    }

    return true
  };

  const changeLogo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files?.length === 0) {
        return;
      }
      
      changeLayoutImage(files![0], 'logo');
    },
    [customizeLayoutForm]
  );

  const changeBackground = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files?.length === 0) {
        return;
      }

      changeLayoutImage(files![0], 'background');

    },
    [customizeLayoutForm]
  );
  
  return (
    <Container>
        <CustomView {...customizeLayoutForm.values} />

        {customizedDateLoading ? (
          <FormLoaderContainer>
            <FormLoader/>
            <FormLoader/>
            <FormLoader/>
            <FormLoader/>
          </FormLoaderContainer>
        ) : (
          <Form style={{ marginTop: 15 }}>
            <ImagesComponentWrapper onClick={() => logoFile.current?.click()}>
              <ContainerInput>
                <IconAndTextContainer>
                  <IconContainer>
                    <CiImageOn />
                  </IconContainer>
                  Logo
                </IconAndTextContainer>

                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ height: '0.6em' }}
                  onClick={() => logoFile.current?.click()}
                />
              </ContainerInput>
              <input
                id="logo"
                ref={logoFile}
                hidden
                name="logo"
                type="file"
                accept="image/*"
                onChange={(event) => changeLogo(event)}
              />
            </ImagesComponentWrapper>

            <ImagesComponentWrapper onClick={() => backgroundFile.current?.click()}>
              <ContainerInput>
                <IconAndTextContainer>
                  <IconContainer>
                    <CiImageOn />
                  </IconContainer>
                  Plano de fundo
                </IconAndTextContainer>

                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ height: '0.6em' }}
                  onClick={() => backgroundFile.current?.click()}
                />
              </ContainerInput>
              <input
                id="background"
                ref={backgroundFile}
                onChange={changeBackground}
                hidden
                name="background"
                type="file"
                accept="image/*"
              />
            </ImagesComponentWrapper>   

            <ColorComponentWrapper onClick={() => setShowColorPicker(!showColorPicker)}>
              <ContainerInput>
                <IconAndTextContainer>
                  <IconContainer>
                    <LuPalette />
                  </IconContainer>
                  <TextWithSquareColored>
                    Cor:
                    <SquareColored color={customizeLayoutForm.values.color}/>
                    {customizeLayoutForm.values.color}
                  </TextWithSquareColored>
                </IconAndTextContainer>
              </ContainerInput>
              {showColorPicker && ( //TODO: Colocar margin top | Deixar esse componente igual aos outros
                <ColorPickerContainer>
                  <HexColorPicker
                    color={customizeLayoutForm.values.color}
                    onChange={(newColor: string) =>
                      customizeLayoutForm.setFieldValue('color', newColor)
                    }
                  />
                </ColorPickerContainer>
              )}
            </ColorComponentWrapper>
            <SelectBox
              label='Tema'
              placeholder='Tema'
              icon={faSun}
              name="theme"
              value={customizeLayoutForm.values.theme}
              setValue={customizeLayoutForm.handleChange}
              iconx={<CgDarkMode />}
              items={[
                {
                  value: "dark",
                  label: "Escuro"
                },
                {
                  value: "ligth",
                  label: "Claro"
                }
              ]}
            />
            <SelectBox
              label="fonte"
              placeholder="fonte"
              icon={faFont}
              name="font"
              value={customizeLayoutForm.values.font}
              setValue={customizeLayoutForm.handleChange}
              items={[
                {
                  value: "Sedan SC",
                  label: "Sedan SC"
                }
              ]}
              iconx={<AiOutlineFontSize />}
            />
            <SelectBox
              label="Bordas"
              placeholder="Bordas"
              icon={
                customizeLayoutForm.values.border === 'radius'
                  ? faCircle
                  : faSquare
              }
              iconx={<TbPlaystationSquare />}
              name="border"
              id="border"
              value={customizeLayoutForm.values.border}
              setValue={customizeLayoutForm.handleChange}
              error={customizeLayoutForm.errors.border}
              type="text"
              items={[
                {
                  value: "radius",
                  label: "Redondas"
                },
                {
                  value: "none",
                  label: "Quadradas"
                }
              ]}
            />
          </Form>
        )}
       
        
        <Button
          colorType="primary"
          onClick={() => customizeLayoutForm.submitForm()}
        >
          Salvar
        </Button>
        <FooterMenu />
    </Container>
  );
}
