import styled from 'styled-components';

interface ButtonProps {
  colorType: 'primary' | 'secondary' | string;
  border?: 'radius' | 'none' | string;
}
export const ButtonStyle = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.colorType === 'primary'
      ? props.theme.color.primary
      : props.colorType};
  padding: 10px;
  color: ${(props) => props.theme.color.light};
  width: 100%;
  border: none;
  border-radius: ${(props) => (props.border === 'radius' ? '1em' : '')};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
  cursor: pointer; /* Altera o cursor ao passar o mouse */

  /* Efeito de pressionamento */
  :active {
    transform: translateY(1px); /* Desloca o botão para baixo */
    box-shadow: 0 0 10px 2px rgba(93, 23, 235, 0.5); /* Sombra ao pressionar */
  }

  /* Efeito de hover */
  :hover {
    box-shadow: 0 0 20px 5px rgba(93, 23, 235, 0.3); /* Bordar esfumaçada */
  }
`;
