import IUseCase from '../IUseCase';
import * as themeService from '../../infrastructure/api/services/themeService';
import { AxiosResponse } from 'axios';
import Theme from '../../domain/model/Theme';
import SaveThemeJson from '../../domain/model/SaveTheme';

export default class SaveTheme
  implements IUseCase<SaveThemeJson, Promise<any>>
{
  execute(theme: SaveThemeJson) {
    return themeService.saveTheme(theme);
  }
}
