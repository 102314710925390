import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';
import { AxiosResponse } from 'axios';

export default class GetServiceById
  implements IUseCase<Number, Promise<AxiosResponse<Service[]>>>
{
  execute(id: Number) {
    return serviceService.getServiceById(id);
  }
}
