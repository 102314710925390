import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useCallback, useState } from 'react';
import { TfiTrash } from "react-icons/tfi";
import {
  Container,
  ContainerText,
  Content,
  IconContainer,
  PositionContainer
} from './styles';

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  setValue?: (e: ChangeEvent<any>) => void;
  name?: string;
  showLabel?: boolean;
  icon?: any;
  position?: any;
  iconEnd?: any;
  onClickEnd?: () => void;
  onClick?: () => void;
}

export default function ItemListWithoutInput({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  icon,
  iconEnd,
  position,
  onClick,
  onClickEnd,
  showLabel
}: TextboxParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);


  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue) setValue(event);
    },
    [setValue]
  );

  return (
    <Content>
      <PositionContainer>
        {position}
      </PositionContainer>

      <ContainerText onClick={onClick}>
        {value}
      </ContainerText>

      <IconContainer>
        <TfiTrash onClick={onClickEnd} />
      </IconContainer>
    </Content> 
  );
}
