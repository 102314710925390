import IUseCase from '../IUseCase';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import { AxiosResponse } from 'axios';
import RecurringAppointment from '../../domain/model/RecurringAppointment';

export default class CreateRecurringAppointmentUseCase
  implements IUseCase<RecurringAppointment, Promise<AxiosResponse>>
{
  execute(data: RecurringAppointment) {
    return scheduleService.createRecurringAppointment(data);
  }
}
