import styled from 'styled-components';

export const SubmitButton = styled.button`
  color: ${(props) => props.theme.color.light};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.primary};
  outline: none;
  border: 2px solid ${(props) => props.theme.color.primary};
  padding: 0.8em;
  margin-top: 1.5em;
  font-weight: bold;
  font-size: 1.2em;
  gap: 0.5em;
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
`;
