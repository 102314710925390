import {
  Container,
  Content,
  ErrorMessage,
  Input,
  Label,
  ShowEndIcon,
  ShowIcon,
  ShowPassword,
} from './styles';
import { useState, useCallback, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TfiTrash } from "react-icons/tfi";

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  setValue?: (e: ChangeEvent<any>) => void;
  name?: string;
  showLabel?: boolean;
  icon?: any;
  iconEnd?: any;
  onClickEnd?: () => void;
  onClick?: () => void;
}

export default function ItemList({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  icon,
  iconEnd,
  onClick,
  onClickEnd,
  showLabel
}: TextboxParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);


  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue) setValue(event);
    },
    [setValue]
  );

  return (
    <Container id={`${id}-container`} onClick={() => console.log("ok")}>
      <Label>{label}</Label>
      <Content>
        <Input
          name={name}
          id={id}
          placeholder={placeholder ?? ''}
          type={showPassword ? 'text' : type ?? 'text'}
          size={size}
          showPassword={isPassword}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
          onClick={() => console.log("ok")}
        ></Input>
        {icon != undefined && (
          <ShowIcon id={`${id ?? 'out'}-button`}>
            <FontAwesomeIcon icon={icon} />
          </ShowIcon>
        )}
        {iconEnd != undefined && (
          <ShowEndIcon id={`${id ?? 'end'}-button`}>
            <TfiTrash onClick={onClickEnd} />
          </ShowEndIcon>
        )}
        {error && (
          <ErrorMessage showPassword={isPassword}>{error}</ErrorMessage>
        )}
      </Content>
    </Container>
  );
}
