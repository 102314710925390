import React, { useCallback, useState, forwardRef, ChangeEvent } from 'react';
import { IMaskInput } from 'react-imask';
import { IconContainerProps, InputAdornment, InputBaseComponentProps } from '@mui/material';
import {
  Container,
  ErrorMessage,
  IconContainer,
  StyledTextField
} from './styles';

const MaskedCnpj = forwardRef(function CNPJInput(props: any, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask='00.000.000/0000-00'
          definitions={{
              '#': /[0-9]/,
          }}
          placeholder={'00.000.000/0000-00'}
          inputRef={ref}
          onAccept={useCallback((value: any) => onChange({ target: { name: props.name, value } }), [])}
          overwrite
      />
  );
});

const MaskedPhone = forwardRef(function CNPJInput(props: any, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask='(00) 00000-0000'
          definitions={{
              '#': /[0-9]/,
          }}
          placeholder={'(99) 99999-9999'}
          inputRef={ref}
          onAccept={useCallback((value: any) => onChange({ target: { name: props.name, value } }), [])}
          overwrite
      />
  );
});

const MaskedCEP = forwardRef(function CEPInput(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000-000"
      definitions={{
        '#': /[0-9]/,
      }}
      placeholder="12345-678"
      inputRef={ref}
      onAccept={useCallback((value: any) => onChange({ target: { name: props.name, value } }), [onChange, props.name])}
      overwrite
    />
  );
});

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  setValue?: (e: ChangeEvent<any>) => void;
  name?: string;
  mask?: string;
  showLabel?: boolean;
  icon?: any;
  iconx?: JSX.Element;
  iconEnd?: any;
  variant?:  'outlined' | 'filled' | 'standard' | undefined;
  onClickEnd?: () => void;
  onClick?: () => void;
}

export default function Textbox({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  iconx,
  icon,
  iconEnd,
  onClick,
  onClickEnd,
  showLabel,
  variant,
  mask
}: TextboxParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue) setValue(event);
    },
    [setValue]
  );

  const startAdornment = iconx ?  {
    startAdornment: (
      <InputAdornment position="start">
        {iconx}
      </InputAdornment>
    ),
  } : {
    startAdornment: (
      <InputAdornment position="start">
        <IconContainer>
        </IconContainer>
      </InputAdornment>
    ),
  }

  const verifyMask = (mask: string | undefined) : any => {
    if(mask == "CNPJ"){
      return MaskedCnpj;
    }

    if(mask == "PHONE"){
      return MaskedPhone;
    }

    if(mask == "CEP"){
      return MaskedCEP;
    }
  }

  return (
    <Container id={`${id}-container`}>
      <StyledTextField
        id={id}
        placeholder={placeholder}
        label={label ?? placeholder}
        variant={variant ?? "outlined"}
        type={type}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        onClick={onClick}
        InputProps={{
          ...startAdornment,
          inputComponent: mask ? verifyMask(mask) : null,
          sx: {
            '& input': {
              backgroundColor: 'white',
              borderRadius: '0', // Bordas quadradas
            }
          }
        }}
      />
        {error && (
          <ErrorMessage showPassword={isPassword}>{error}</ErrorMessage>
        )}
    </Container>
  );
}
