import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 1em;
  padding-bottom: 4em;
`;

export const WrapperModalComponents = styled.div``;

export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  gap: 1em;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9); /* Cor de fundo com opacidade */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra */
  z-index: 999; /* Valor alto para sobrepor outros elementos */
  width: 80vw;

  /* Adiciona um efeito de desfoque no fundo (backdrop) */
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(50px);
    z-index: -1;
  }
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 1.2em;
  font: arial;
  color: gray;
  background-color: transparent;
  border: none;
`;

export const ConfirmationModalTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

export const ConfirmationModalText = styled.p`
  margin-bottom: 20px;
  text-align: center;
`;

export const ConfirmationModalOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

export const ConfirmAction = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1em;
  width: 7em;
`;

export const CancelAction = styled.button`
  background-color: transparent;
  color: gray;
  border: none;
  width: 5em;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
`;

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 997;
  backdrop-filter: blur(8px);
`;

export const RemoveServiceContainer = styled.div`
  text-align: center;
  color: gray;
  font-weight: 300;
  padding-bottom: 1em;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
  padding-top: 2em;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;


export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;

export const ServiceLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const ServiceLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 6em;
  height: 6em;
`;

export const GalleryListLoader = styled.div`
	display: flex;
  flex-wrap: wrap;
  align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

  /* Define a largura máxima dos itens para que haja no máximo 3 itens por linha */
  & > * {
    flex: 0 0 calc(33.33% - 1em); /* 33.33% menos o espaço entre os itens */
    max-width: calc(33.33% - 1em); /* Largura máxima de cada item */
    margin: 0.5em; /* Espaçamento entre os itens */
  }
`;

export const GalleryImagesLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: calc(33.33% - 1em); /* 3 itens por linha com margem entre eles */
  margin-bottom: 1em; /* Espaçamento inferior entre as linhas */
  height: 8em;
`;

export const GalleryImagesLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Adicionado para envolver os itens */
  justify-content: space-between; /* Distribui os itens uniformemente */
`;
