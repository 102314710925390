import { TextField } from '@mui/material';
import styled, { keyframes } from 'styled-components';

interface InputProps {
  size?: number;
  showPassword?: boolean;
}

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const TextBoxLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 100%;
  height: 3em;
`;

export const CommentBox = styled.input`
	background-color: white;
	padding: 1em 1.5em;
	border: none;
	outline: none;
	margin-top: 1em;
	color: black;
	font-weight: 500;
	font-size: 1em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const ErrorMessage = styled.small<{ showPassword?: boolean }>`
  color: ${(props) => props.theme.color.error};
  padding-top: 6px;
  letter-spacing: 1px;
  align-self: flex-start;
`;


export const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 0; /* Bordas quadradas */
      background-color: white; /* Fundo branco */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent; /* Remover borda padrão */
    }

    .MuiInputLabel-root {
      color: grey; /* Cor do rótulo */
    }

    .MuiOutlinedInput-input {
      padding: 1em 0.1em; /* Espaçamento interno */
      color: black; /* Cor do texto */
      font-weight: 500; /* Peso da fonte */
      font-size: 1em; /* Tamanho da fonte */
    }

    /* Estilo de foco */
    &:hover .MuiOutlinedInput-notchedOutline,
    &:focus .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.color.primary}; /* Cor da borda ao focar */
    }
  }
`;


export const Label = styled.label`
  color: ${(props) => props.theme.color.dark};
  letter-spacing: 1px;
  align-self: flex-start;
`;

export const Input = styled.input<InputProps>`
 border: ${(props) => props.theme.color.borderUnfocused};
  outline: none;
  font-size: ${(props) => (props.size ? `${props.size}em` : '1.2em')};
  background-color: white;
  height: 3em;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
  padding: 0.7em 1em;
  padding-right: ${(props) => (props.showPassword ? `3em` : `1em`)};
  padding-left: 3.3em;
  transition: all 0.2s ease-in-out;

  /* Remove o ícone padrão */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Oculta o ícone do calendário para navegadores WebKit */
  &::-webkit-calendar-picker-indicator {
    display: block;
    position: absolute;
    right: 100%; /* Move o ícone para fora do input */
    pointer-events: none; /* Mantém o ícone clicável */
  }

  &:focus {
    border-color: ${(props) => props.theme.color.primary};
  }
  
`;

export const ShowPassword = styled.button`
  background-color: ${(props) => props.theme.color.light};
  border: none;
  color: ${(props) => props.theme.color.borderUnfocused};
  font-size: 1.2em;
  align-self: flex-end;
  transform: translate(-16px, -40px);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  height: 0px;
`;

export const ShowIcon = styled.button`
  background-color: ${(props) => props.theme.color.light};
  border: none;
  color: ${(props) => props.theme.color.primary};
  font-size: 1.2em;
  align-self: flex-start;
  transform: translate(-16px, -40px);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  height: 0px;
  margin-left: 1.8em;
`;
export const ShowEndIcon = styled.button`
  background-color: ${(props) => props.theme.color.light};
  border: none;
  color: ${(props) => props.theme.color.inputEndIconColor};
  font-size: 1.2em;
  align-self: flex-end;
  transform: translate(-16px, -40px);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  height: 0px;
  margin-left: 1.8em;
`;
