import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationServiceForm = Yup.object().shape({
  name: Yup.string().max(30, dictionary.global.validation.maxLength.replace("{0}", "30")).required(dictionary.global.validation.required),
  price: Yup.number().moreThan(0, dictionary.global.validation.required).required(dictionary.global.validation.required),
  genre: Yup.string().required(dictionary.global.validation.required),
  duration: Yup.string().required(dictionary.global.validation.required),
  ageGroup: Yup.string().required(dictionary.global.validation.required),
  categoryId: Yup.number().moreThan(0, dictionary.global.validation.required),
  description: Yup.string().max(255, 'Descrição deve ter no máximo 255 caracteres')
});

export default validationServiceForm;
