import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as colaboratorService from '../../infrastructure/api/services/colaboratorService';
import { AxiosResponse } from 'axios';

export default class GetColaborator
  implements IUseCase<void, Promise<AxiosResponse<Colaborator[]>>>
{
  execute() {
    /*return [
      {
        name: 'teste',
        email: 'teste@teste.com',
        password: '',
        description: 'teste',
        login: 'teste@teste.com',
        imagePerfil:
          'https://www.felps.com.br/wp-content/uploads/2019/09/A-Real-Import%C3%A2ncia-De-Um-Profissional-Cabeleireiro-1.jpg',
      },
      {
        name: 'teste 2',
        email: 'teste2@teste.com',
        password: '',
        description: 'teste 2',
        login: 'teste2@teste.com',
        imagePerfil:
          'https://www.felps.com.br/wp-content/uploads/2019/09/A-Real-Import%C3%A2ncia-De-Um-Profissional-Cabeleireiro-1.jpg',
      },
    ];*/
    return colaboratorService.getColaborators();
  }
}
