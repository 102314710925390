import styled, { keyframes } from 'styled-components';

const Rotate = keyframes`
  100% { transform: rotate(360deg)}
`;

const spin = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

// Estilizando o componente Loader
export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;


export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.color.backLoading};
  z-index: 1;
  opacity: 0.5;
`;
