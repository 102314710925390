import { AxiosResponse } from 'axios';
import * as oauthService from '../../infrastructure/api/services/oauthService';
import IUseCase from '../IUseCase';

export interface ChangeUseCaseInput {
  code: string;
  newPassword: string;
}

export default class ChangeUseCase
  implements IUseCase<ChangeUseCaseInput, Promise<AxiosResponse<void>>>
{
  execute(input: ChangeUseCaseInput) {
    return oauthService.changePassword({ ...input });
  }
}
