import styled from 'styled-components';

export const ButtonGray = styled.button`
  background-color: ${(props) => props.theme.color.grayButtonColor};
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  gap: 0.5em;
`;
