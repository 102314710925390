import arrayMove from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import { Container } from "./styles";
import "./styles.css";
import ItemListWithoutInput from "../ItemListWithoutInput";
import Service from "../../@core/domain/model/Service";
import { faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

interface GalleryParams {
  items: any;
  setItems: any;
  setSelectedService: any;
  setDialogModal: any
}

export function SortItems({
  items,
  setItems,
  setSelectedService,
  setDialogModal
}: GalleryParams) {

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array: any) => arrayMove(array, oldIndex, newIndex));
  };

  const navigate = useNavigate();

  return (
    <Container>
      <SortableList
        onSortEnd={onSortEnd}
        className={"list"}
        draggedItemClassName={"dragged"}
      >
        {items.map((service: any, index: number) => (
          <SortableItem key={service}>
            <div className="item">
              <ItemListWithoutInput
                position={index + 1}
                key={service}
                value={service.name.length >= 30 ? service.name.substring(0, 30) + "..." : service.name}
                type="text"
                icon={faList}
                iconEnd={faTrash}
                onClick={() => navigate(`/servico/${service.id}`)}
                onClickEnd={() => {
                  setSelectedService(service);
                  setDialogModal(true);
                }}
                disabled
              />
            </div>
          </SortableItem>
        ))}
      </SortableList>
    </Container>
  )
}
