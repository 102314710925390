import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as colaboratorService from '../../infrastructure/api/services/colaboratorService';

export default class UpdateColaborator
  implements IUseCase<Colaborator, Promise<any>>
{
  execute(register: Colaborator) {
    return colaboratorService.doUpdate(register);
  }
}
