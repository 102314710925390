import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationRegisterColaboratorForm = Yup.object().shape({
  name: Yup.string().max(30, dictionary.global.validation.maxLength.replace("{0}", "30")).required(dictionary.global.validation.required),
  email: Yup.string().max(100, dictionary.global.validation.maxLength.replace("{0}", "100")).required(dictionary.global.validation.required),
  password: Yup.string()
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[A-Z]).{6,50}$/,
			'A senha deve ter entre 6 e 50 caracteres, conter letra, número e pelo menos uma letra maiúscula'
		)
		.required('Obrigatório'),
  passwordRepeat: Yup.string().max(50, dictionary.global.validation.maxLength.replace("{0}", "50")).required(dictionary.global.validation.required),
  age: Yup.number().moreThan(15, dictionary.global.validation.minAge.replace("{0}", "16")).required(dictionary.global.validation.required),
  startLunchTime: Yup.string().required(dictionary.coworker.validation.startLunchTimeRequired),
  endLunchTime: Yup.string().required(dictionary.coworker.validation.endLunchTimeRequired),
  startWork: Yup.string().required(dictionary.coworker.validation.startWorkRequired),
  endWork: Yup.string().required(dictionary.coworker.validation.endWorkRequired)
});

export default validationRegisterColaboratorForm;
