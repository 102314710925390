import {
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { Autocomplete, Box, TextField } from '@mui/material';
import {
  Container,
  ErrorMessage
} from './styles';
import { ChangeEvent } from 'react';

interface OptionsParams {
  value?: string;
  label?: string;
}

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value: any;
  setValue: (e: string) => void;
  children?: React.ReactNode;
  name?: string;
  icon?: IconDefinition;
  items: any[];
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  defaultValue?: string;
}

export default function SelectBoxSearch({
  label,
  id,
  error,
  value,
  setValue,
  name,
  icon,
  placeholder,
  items,
  disabled,
  multiple,
  defaultValue,
  onChange,
}: TextboxParams) {
  const handleChange = (newValue: any) => {
    setValue(newValue?.value ?? "");
    console.log("event", newValue?.value ?? "");
  };

  return (
    <Container id={`${id}-container`}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          placeholder={placeholder}
          value={value}
          onChange={(event: any, newValue: string | null) => {
            handleChange(newValue);
          }}
          options={items}
            sx={{
              width: '100%',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Adiciona uma sombra
              backgroundColor: 'white',
              borderRadius: '0px', // Borda arredondada
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda do TextField
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda ao passar o mouse sobre o TextField
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda quando o TextField está focado
              },
            }}
          renderInput={(params) => 
            <TextField onChange={(e: any) => handleChange(e)} {...params} name={name} label={label} placeholder={placeholder} />}
        />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
      </Box>

    </Container>
  );
}
