import styled from 'styled-components';

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 1em;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
  padding-top: 2em;
  margin-bottom: 4em;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 5em;
`;

export const FormScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 45vh;
  overflow-y: auto;
  margin-bottom: 1em;
  padding-bottom: 1em;
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;
