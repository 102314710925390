import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import DashboardWrapper from '../../components/DashboardWrapper';
import { Form } from '../../components/Form/style';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Container,
  Header,
  HeaderContainer,
  Instructiontext
} from './styles';
import validationupdateEstablishmentForm from './validation';

import GetEstablishmentById from '../../@core/application/establishment/getEstablishmentById';
import UpdateEstablishmentUseCase from '../../@core/application/establishment/updateEstablishment';
import Establishment from '../../@core/domain/model/Establishment';
import { default as UpdateEstablishmentForm } from "../../@core/domain/ui/UpdateEstablishmentForm";

import { Slate, Editable, withReact } from 'slate-react'
import { createEditor } from 'slate'

import { BaseEditor, Descendant } from 'slate'
import { ReactEditor } from 'slate-react'
import { Editor } from 'primereact/editor';
import Button from '../../components/Button';
import GetEstablishmentBySite from '../../@core/application/establishment/getEstablishmentBySite';
import FooterMenu from '../../components/Menus/FooterMenu';

type CustomElement = { type: 'paragraph'; children: CustomText[] }
type CustomText = { text: string }

const initialService: UpdateEstablishmentForm = {
  nameSite: '',
  establishmentType: '',
  description: '',
  socialName: '',
  fantasyName: '',
  name: '',
  telephone: '',
  email: '',
  cnpj: '',
  matriz: '',
  facebookURL: '',
  gmailURL: '',
  instagramURL: '',
  whatsappURL: ''
};

interface CustomTypes {
  Editor: BaseEditor & ReactEditor
  Element: CustomElement
  Text: CustomText
}

export default function AppInformations() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [tabSelected, setTabSelected] = useState(1);

  const [editor] = useState(() => withReact(createEditor()))
  const [updateEstablishmentForm, setUpdateEstablishmentForm] = useState<UpdateEstablishmentForm>(initialService);
  const [establishmentId, setEstablishmentId] = useState<number>(Number(id));

  const [text, setText] = useState()

  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);


  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: updateEstablishmentForm,
    // validationSchema: validationupdateEstablishmentForm,
    onSubmit: async (values: UpdateEstablishmentForm) => {
      console.log("info", text)
      values.description = text;

      const updateEstablishmentUseCase = new UpdateEstablishmentUseCase()
      setLoading(true);


      updateEstablishmentUseCase.execute({
        ...values
      } as Establishment)
        .then(res => {
          setLoading(false);
          showAlert.success("Informações atualizadas com sucesso")
        }).catch(err => {
          setLoading(false);
          if (err?.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          } else {
            showAlert.error("Falha ao atualizar informações. Tente novamente")
          }
        })
    },
  });

  const findEstablishment = useCallback(async () => {
      const tenant = getTenantStorage();

      if (!checkUserAuthenticated()) {
        navigate(`/${tenant}/login`)
        setLoading(false)
        return;
      }

      setLoading(true);

      const getEstablishmentBySiteNameUseCase = new GetEstablishmentBySite();

      await getEstablishmentBySiteNameUseCase
        .execute(tenant ?? "")
        .then((response: any) => {
          var establishment = response.data;

          setText(establishment.description)
          setUpdateEstablishmentForm({
            ...establishment,
            ...establishment.addressEstablishment[0]
          });
          setLoading(false)
        })
        .catch((err: any) => {
          setLoading(false);
          if (err?.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [setLoading]
  );


  useEffect(() => {
    setLoading(true)
    const timeoutId = setTimeout(() => {
      findEstablishment();
    }, 2000); 

    return () => clearTimeout(timeoutId);
  }, [establishmentId, findEstablishment]);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-strike" aria-label="Strike"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <button className="ql-header" value="1"></button>
        <button className="ql-header" value="2"></button>

      </span>
    );
  };

  const header = renderHeader();

  return (
    <Container>
      <HeaderContainer>
        <Header>Descrição</Header>
        <Instructiontext>Altere a descrição do seu estabelecimento</Instructiontext>
      </HeaderContainer>
      <Form>

        <div className="card">
          <Editor value={registerForm.values.description} onTextChange={(e: any) => setText(e.htmlValue)} headerTemplate={header} style={{ height: '320px', backgroundColor: 'white', }} />
        </div>


        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Salvar
        </Button>
      </Form>
      {/* </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          
        </CustomTabPanel>
      </Box>
    </TabContainer> */}

      <FooterMenu />
    </Container>
  );
}
