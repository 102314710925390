import styled, { css, keyframes } from 'styled-components';
import background from '../../assets/images/background_register.png';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 1em;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(${background});
    background-position: center;
  }
  padding-bottom: 4em;

`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 2em;
`;

export const HeaderTitle = styled.span`
	color: ${(props) => props.theme.color.primary};
	font-family: ${props => props.theme.font.rufina};
	letter-spacing: 1px;
	font-weight: 600;
	font-size: 1em;
`;

export const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.color.primary};
	font-size: 1.5em;
`;


export const MyScheduleContainer = styled.div`
  width: 100%;
  // max-height: 900px;
  // height: 900px;
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical quando necessário */
`;

export const SliderContainer = styled.div``;

export const Slider = styled.div`
  display: flex;
  gap: 1em;
  overflow-y: scroll;
  padding: 1em;
  align-items: center;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const Employee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;
`;

export const EmployeeImage = styled.img<{ selected?: boolean }>`
  width: 6em;
  height: 6em;
  border: 8px solid rgba(128, 128, 128, 0.2);
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 12px 1px #5d17eb;
    `}
`;

export const EmployeeName = styled.span`
  color: black;
  font-size: 0.8em;
  letter-spacing: 1px;
`;

export const BackofficeButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;

  /* Adicionando sombra para dar uma aparência flutuante */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
`;

export const TabContainer = styled.div`
  width: 100%;
`;

export const ContainerTab = styled.div`
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;

export const ColaboratorsLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const ColaboratorsLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 6em;
  height: 6em;
`;


export const MyScheduleLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const MyScheduleLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 90vw;
  height: 50vh;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1em;
  margin-bottom: 4em;
  z-index: 500; /* Valor alto para garantir que fique sobre todos os outros componentes */
`;

export const RecurrenceSelectContainer = styled.div`
  margin-bottom: 2em;
`
