import { useEffect, useState } from 'react';
import {
  Container,
  DaysContainer,
  FormsContainer,
  Friday,
  Label,
  LabelContainer,
  Monday,
  Saturday,
  Sunday,
  Thursday,
  Tuesday,
  Wednesday
} from './styles';
import TimePickerBox from '../TimePickerBox';


interface TextboxParams {
  time:  string[];
  callbackTime: any;
  errorRequiredTime: boolean;
}

export default function RecurrenceSelect({
  time,
  callbackTime,
  errorRequiredTime
}: TextboxParams) {

  const [selectedDays, setSelectedDays] = useState([] as string[]);


  useEffect(() => {
    callbackTime(selectedDays)
  }, [selectedDays])

  const organizeDays = (das : string[]) : string[] => {
    const organizedDays = [] as string[];

    if(das.includes("SUNDAY")){
      organizedDays.push("SUNDAY")
    }

    if(das.includes("MONDAY")){
      organizedDays.push("MONDAY")
    }

    if(das.includes("TUESDAY")){
      organizedDays.push("TUESDAY")
    }

    if(das.includes("WEDNESDAY")){
      organizedDays.push("WEDNESDAY")
    }

    if(das.includes("THURSDAY")){
      organizedDays.push("THURSDAY")
    }

    if(das.includes("FRIDAY")){
      organizedDays.push("FRIDAY")
    }

    if(das.includes("SATURDAY")){
      organizedDays.push("SATURDAY")
    }

    return organizedDays;
  }

  const toggleDay = (day: string) => {
    setSelectedDays((prevSelectedDays: string[]) =>
      {
        const days = prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]

        return organizeDays(days)
      }
    );
  };

  const mapDayToPortugues = (day: string) => {
    let portugueseDay = "";

    if(day == "SUNDAY"){
      portugueseDay = "domingo"
    }

    if(day == "MONDAY"){
      portugueseDay = "segunda"
    }

    if(day == "TUESDAY"){
      portugueseDay = "terça"
    }

    if(day == "WEDNESDAY"){
      portugueseDay = "quarta"
    }

    if(day == "THURSDAY"){
      portugueseDay = "quinta"
    }

    if(day == "FRIDAY"){
      portugueseDay = "sexta"
    }

    if(day == "SATURDAY"){
      portugueseDay = "sábado"
    }

    return portugueseDay;
  }

  const verifyDayText = (selectedDays: string[]) => {
    let complementText = "";
    let daysText = "";
    if(selectedDays.length == 1){
      if(selectedDays[0] == "SATURDAY" || selectedDays[0] == "SUNDAY"){
        complementText = "Todo"
      } else {
        complementText = "Toda"
      }
      
    }

    if(selectedDays.length > 1 && selectedDays.length < 7){
      complementText = "A cada"
    }

    if(selectedDays.length == 7){
      complementText = "Todos os dias"
    }

    if(selectedDays.length > 0 && selectedDays.length  < 7){
      daysText = selectedDays.map(day => mapDayToPortugues(day)).join(", ")
    }

    return `${complementText} ${daysText}`
  }

  return (
    <Container>
      <LabelContainer>
        <Label>Dias</Label>
        {selectedDays.length > 0 ? (<Label>{verifyDayText(selectedDays)}</Label>) : (<></>)}
      </LabelContainer>
      <DaysContainer>
        <Sunday selected={selectedDays.includes('SUNDAY')} onClick={() => toggleDay('SUNDAY')}/>
        <Monday selected={selectedDays.includes('MONDAY')} onClick={() => toggleDay('MONDAY')}/>
        <Tuesday selected={selectedDays.includes('TUESDAY')} onClick={() => toggleDay('TUESDAY')}/>
        <Wednesday selected={selectedDays.includes('WEDNESDAY')} onClick={() => toggleDay('WEDNESDAY')}/>
        <Thursday selected={selectedDays.includes('THURSDAY')} onClick={() => toggleDay('THURSDAY')}/>
        <Friday selected={selectedDays.includes('FRIDAY')} onClick={() => toggleDay('FRIDAY')}/>
        <Saturday selected={selectedDays.includes('SATURDAY')} onClick={() => toggleDay('SATURDAY')}/>
      </DaysContainer>    
    </Container>
  );
}
