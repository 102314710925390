import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationAssociatePhotoForm = Yup.object().shape({
  customerId: Yup.string().required(dictionary.login.validation.required),
  scheduleId: Yup.string().required(dictionary.login.validation.required),
  serviceId: Yup.string().required(dictionary.login.validation.required),
});

export default validationAssociatePhotoForm;
