import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import {
  default as GetFinalClient,
  default as SearchFinalClientInput,
} from '../../@core/application/final-client/getFinalClients';
import SearchFinalClientForm from '../../@core/domain/ui/SearchFinalClientForm';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import CustomerCard from '../../components/CustomerCard';
import FooterMenu from '../../components/Menus/FooterMenu';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import Textbox from '../../components/TextboxMUI';
import dictionary from '../../config/dictionary';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Content,
  CustomerLoader,
  CustomerLoaderContainer,
  CustomersContainer,
  Form,
  Header,
  HeaderContainer,
  Instructiontext,
} from './styles';

export default function CustomersPage() {
  const { setLoading } = useContext(LoadingContextContent);
  const [customersLoading, setCustomersLoading] = useState(true);
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [blockRegister, setBlockRegister] = useState(false);
  const [customers, setCustomers] = useState([] as any);

  const formState: SearchFinalClientForm = {
    terms: undefined,
  };

  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: formState,
    // validationSchema: validationLogin,
    onSubmit: (value: SearchFinalClientForm) => {
      setLoading(true);
    },
  });

  useEffect(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    setCustomersLoading(true)
    const getAllFinalClientUseCase = new GetFinalClient();
    getAllFinalClientUseCase
      .execute({
        terms: registerForm.values.terms,
        perPage: 99999
      } as SearchFinalClientInput)
      .then((res: any) => {
        setCustomers(res.data.items);
        setCustomersLoading(false)
      }).catch(err => {
        setCustomersLoading(false)
        if(err?.response?.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, [registerForm.values.terms]);

  return (
    <Content>
      <HeaderContainer>
        <Header>{dictionary.customers.title}</Header>
        <Instructiontext>{dictionary.customers.instructions}</Instructiontext>
      </HeaderContainer>
      <Form>
        <Textbox
          label={dictionary.customers.search}
          name="terms"
          type="text"
          placeholder={dictionary.customers.search}
          value={registerForm.values.terms}
          setValue={registerForm.handleChange}
          error={registerForm.errors.terms}
          icon={faUser}
        />
      </Form>

      <CustomersContainer>
        {customersLoading ? [1,2,3,4,5,6,7].map(item => (
          <CustomerLoaderContainer>
            <CustomerLoader key={item}/>
          </CustomerLoaderContainer>)
        ) : customers.map((customer: any) => (
          <CustomerCard customer={customer} />
        ))}
      </CustomersContainer>
      <FooterMenu />
    </Content>

  );
}
