import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Service from "../../@core/domain/model/Service";
import { normalizeBucketURIFallback } from "../../utils/BucketAccess";
import { AddIconContainer, Container, ContainerAddImage, ContainerImage, Empty, GalleryList, Image, LoaderCard, TextAddImage, TrashContainer, TrashIcon } from "./styles";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "../../@core/domain/ui/ImageGallery";
import { useCallback, useEffect, useRef } from "react";

interface GalleryParams {
	images: ImageGallery[];
  addImageCallback: (newImageValue: string | ArrayBuffer | null, ref: File) => void;
  removeImageCallback: (image: ImageGallery) => void;
}

export function GalleryImages({
	images,
  addImageCallback,
  removeImageCallback
} : GalleryParams) {

  const newImageFile = useRef<HTMLInputElement>(document.createElement("input"));

  const changeNewImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log("cheguei")
      const fileReader = new FileReader();
      const { files } = event.target;

      if (files?.length === 0) return;

      fileReader.onload = () => {
        if(newImageFile.current?.files)
          addImageCallback(fileReader.result, newImageFile.current?.files[0]) 
      };
  
      fileReader.readAsDataURL(files![0]);
    },
    []
  );
	
	return (
		<Container>
			<GalleryList>
				{(images == undefined) ? (
					<>
						<LoaderCard/>
						<LoaderCard/>
						<LoaderCard/>
						<LoaderCard/>
						<LoaderCard/>
						<LoaderCard/>
					</>
				) : (
					images.map(image => (
						<ContainerImage key={Math.random()}>
							<Image key={Math.random()} src={image.isNew ? image.valueImage : normalizeBucketURIFallback("SERVICE", image.name)} alt={"image"}/>
              <TrashContainer>
                <TrashIcon>
                  <FontAwesomeIcon icon={faTrash} onClick={() => removeImageCallback(image)} />
                </TrashIcon>
              </TrashContainer>
						</ContainerImage>
					))
				)}

        {images.length < 6 ? (
          <ContainerAddImage  htmlFor="imageService" key={Math.random()}>
            <AddIconContainer>
              <FontAwesomeIcon icon={faPlus} />
            </AddIconContainer>
            <TextAddImage>Adicionar Imagem</TextAddImage>
            <input
              id="imageService"
              ref={newImageFile || undefined}
              key={Math.random()} // Adiciona uma chave única para recriar o input quando necessário
              hidden
              name="imageService"
              type="file"
              accept="image/*"
              onChange={(event) => changeNewImage(event)}
            />
          </ContainerAddImage>
        ) : (<></>)}
			</GalleryList>
		</Container>
	)
}
