import styled from 'styled-components';
import Select from '@mui/material/Select';
import { OutlinedInput } from '@mui/material';


export const MultiSelect = styled(OutlinedInput)`
  && {
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0; /* Bordas quadradas */
      border-color: transparent; /* Remover borda padrão */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra */
    }
  }

`;

export const ErrorMessage = styled.small<{ showPassword?: boolean }>`
  color: ${(props) => props.theme.color.error};
  padding-top: ${(props) => (props.showPassword ? '0' : '6px')};
  letter-spacing: 1px;
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1.2em;
  color: ${(props) => props.theme.color.white};
`;

export const SelectContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.input};
  padding-right: 0.2em;
  border: ${(props) => props.theme.color.borderUnfocused};
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
