import Colaborator from '../model/Colaborator';

export default interface RegisterColaboratorForm {
  name: string;
  email: string;
  password: string;
  description: string | undefined;
  imagePerfil: any;
  startLunchTime: string
  endLunchTime: string
  startWork: string
  endWork: string
  age: any;
  facebookURL: string;
  gmailURL: string;
  instagramURL: string;
  whatsappURL: string;
  passwordRepeat: string;
  active?: boolean;
  id?: number;
}

export const MapToColaborator = (
  form: RegisterColaboratorForm,
  image: File | null
): Colaborator => {
  return {
    name: form.name,
    email: form.email,
    password: form.password,
    description: form.description,
    login: form.email,
    imagePerfil: image,
    id: form.id,
    age: Number(form.age),
    establishmentId: 0,
    colaboratorRole: '',
    startLunchTime: form.startLunchTime,
    endLunchTime: form.endLunchTime,
    startWork: form.startWork,
    endWork: form.endWork,
    socialLinks: {
      facebookURL: form.facebookURL,
      instagramURL: form.instagramURL,
      gmailURL: form.gmailURL,
      whatsappURL: form.whatsappURL,
    },
  };
};
