import { AxiosResponse } from 'axios';
import * as addressService from '../../infrastructure/api/services/addressService';
import IUseCase from '../IUseCase';

export default class GetAllAddressByEstablishmentId implements IUseCase<number, Promise<AxiosResponse>>
{
  execute(id: number) {
    return addressService.getAllAddressByEstablishmentId(id);
  }
}
