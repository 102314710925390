import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';
import { Position } from '../../domain/model/Positions';


export interface OrganizeServicesInput {
  positions: Position[];
}


export default class OrganizeServicesUseCase implements IUseCase<OrganizeServicesInput, Promise<any>> {
  execute(organizeServicesInput: OrganizeServicesInput) {
    return serviceService.organizeServices(organizeServicesInput);
  }
}
