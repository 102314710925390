import IUseCase from '../IUseCase';
import * as categoryService from '../../infrastructure/api/services/categoryService';
import { AxiosResponse } from 'axios';

export default class DeleteCategory
  implements IUseCase<string, Promise<AxiosResponse>>
{
  execute(categoryId: string): Promise<AxiosResponse> {
    return categoryService.deleteCategory(categoryId);
  }
}
