import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import background from '../../assets/images/background_register.png';

export const Content = styled.div`
  display: flex;
  padding: 1em;
  padding-top: 4em;
  flex-direction: column;
  align-items: center;
  gap: 3em;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;


export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

export const CustomersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  heigth: 100%;
  gap: 30px;
  overflow-y: auto;
  max-height: 60vh;
  padding-bottom: 30px;
`;


export const CustomerLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const CustomerLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 100%;
  height: 7em;
`;
