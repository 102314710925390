import { getEstablishment } from '../../infrastructure/api/services/storageService';
import Service from '../model/Service';
import { Option } from 'react-multi-select-component';

export default interface RegisterServiceForm {
  name: string;
  description: string;
  collaboratorsOptions: string[];
  price: number;
  genre: string;
  duration: string;
  ageGroup: string;
  categoryId: number;
  specializationService: boolean;
  id?: number;
}

export const MapToService = (form: RegisterServiceForm): Service => {
  return {
    establishmentId: parseInt(getEstablishment() ?? ''),
    name: form.name,
    description: form.description,
    collaboratorsIds: [],
    price: form.price,
    genre: form.genre,
    duration: parseInt(form.duration),
    ageGroup: form.ageGroup,
    category: {
      id: form.categoryId,
    },
    categoryId: form.categoryId,
    specializationService: form.specializationService,
    images: [],
    id: form.id,
  };
};
