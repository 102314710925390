import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const BlockScheduleValidation = Yup.object().shape({
  title: Yup.string().required(dictionary.global.validation.required),
  colaboratorId: Yup.string().required(dictionary.global.validation.required),
  initialTime: Yup.string().nullable().required(dictionary.global.validation.required),
  endTime: Yup.string().required(dictionary.global.validation.required)
});

export default BlockScheduleValidation;
