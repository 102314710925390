import {
  faCalendarAlt,
  faHouse,
  faWandMagic,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  CalendarButton,
  CalendarIcon,
  Container,
  Icon,
  IconContainer,
  IconContainerCenter,
  TogglePage,
  ToggleText,
  TopContainer,
} from './style';

import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";


export default function FooterMenu() {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <TogglePage onClick={() => navigate('/inicio')}>
          <IconContainer>
            <AiOutlineHome />
          </IconContainer>
          <ToggleText>Início</ToggleText>
        </TogglePage>

        <TogglePage onClick={() => navigate('/minha-agenda')}>
          <IconContainer>
            <IoCalendarOutline />
          </IconContainer>
          <ToggleText>Agenda</ToggleText>
        </TogglePage>

        <TogglePage onClick={() => navigate('/customizar')}>
          <IconContainer>
            <MdOutlineDashboardCustomize />
          </IconContainer>
          <ToggleText>Customizar</ToggleText>
        </TogglePage>

      </Container>
      {/* <TopContainer>
        <CalendarButton onClick={() => navigate('/minha-agenda')}>
          <IconContainer>
            <IoCalendarOutline />
          </IconContainer>
        </CalendarButton>
      </TopContainer> */}
    </>
  );
}
