import axios from 'axios';
import { getLoginData } from './services/storageService';
import LoginReturn from '../../domain/model/LoginReturn';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  function (config) {
    const loginData = JSON.parse(getLoginData() as string) as LoginReturn;
    
    const headers: any = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };

    if (loginData && loginData.token) {
      headers.Authorization = `Bearer ${loginData.token}`;
    }

    config.headers = { ...headers, ...config.headers };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
