'use client';

import styled, { css } from 'styled-components';
import backgroundImage from '../../assets/images/background_register.png';

interface ContainerParams {
  isloading?: boolean;
}

export const Container = styled.div<ContainerParams>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isloading }) =>
    isloading &&
    css`
      pointer-events: none;
      overflow: hidden;
    `}
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;
