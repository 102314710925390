import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import GetQuantitySchedulesUseCase, {
  GetQuantitySchedulesInput,
} from '../../@core/application/analytics/getQuantitySchedules';
import ScheduleAnalytics, {
  Category,
  Service,
} from '../../@core/domain/model/ScheduleAnalytics';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DateTimePickerBox from '../../components/DateTimePicker';
import FooterMenu from '../../components/Menus/FooterMenu';
import { checkUserAuthenticated, getColaboratorId, getPermission } from '../../components/PrivateRoute';
import dictionary from '../../config/dictionary';
import LoadingContextContent from '../../contexts/LoadingContext';
import Painel from '../../interfaces/painel';
import { formatADateObjectWithHourAndSecondsFormat } from '../../utils/HoursUtils';
import {
  Content,
  Form,
  Header,
  HeaderContainer,
  Instructiontext,
  MetricsComponent,
  MetricsContainer,
  MetricsLabel,
  MetricsSection
} from './styles';
import validationLogin from './validation';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import Colaborator from '../../@core/domain/model/Colaborator';
import SelectBox from '../../components/Selectbox';

export default function InvoicingPage() {
  const { setLoading } = useContext(LoadingContextContent);

  const [scheduleAnalytics, setScheduleAnalytics] = useState<ScheduleAnalytics>();
  const [dataServiceTypeQuantity, setDataServiceTypeQuantity] = useState<{ name: string; value: number }[]>([]);
  const [dataServiceNameQuantity, setDataServiceNameQuantity] = useState<{ name: string; value: number }[]>([]);
  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [permission, setPermission] = useState();

  const navigate = useNavigate();

  const formState: Painel = {
    dateEnd: '',
    dateStart: '',
    colaboratorId: getColaboratorId()
  };

  const filterForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: false,
    initialValues: formState,
    validationSchema: validationLogin,
    onSubmit: (value: Painel) => {
      if(value.colaboratorId == -1){
        callAnalytics(value.dateStart, value.dateEnd, null);
      } else {
        callAnalytics(value.dateStart, value.dateEnd, value.colaboratorId);
      }
    },
  });

  const callAnalytics = useCallback(
    (initialIntervalDate: String, endIntervalDate: String, colaboratorId?: Number | null) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      console.log("initialIntervalDate", initialIntervalDate)
      console.log("endIntervalDate", endIntervalDate)

      setLoading(true);

      // Adicionando 3 horas às datas
      // const adjustedStartDate = moment(initialIntervalDate).add(3, 'hours');
      // const adjustedEndDate = moment(endIntervalDate).add(3, 'hours');

      // const formattedStartDate = adjustedStartDate.format(
      //   'YYYY-MM-DDTHH:mm:ss'
      // );
      // const formattedEndDate = adjustedEndDate.format('YYYY-MM-DDTHH:mm:ss');

      const getQuantitySchedulesUseCase = new GetQuantitySchedulesUseCase();
      getQuantitySchedulesUseCase
        .execute({
          initialIntervalDate: initialIntervalDate,
          endIntervalDate: endIntervalDate,
          colaboratorId: colaboratorId
        } as GetQuantitySchedulesInput)
        .then((res) => {
          setScheduleAnalytics(res.data);

          const dataServiceTypeQuantity = res.data.categories.map(
            (category: Category) => {
              return {
                name: category.category,
                value: category.quantity,
              };
            }
          );

          const dataServiceNameQuantity = res.data.services.map(
            (service: Service) => {
              return {
                name: service.name,
                value: service.quantity,
              };
            }
          );

          setDataServiceNameQuantity(dataServiceNameQuantity);
          setDataServiceTypeQuantity(dataServiceTypeQuantity);


          setLoading(false);
        })
        .catch((err) => {
          console.log('Erro ao consultar analytics', err);
          setLoading(false);
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [setLoading]
  );

  useEffect(() => {
    const currentDate = new Date();

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 30);

    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 30);

    const formatedStartDate = formatADateObjectWithHourAndSecondsFormat(startDate);
    const formatedEndDate = formatADateObjectWithHourAndSecondsFormat(endDate);

    filterForm.setFieldValue("dateStart", formatedStartDate)
    filterForm.setFieldValue("dateEnd", formatedEndDate)

    const currentPermission = getPermission();
    setPermission(currentPermission)

    if(currentPermission == "OWNER"){
      callAnalytics(formatedStartDate, formatedEndDate);
    } else {
      const currentColaboratorId = getColaboratorId()
      callAnalytics(formatedStartDate, formatedEndDate, currentColaboratorId);
    }
  }, []);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      filterForm.submitForm();
    },
    [filterForm]
  );

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabelDataServiceTypeQuantity = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
    value: any;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fontSize={10}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {dataServiceTypeQuantity[index].name} (
        {`${(percent * 100).toFixed(0)}%`})
      </text>
    );
  };

  const renderCustomizedLabelDataServiceNameQuantity = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
    value: any;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fontSize={10}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {dataServiceNameQuantity[index].name} (
        {`${(percent * 100).toFixed(0)}%`})
      </text>
    );
  };

  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetColaborator();
    getUseCase.execute().then((response: any) => {
      setColaborators([
        {
          id: -1,
          name: 'Todos'
        },
        ...response.data
      ]);
    }).catch((err: any) => {
      console.log("Algum erro ocorreu na obtenção dos colaboradores", err)
    });
  }, []);

  useEffect(() => {
    findColaborators();
  }, []);

  return (
    <Content>
      <HeaderContainer>
        <Header>{dictionary.painel.welcomeTitle}</Header>
        <Instructiontext>{dictionary.painel.instruction}</Instructiontext>
      </HeaderContainer>
      <Form>

        {permission == "OWNER" ? 
          <SelectBox
            placeholder={dictionary.painel.colaborator}
            name="colaboratorId"
            value={filterForm.values.colaboratorId}
            setValue={filterForm.handleChange}
            error={filterForm.errors.colaboratorId}
            items={colaborators.map((colaborator) => {
              return { value: colaborator.id, label: colaborator.name };
            })}
          />
        : (<></>)}

        <DateTimePickerBox
          label={dictionary.painel.labelDateStart}
          name="dateStart"
          type="date"
          placeholder={dictionary.painel.dateStart}
          value={filterForm.values.dateStart}
          setValue={(newValue: string) => { filterForm.setFieldValue("dateStart", newValue) }}
          error={filterForm.errors.dateStart}
          icon={faCalendar}
        />
        <DateTimePickerBox
          label={dictionary.painel.labelDateEnd}
          name="dateEnd"
          type="date"
          placeholder={dictionary.painel.dateEnd}
          value={filterForm.values.dateEnd}
          setValue={(newValue: string) => { filterForm.setFieldValue("dateEnd", newValue) }}
          error={filterForm.errors.dateEnd}
          icon={faCalendar}
        />
        <Button
          colorType="primary"
          onClick={(e: any) => handleSubmit(e)}
        >
          {dictionary.painel.filter}
        </Button>
      </Form>
      <MetricsContainer>
        <MetricsSection>
          <MetricsComponent>
            R$ {scheduleAnalytics?.totals.invoicing}
            <MetricsLabel>Faturamento</MetricsLabel>
          </MetricsComponent>
          <MetricsComponent>
            {scheduleAnalytics?.totals.quantitySchedules}
            <MetricsLabel>Agendamentos</MetricsLabel>
          </MetricsComponent>
        </MetricsSection>
        <MetricsSection>
          <MetricsComponent>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={dataServiceTypeQuantity}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabelDataServiceTypeQuantity}
                  outerRadius={80}
                  dataKey="value"
                >
                  {dataServiceTypeQuantity.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </MetricsComponent>
        </MetricsSection>
        <MetricsSection>
          <MetricsComponent>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={dataServiceNameQuantity}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabelDataServiceNameQuantity}
                  outerRadius={80}
                  dataKey="value"
                >
                  {dataServiceNameQuantity.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </MetricsComponent>
        </MetricsSection>
      </MetricsContainer>
      <FooterMenu />
    </Content>
  );
}
