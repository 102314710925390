const dictionary = {
  global: {
    validation: {
      required: 'Campo obrigatório',
      email: 'Email não está no formato correto',
      maxLength: 'Tamanho máximo de {0} caracteres excedido',
      minLength: 'Tamanho mínimo de {0} caracteres',
      minAge: 'Idade mínima de {0} anos',
    },
  },
  login: {
    route: '/login',
    logoSubtitle: 'Lead of Multi-size cloths on <br/><b>South America</b>',
    welcomeTitle: 'Bem Vindo!',
    welcomeSubtitle: 'Por favor insira seus dados para continuar',
    labelEmail: 'Email',
    labelPassword: 'Senha',
    placeholderEmail: 'Insira seu email',
    placeholderPassword: '••••••••••',
    rememberPassword: 'Lembrar minha senha',
    enter: 'ENTRAR',
    forgetPassword: 'Esqueci minha senha',
    doNotHaveAccount: 'Não possui uma conta?',
    clickHere: 'Cadastre-se Aqui!',
    validation: {
      required: 'Campo obrigatório',
      email: 'Email não está no formato correto',
    },
  },
  forgotPassword: {
    code: 'Codígo',
    codeText: 'Codigo que você recebeu em seu e-mail',
    emailText: 'Digite o seu e-mail para receber um codígo de verificação',
    newPassword: 'Digite sua nova senha',
    confirmNewPassword: 'Confirme a nova senha',
  },
  register: {
    route: '/register',
    logoSubtitle: 'Lead of Multi-size cloths on <br/><b>South America</b>',
    welcomeTitle: 'Cadastre-se',
    labelOwner: 'Dono da barbearia',
    labelEmail: 'Email',
    labelPassword: 'Senha',
    labelTelephone: 'Celular',
    socialName: 'Nome social',
    fantasyName: 'Nome fantasia',
    startWork: 'Inicio expediente',
    endWork: 'Fim expediente',
    cnpj: 'CNPJ',
    CEP: 'CEP',
    street: 'Rua',
    number: 'Número',
    neighborhood: 'Bairro',
    city: 'Cidade',
    country: 'Pais',
    placeholderEmail: 'Insira seu email',
    placeholderPassword: '••••••••••',
    placeholderOwner: 'Dono da barbearia',
    placeholderTelephone: '(19) 9 9999-9999',
    placeholderNameCompany: 'Nome da barbearia',
    placeholderSite: 'Domínio da barbearia',
    placeholderEmailBarber: 'Email da barbearia',
    placeholderName: 'Seu nome',
    placeholderBirthdate: 'Data de nascimento',
    placeholderCnpj: 'CNPJ',
    placeholderCountry: 'Pais',
    next: 'Proximo',
    save: 'Salvar',
    validation: {
      required: 'Campo obrigatório',
      email: 'Email não está no formato correto',
    },
  },
  customers: {
    title: 'Clientes',
    instructions: 'Gerencie sua rede de clientes em apenas um lugar',
    search: 'Nome, telefone ou email',
  },
  associatePhotoCustomer: {
    title: 'Histórico',
    instruction:
      'Publique a foto do serviço realizado para seu cliente, ele poderá visualizar em sua galeria pessoal',
  },
  agenda: {
    welcomeTitle: 'Agenda',
    instruction: 'Toque no colaborador para ver os compromissos',
  },
  painel: {
    route: '/painel',
    logoSubtitle: 'Lead of Multi-size cloths on <br/><b>South America</b>',
    welcomeTitle: 'Faturamento',
    instruction: 'Métricas do seu estabelecimento',
    labelDateStart: 'Data de inicio',
    labelDateEnd: 'Data de fim',
    dateStart: 'Data de inicio',
    dateEnd: 'Data de fim',
    colaborator: 'Colaborador',
    validation: {
      required: 'Campo obrigatório',
    },
    filter: 'Filtrar',
  },
  coworker: {
    validation: {
      startLunchTimeRequired: 'Campo inicio horário de almoço obrigatório',
      endLunchTimeRequired: 'Campo fim horário de almoço obrigatório',
      startWorkRequired: 'Campo inicio expediente obrigatório',
      endWorkRequired: 'Campo final de expediente obrigatório',
    },
  },
  copyright: 'Umbrella © 2022 - 2023. Todos os direitos reservados.',
};

export default dictionary;
