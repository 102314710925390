import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  display: flex;
  padding-top: 6em;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  width: 100%;
  padding: 1em;
  padding-top: 3em;
`;

export const ItemImage = styled.button`
  background-color: #e9e9e9;
  border: none;
  padding: 1em;
  font-family: 'Sedan SC';
  font-size: 18px;
  display: flex;
  justify-content: center;
  gap: 15px;

  svg {
    color: ${(props) => props.theme.color.primary};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;
  

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;


export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;


export const Bee8bBaseboardTitle = styled.h1`
  color: ${(props) => props.theme.color.primary};
  font-size: 2.2em;

  @media only screen and (max-width: 768px) {
    font-size: 1.8em;
  }
`;

export const Bee8bBaseboardSubtitle = styled.h1`
  color: ${(props) => props.theme.color.gray};
  opacity: 0.8;
  @media only screen and (max-width: 768px) {
    font-size: 1.2em;
    opacity: 0.5;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 5em;
  width: 100%;
  gap: 1em;
`;

export const CustomersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  heigth: 100%;
  gap: 30px;
  overflow-y: auto;
  padding-bottom: 30px;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const GalleryImagesLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: calc(33.33% - 1em); /* 3 itens por linha com margem entre eles */
  margin-bottom: 1em; /* Espaçamento inferior entre as linhas */
  height: 8em;
`;

export const GalleryImagesLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Adicionado para envolver os itens */
  justify-content: space-between; /* Distribui os itens uniformemente */
`;
