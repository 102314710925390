import ChangePassword from '../../../domain/model/ChangePassword';
import Login from '../../../domain/model/Login';
import RefreshToken from '../../../domain/model/RefreshToken';
import Register from '../../../domain/model/Register';
import ResetPassword from '../../../domain/model/ResetPassword';
import api from '../api';

export const doRegister = (register: Register) => {
  return api.post('/establishments', register);
};

export const doLogin = (login: Login) => {
  return api.post('/oauth/login', login);
};

export const refreshToken = (refreshToken: RefreshToken) => {
  return api.post(`/oauth/refresh-token`, refreshToken);
};
export const forgotPassword = (reset: ResetPassword) => {
  return api.post(`/oauth/reset-password`, reset);
};
export const changePassword = (changePassword: ChangePassword) => {
  return api.post(`/oauth/change-password`, changePassword);
};
