import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import { AxiosResponse } from 'axios';
import Schedule from '../../domain/model/Schedule';
import GetScheduleFilter from '../../domain/filter/GetScheduleFilter';
import SaveSchedule from '../../domain/model/SaveSchedule';
import DeleteSchedule from '../../domain/model/DeleteSchedule';

export default class DeleteScheduleCase
  implements IUseCase<DeleteSchedule, Promise<AxiosResponse>>
{
  execute(data: DeleteSchedule) {
    return scheduleService.deleteSchedule(data);
  }
}
