import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import { AxiosResponse } from 'axios';
import Schedule from '../../domain/model/Schedule';
import GetScheduleFilter from '../../domain/filter/GetScheduleFilter';
import SaveSchedule from '../../domain/model/SaveSchedule';

export default class AddScheduleCase
  implements IUseCase<SaveSchedule, Promise<AxiosResponse>>
{
  execute(data: SaveSchedule) {
    return scheduleService.addSchedule(data);
  }
}
