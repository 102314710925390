import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate,
} from 'react-router-dom';
import LoginPage from './pages/login';
import Register from './pages/register';
import Dashboard from './pages/dashboard';
import CoWorkers from './pages/coworkers';
import Services from './pages/servicesHandle';
import Home from './pages/home';
import CustomizeLayout from './pages/customizeLayout';
import MySchedule from './pages/mySchedule';
import Error404 from './pages/errors/404';
import NoConnection from './pages/errors/noConnection';
import Categories from './pages/categories';
import CustomersPage from './pages/customers';
import AssociatePhotoPage from './pages/associatePhoto';
import ServiceCreate from './pages/serviceCreate';
import ServiceUpdate from './pages/serviceUpdate';
import PrivatePageContextWrapper from './contexts/privatePageContext';
import NotificationSend from './pages/notificationSend';
import EstablishmentInformations from './pages/establishmentInformations';
import AppInformations from './pages/appInformations';
import CarouselHandle from './pages/carouselHandle';
import LinkToApp from './pages/linkToApp';
import ForgotPassword from './pages/forgotPassword';
import FinishedRegisterPage from './pages/finishedRegister';
import WelcomePage from './pages/welcomePage';
import BlockSchedule from './pages/blockSchedule';
import AddSchedule from './pages/addSchedule';
import InvoicingPage from './pages/invoicing';
import RecurrenceBlockSchedule from './pages/recurrenceBlockSchedule';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/:nameSite/login"
          element={
            <PrivatePageContextWrapper>
              <LoginPage />
            </PrivatePageContextWrapper>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/registre-se"
          element={
            <Register />
          }
        />
        <Route
          path="/:tenant/boas-vindas/colaborador/:id"
          element={
            <WelcomePage />
          }
        />

        <Route
          path="/novo-estabelecimento"
          element={
            <FinishedRegisterPage />
          }
        />
        
        <Route
          path="/foto-meu-cliente/:id"
          element={
            <PrivatePageContextWrapper>
              <AssociatePhotoPage />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/foto-meu-cliente"
          element={
            <PrivatePageContextWrapper>
              <AssociatePhotoPage />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/clientes"
          element={
            <PrivatePageContextWrapper>
              <CustomersPage />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivatePageContextWrapper>
              <Dashboard />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/colaboradores"
          element={
            <PrivatePageContextWrapper>
              <CoWorkers />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/servicos"
          element={
            <PrivatePageContextWrapper>
              <Services />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/criar-servico"
          element={
            <PrivatePageContextWrapper>
              <ServiceCreate />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/link-meu-app"
          element={
            <PrivatePageContextWrapper>
              <LinkToApp />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/servico/:id"
          element={
            <PrivatePageContextWrapper>
              <ServiceUpdate />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/inicio"
          element={
            <PrivatePageContextWrapper>
              <Home />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/faturamento"
          element={
            <PrivatePageContextWrapper>
              <InvoicingPage />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/customizar"
          element={
            <PrivatePageContextWrapper>
              <CustomizeLayout />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/minha-agenda"
          element={
            <PrivatePageContextWrapper>
              <MySchedule />
            </PrivatePageContextWrapper>
          }
        />

        <Route
          path="/bloquear-agenda"
          element={
            <PrivatePageContextWrapper>
              <BlockSchedule />
            </PrivatePageContextWrapper>
          }
        />

        <Route
          path="/cadastrar-agendamento"
          element={
            <PrivatePageContextWrapper>
              <AddSchedule />
            </PrivatePageContextWrapper>
          }
        />
        
        <Route path="/sem-conexao" element={<NoConnection />} />
        <Route
          path="/informacoes-do-estabelecimento"
          element={
            <PrivatePageContextWrapper>
              <EstablishmentInformations />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/informacoes-no-app"
          element={
            <PrivatePageContextWrapper>
              <AppInformations />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/imagens-carousel"
          element={
            <PrivatePageContextWrapper>
              <CarouselHandle />
            </PrivatePageContextWrapper>
          }
        />
        <Route
          path="/categorias"
          element={
            <PrivatePageContextWrapper>
              <Categories />
            </PrivatePageContextWrapper>
          }
        />

        <Route
          path="/enviar-notificacao"
          element={
            <PrivatePageContextWrapper>
              <NotificationSend />
            </PrivatePageContextWrapper>
          }
        />

        <Route
          path="/compromisso-recorrente"
          element={
            <PrivatePageContextWrapper>
              <RecurrenceBlockSchedule />
            </PrivatePageContextWrapper>
          }
        />

        <Route
          path="/:nameSite/esqueci-minha-senha"
          element={
            <PrivatePageContextWrapper>
              <ForgotPassword />
            </PrivatePageContextWrapper>
          }
        />
        <Route path="/*" element={<Error404 />} />
      </Switch>
    </BrowserRouter>
  );
}
