import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 1em;
  margin-top: 2em;
`;
