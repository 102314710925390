import { Backdrop, Box, Typography } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import dictionary from '../../config/dictionary';

import { Scheduler } from '@aldabil/react-scheduler';
import { SchedulerRef } from '@aldabil/react-scheduler/types';
import { DayProps } from '@aldabil/react-scheduler/views/Day';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { styled } from '@mui/material/styles';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import * as React from 'react';
import { useAlert } from 'react-alert';
import { IoAddCircleOutline } from "react-icons/io5";
import { TbLock } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import GetFinalClient from '../../@core/application/final-client/getFinalClients';
import DeleteScheduleCase from '../../@core/application/schedule/deleteScheduleCase';
import GetByColaboratorCase from '../../@core/application/schedule/getSchedulesByFilterCase';
import GetServices from '../../@core/application/services/getServices';
import GetScheduleFilter from '../../@core/domain/filter/GetScheduleFilter';
import Colaborator from '../../@core/domain/model/Colaborator';
import FinalClient from '../../@core/domain/model/FinalClient';
import Service from '../../@core/domain/model/Service';
import { IoMdClose } from "react-icons/io";
import SchedulerEvent, {
  MapFromSchedule,
  MapToDelete,
} from '../../@core/domain/ui/SchedulerEvent';
import { getEstablishment, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import { checkUserAuthenticated, getColaboratorId, getPermission } from '../../components/PrivateRoute';
import LoadingContextContent from '../../contexts/LoadingContext';
import { normalizeBucketURIFallback } from '../../utils/BucketAccess';
import { Header } from '../login/styles';

import { VscWarning } from 'react-icons/vsc';
import FooterMenu from '../../components/Menus/FooterMenu';
import { TextActiveAccount } from '../coworkers/styles';
import {
  ButtonContainer,
  CancelButton,
  CancelContainer,
  CancelRecurrenceButton,
  ColaboratorsLoader,
  ColaboratorsLoaderContainer,
  ConfirmScheduleButton,
  Content,
  Employee,
  EmployeeImage,
  EmployeeName,
  HeaderContainer,
  IconActiveAccountContainerWarning,
  IconCancelContainer,
  IconContainer,
  Instructiontext,
  MyScheduleLoader,
  MyScheduleLoaderContainer,
  Slider,
  SliderContainer,
  ViewerExtraContainer,
  WarningActiveAccountContainer
} from './styles';
import { formatWeekDay } from '../../utils/HoursUtils';
import DialogFormModal from '../../components/DialogFormModal';
import RecurrenceBlock from '../../@core/domain/model/RecurrenceBlock';
import GetRecurrencesBlockUseCase from '../../@core/application/schedule/getRecurrencesBlock';
import { Col } from 'react-grid-system';
import DialogModal from '../../components/DialogModal';
import DeleteRecurrenceUseCase from '../../@core/application/schedule/deleteRecurrenceUseCase';

export default function MySchedule() {
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();

  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [colaboratorsLoading, setColaboratorsLoading] = useState(true);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [isCommitmentConfirmationModalOpen, setIsCommitmentConfirmationModalOpen] = useState(false);
  
  const [isCancelationRecurrenceModalOpen, setIsCancelationRecurrenceModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>();
  const [promiseCancelation, setPromiseCancelation] = useState<any>();
  const [dayReplicas, setDayReplicas] = useState<string[]>([]);
  const [monthReplicas, setMonthReplicas] = useState<any>([]);
  
  const [deleteId, setDeletedId] = useState<any>();

  const [finalClients, setFinalClients] = useState([] as FinalClient[]);
  const [services, setServices] = useState([] as Service[]);
  const [recurrences, setRecurrences] = useState([] as RecurrenceBlock[]);

  const [permission, setPermission] = useState();
  const [colaboratorId, setColaboratorId] = useState();
  const [textFormValue, setTextFormValue] = useState("Caro cliente, infelizmente não será possível atende-lo. Estamos ansiosos pelo próximo agendamento!");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const calendarRef = useRef<SchedulerRef>(null);
  const [events, setEvents] = useState<SchedulerEvent[]>([]);
  const [blockEvents, setBlockEvents] = useState<SchedulerEvent[]>([]);
  const [processedMonths, setProcessedMonths] = useState<Number[]>([]);
  

  const [colaboratorEventsLoading, setColaboratorEventsLoading] = useState(false);
  const eventsRef = useRef([] as SchedulerEvent[]);
  eventsRef.current = events;

  const [selected, setSelected] = useState<Colaborator>();
  const selectedRef = useRef<Colaborator>();
  selectedRef.current = selected;


  const openCancelationModal = async (deleteId: string | number) : Promise<string | number> => {
    setIsReasonModalOpen(true)
    setDeletedId(deleteId)


    let promise = new Promise<string | number>(async (res, rej) => {});
    setPromiseCancelation(promise);
    return promise;
  }

  const openCancellationCommitmentModal = async (deleteId: string | number) : Promise<string | number> => {
    setIsCommitmentConfirmationModalOpen(true)
    setDeletedId(deleteId)

    let promise = new Promise<string | number>(async (res, rej) => {});
    setPromiseCancelation(promise);
    return promise;
  }

  const openCancelationRecurrenceModal = (deleteId: string | number) => {
    setIsCancelationRecurrenceModalOpen(true)
    setDeletedId(deleteId)
  }

  const handleDeleteEvent = useCallback(async (eventId: string | number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const deleteCase = new DeleteScheduleCase();
      const selectedEvent = eventsRef.current.find(
        (event) => event.event_id === eventId
      );

      console.log("deleteId", eventId)
      console.log("selectedEvent", selectedEvent)

      const deleteSchedule = MapToDelete(
        selectedEvent as SchedulerEvent,
        selectedRef.current?.id as number,
        textFormValue
      );

      setLoading(true);

      deleteSchedule.reason = btoa(encodeURIComponent(deleteSchedule.reason))

      const responseDelete = await deleteCase.execute(deleteSchedule).then((res: any) => res).catch(err => {
        setLoading(false);
      }).catch(err => {
        if (err.response?.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
        console.log("err", err)
      })

      setLoading(false);
      setIsReasonModalOpen(false)

      if (responseDelete?.status < 400) {
        showAlert.success('Agenda deletada com sucesso!');
        setEvents(prev => prev.filter(event => event.event_id != deleteId))
        return;
      }

      showAlert.error('Houve um erro ao deletar a agenda!');
  }, [promiseCancelation, textFormValue, setEvents]);


  const handleSelect = useCallback(
    (colaborator: Colaborator) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const getByColaboratorCase = new GetByColaboratorCase();
      const currentDate = calendarRef.current?.scheduler.selectedDate;
      const month = currentDate?.getMonth() as number;
      const year = currentDate?.getFullYear() as number;
      const firstDay = new Date(year, month - 12, 1);
      const lastDay = new Date(year, month + 12, 0);

      const filter: GetScheduleFilter = {
        colaboratorId: colaborator.id as number,
        establishmentId: parseInt(getEstablishment() as string),
        initialIntervalDate: `${moment(firstDay).format(
          'yyyy-MM-DDThh:mm:ss'
        )}`,
        endIntervalDate: `${moment(lastDay).format('yyyy-MM-DDThh:mm:ss')}`,
      };

      setColaboratorEventsLoading(true)

      getByColaboratorCase
        .execute(filter)
        .then((response: any) => {
          const schedulesResponse = response.data;
          const eventsMapped = schedulesResponse.map((schedule : any) =>
            MapFromSchedule(schedule)
          );

          setEvents(eventsMapped);
          setLoading(false);
          setColaboratorEventsLoading(false)
        })
        .catch((err : any) => {
          setLoading(false);
          setColaboratorEventsLoading(false)
          if (err.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
      setSelected(colaborator);
    },
    [calendarRef, setEvents]
  );
  
  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    setColaboratorsLoading(true)
    const getUseCase = new GetColaborator();
    getUseCase.execute().then((response: any) => {
      setColaboratorsLoading(false)
      if(permission === "OWNER"){
        setColaborators(response.data);
      } else {
        setColaborators(response.data.filter((colaborator: Colaborator) => colaborator.id == colaboratorId));
      }
    }).catch(err => {
      setColaboratorsLoading(false)
      if (err.response?.status == 401) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
      };
    });
  }, [permission, colaboratorId]);

  const findServices = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetServices();
    getUseCase.execute().then((response) => {
      setServices(response.data.items);
    }).catch(err => {
      if (err.response.status == 401) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
      };
    });
  }, []);

  const findFinalClients = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getFinalClients = new GetFinalClient();
    getFinalClients
      .execute(undefined)
      .then((response: any) => {
        setFinalClients(response.data.items);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  const findRecurrenceBlocks = (selectedColaborator : Colaborator) => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    if(!selectedColaborator) return

    const getRecurrencesBlock = new GetRecurrencesBlockUseCase();
    getRecurrencesBlock
      .execute(selectedColaborator.id ?? 0)
      .then((response: any) => {
        setRecurrences(response.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  };


  useEffect(() => {  
    const currentPermission = getPermission();
    setPermission(currentPermission)

    const currentColaboratorId = getColaboratorId()
    setColaboratorId(currentColaboratorId)
  }, []);

  useEffect(() => {
    if(permission){
      findServices();
      findColaborators();
      findFinalClients();
    }
  }, [permission]);

  useEffect(() => {
    if(colaborators.length > 0){
      handleSelect(colaborators[0])
    }
  },[colaborators])

  useEffect(() => {
    if(selected){
      setBlockEvents([])
      findRecurrenceBlocks(selected);
    }
  },[selected, setBlockEvents])


  const handleOpenModal = (itemSelected: string) => {
    if (itemSelected === "Agendamento") {
      navigate(`/cadastrar-agendamento`)
    }

    if (itemSelected === "Compromisso") {
      navigate(`/bloquear-agenda`)
    }

    if (itemSelected === "Compromisso Recorrente") {
      navigate(`/compromisso-recorrente`)
    }
  }

  const actions = [
    { icon: <IconContainer><IoAddCircleOutline /></IconContainer>, name: 'Agendamento' },
    { icon: <IconContainer><TbLock /></IconContainer>, name: 'Compromisso' },
    { icon: <IconContainer><TbLock /></IconContainer>, name: 'Compromisso Recorrente' }
  ];

  const openWhatsApp = (phone: string, message: string) => {
    if(!phone){
      showAlert.info('Cliente não cadastrou whatsapp');
      return;
    }
    const normalizedPhone = phone.trim()
      .replace(/ /g, '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
    const whatsappURL = `https://wa.me/${normalizedPhone}?text=${message}`
    window.open(whatsappURL);
  };


  function generateBlockEvent(day: String, month: String, year: Number, recurrenceBlock: RecurrenceBlock){
    const initialDateText = `${year}-${month}-${day}T${recurrenceBlock.initialTime}`
    const endTimeText = `${year}-${month}-${day}T${recurrenceBlock.endTime}`

    const blockEvent = {
      event_id: recurrenceBlock.id,
      title: recurrenceBlock.title,
      start: new Date(initialDateText),
      end: new Date(endTimeText),
      finalClientId: 0,
      finalClientName: "N/A",
      finalClientPhone: "N/A",
      isDefaulter: false,
      services: [],
      isBlock: true,
      color: "#5D17EB"
    } as SchedulerEvent;

    return blockEvent;
  }

  useEffect(() => {
    console.log("recurrences", recurrences)
    console.log("currentDate", currentDate)

    populateWithReplicas(recurrences);
   
  }, [currentDate, recurrences])

  const populateWithReplicas = useCallback((recurrences: RecurrenceBlock[]) => {
    if (currentDate && recurrences.length > 0) {
      handleReplicasMonth(currentDate);
    }

    if (currentDate == undefined && recurrences.length > 0) {
      handleReplicasMonth(new Date());
    }
  }, [currentDate, recurrences])

  const handleDeleteRecurrence = useCallback(async () => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const deleteRecurrenceUseCase = new DeleteRecurrenceUseCase();

    setLoading(true);

    const responseDelete = await deleteRecurrenceUseCase.execute(deleteId)
      .then((res: any) => res)
      .catch(err => {
        setLoading(false);
        if (err.response?.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
        console.log("err", err)
      })

      setLoading(false);
      setIsReasonModalOpen(false)

    if (responseDelete?.status < 400) {
      showAlert.success('Compromisso recorrente deletado com sucesso!');
      setRecurrences(recurrences => recurrences.filter(recurrence => recurrence.id !== deleteId))
      console.log("deleteId", deleteId)
      console.log("blockEvents", blockEvents)

      setBlockEvents(prev => prev.filter(event => event.event_id != deleteId))

      return;
    }

    showAlert.error('Houve um erro ao deletar o compromisso recorrente!');
}, [setLoading, deleteId, showAlert, navigate, setRecurrences, setBlockEvents, blockEvents]);

  const handleCommitmentExclusion = useCallback(async () => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    handleDeleteEvent(deleteId)
  }, [setLoading, deleteId, showAlert, navigate, setRecurrences, setBlockEvents, blockEvents]);

  const handleReplicasMonth = useCallback((date: Date) => {
    console.log("handleReplicas", date)
    const day = normalizeLeftZero(extractDay(date));
    const month = normalizeLeftZero(extractMonth(date));
    const year = extractYear(date);

    console.log("day", day)

    const allDaysOfMonth = extractDaysOfMonth(date)

    console.log("allDaysOfMonth", allDaysOfMonth)

    let newBlockEvents = [] as SchedulerEvent[]
    console.log("recurrences", recurrences)


    allDaysOfMonth.forEach(dayOfMonth => {
      let fullDateText = `${year}-${month}-${dayOfMonth}T00:00`;
      console.log("fullDateText", fullDateText)
      let dateObj = new Date(fullDateText);
      console.log("dateObj", dateObj)

      let weekDay = extractWeekDay(dateObj);
      console.log("weekDay", weekDay)

      let recurrenceBlocks = recurrences.filter(recurrence => recurrence.weekDays.includes(weekDay) && recurrence.colaboratorId === selected?.id);
      console.log("recurrenceBlocks", recurrenceBlocks)

      recurrenceBlocks.forEach((recurrenceBlock: RecurrenceBlock) => {
        let blockEvent = generateBlockEvent(normalizeLeftZero(dayOfMonth), month, year, recurrenceBlock);
        newBlockEvents.push(blockEvent)
      })
    })

    setBlockEvents(newBlockEvents)

    console.log("newBlockEvents", newBlockEvents)
   
  },[blockEvents, monthReplicas, recurrences, selected])

  function extractDaysOfMonth(date: Date) {
    // Extraindo o mês e o ano da data
    const month = extractMonth(date);
    const year = extractYear(date);

    // Obtendo o número de dias no mês
    const numberOfDays = new Date(year, month, 0).getDate();

    // Criando um array com os dias do mês
    const daysOfMonth = [];
    for (let day = 1; day <= numberOfDays; day++) {
        daysOfMonth.push(day);
    }

    return daysOfMonth;
}

  function extractDay(date: Date) {
    return date.getDate();
  }
  
  function extractMonth(date: Date) {
    return date.getMonth() + 1; // getMonth() returns a zero-based value, so we add 1
  }

  function extractYear(date: Date) {
    return date.getFullYear();
  }

  function extractWeekDay(date: Date) {
    const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
    return daysOfWeek[date.getDay()];
  }

  function normalizeLeftZero(num: number){
    if(num < 10){
      return "0" + String(num)
    }

    return String(num)
  }

  return (
    <Content>
      <HeaderContainer>
        <Header>{dictionary.agenda.welcomeTitle}</Header>
        <Instructiontext>{dictionary.agenda.instruction}</Instructiontext>
      </HeaderContainer>

      <SliderContainer>
        <Slider>
          {colaboratorsLoading ? (
            [1, 2, 3, 4, 5, 6].map(item => {
              return (<ColaboratorsLoaderContainer key={item}>
                <ColaboratorsLoader />
              </ColaboratorsLoaderContainer>)
            })
          ) : (
            colaborators.map((employee) => (
              <Employee
                key={employee.name}
                onClick={() => handleSelect(employee)}
              >
                <EmployeeImage
                  src={normalizeBucketURIFallback("COWORK", employee.imagePerfil)}
                  selected={selected?.id === employee.id}
                />
                <EmployeeName>
                  {employee.name.length > 11
                    ? employee.name.substring(0, 11) + '...'
                    : employee.name}
                </EmployeeName>
              </Employee>
            ))
          )}
        </Slider>
      </SliderContainer>

      {colaboratorEventsLoading || colaboratorsLoading ?
        <MyScheduleLoaderContainer>
          <MyScheduleLoader />
        </MyScheduleLoaderContainer>
        : (
            <Scheduler
              ref={calendarRef}
              editable={false}
              loading={false}
              deletable={false}
              draggable={false}
              onSelectedDateChange={setCurrentDate}
              height={600}
              view="day"
              onDelete={openCancelationModal}
              locale={ptBR}
              day={
                {
                  startHour: 6, //TODO: Deixar inicio e fim do expediente
                  endHour: 24,
                } as DayProps
              }
              translations={{
                navigation: {
                  month: 'Mês',
                  week: 'Semana',
                  day: 'Dia',
                  today: 'Hoje',
                },
                form: {
                  addTitle: 'Adicionar Agendamento',
                  editTitle: 'Editar Agendamento',
                  confirm: 'Confirmar',
                  delete: 'Deletar',
                  cancel: 'Cancelar',
                },
                event: {
                  title: 'Titulo',
                  start: 'Começo',
                  end: 'Fim',
                  allDay: 'Dia inteiro',
                },
                validation: {
                  required: 'Obrigatório',
                  invalidEmail: 'Email inválido',
                  onlyNumbers: 'Apenas números',
                  min: 'Mínimo {{min}} letters',
                  max: 'Máximo {{max}} letters',
                },
                moreEvents: 'Mais...',
                loading: 'Carregando...',
              }}
              events={[...events, ...blockEvents]}
              viewerExtraComponent={(fields, event) => {
                const services = event.title.split(" | ")[1]
                const obs = event.title.split(" | ")[2]
                
                const message = `
Olá, tudo bem? %0A
Gostaria de confirmar o agendamento abaixo %0A %0A 
*AGENDAMENTO* %0A 
======================== %0A 
*SERVIÇOS* %0A
${event.services.map((service: Service) => `* ${service.name}`).join("%0A")} %0A
======================== %0A 
*HORÁRIO* %0A ${formatWeekDay(event.start)} %0A %0A %0A 
Querido cliente, lembrando que agendamentos são apenas pelo aplicativo :)`
                
                return (
                  <ViewerExtraContainer>
                    <br/>
                    <p>{services}</p>
                    <br/>
                    <p>{obs}</p>

                    {event.isBlock && (
                      <CancelContainer onClick={() => openCancelationRecurrenceModal(event.event_id)}>
                        <CancelRecurrenceButton>
                          <IconCancelContainer>
                            <IoMdClose />
                          </IconCancelContainer>
                          Excluir recorrência
                        </CancelRecurrenceButton>
                      </CancelContainer>
                    )}

                    {!event.isBlock && event.services.length > 0 && (
                      <CancelContainer onClick={() => openCancelationModal(event.event_id)}>
                        <CancelButton>
                          <IconCancelContainer>
                            <IoMdClose />
                          </IconCancelContainer>
                          Rejeitar
                        </CancelButton>
                      </CancelContainer>
                    )}

                    
                    {!event.isBlock && event.services.length == 0 && (
                      <CancelContainer onClick={() => openCancellationCommitmentModal(event.event_id)}>
                        <CancelButton>
                          <IconCancelContainer>
                            <IoMdClose />
                          </IconCancelContainer>
                          Cancelar
                        </CancelButton>
                      </CancelContainer>
                    )}
                    
                    {event.isDefaulter ? (
                      <>
                        <WarningActiveAccountContainer>
                          <IconActiveAccountContainerWarning>
                            <VscWarning />
                          </IconActiveAccountContainerWarning>
                          <TextActiveAccount>
                            Cliente {event.finalClientName} possui histórico de cancelamentos, sugerimos enviar uma mensagem de confirmação clicando no botão abaixo
                          </TextActiveAccount>
                        </WarningActiveAccountContainer>
                      </>
                    ) : (<></>)}


                    {event.isBlock ? (<></>) : (
                      <ConfirmScheduleButton onClick={() => openWhatsApp(event.finalClientPhone, message)}><span>Solicitar confirmação via Whatsapp</span></ConfirmScheduleButton>
                    )}

                  </ViewerExtraContainer>
                );
              }}
            />
        )}

      <ButtonContainer>
        <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => handleOpenModal(action.name)}
              />
            ))}
          </SpeedDial>
        </Box>
      </ButtonContainer>

      <FooterMenu />

      {isReasonModalOpen ? (
        <DialogFormModal 
          isOpen={isReasonModalOpen} 
          setIsOpen={setIsReasonModalOpen} 
          setTextForm={setTextFormValue}
          textFormValue={textFormValue}
          title='Motivo cancelamento' 
          description='Caso queira justificar ao cliente, edite a mensagem abaixo ou retornaremos a resposta padrão.'
          submitButtonText='Finalizar'
          cancelButtonText='Voltar'
          callback={() => handleDeleteEvent(deleteId)}
          callbackReject={() => {setIsReasonModalOpen(false)}}
          placeHolder={"Motivo"}
          labelForm='Motivo' />
      ) : (<></>) }

      
      {isCancelationRecurrenceModalOpen ? (
        <DialogModal isOpen={isCancelationRecurrenceModalOpen}
          setIsOpen={setIsCancelationRecurrenceModalOpen}
          title={"IMPORTANTE"}
          message={"Você está prestes a excluir todos os compromissos recorrentes futuros. Tem certeza de que deseja continuar?"}
          callback={handleDeleteRecurrence}
          callbackReject={() => { }}
        />
      ) : (<></>)}

      {isCommitmentConfirmationModalOpen ? (
        <DialogModal isOpen={isCommitmentConfirmationModalOpen}
          setIsOpen={setIsCommitmentConfirmationModalOpen}
          title={"IMPORTANTE"}
          message={"Você está preste a excluir esse compromisso. Tem certeza que deseja continuar?"}
          callback={handleCommitmentExclusion}
          callbackReject={() => { }}
        />
      ) : (<></>) }
      
    </Content>
  );
}
