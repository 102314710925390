export const APP_ROUTES = {
  public: {
    login: 'login',
    register: 'registre-se',
    forgot: 'esqueci-minha-senha',
    withoutConnection: 'sem-conexao',
  },
};

export const checkIsPublicRoute = (asPath: string) => {
  console.log('as path', asPath);
  const appPublicRoutes = Object.values(APP_ROUTES.public);
  return appPublicRoutes.some((route) => asPath.includes(route));
};
