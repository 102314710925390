import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  padding: 10px;
  margin-left: 10px;
  width: 90%;
`;

export const Menu = styled.div`
  border-radius: 15px;
  padding: 15px;
`;
interface MenuItemProps {
  selected?: boolean;
}

export const MenuItemStyled = styled.div<MenuItemProps>`
  font-size: 18px;

  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  :hover {
    cursor: pointer;
  }

  a {
    margin-left: 15px;
    text-decoration: none;
  }
  svg {
    color: ${(props) => props.theme.color.primary};
  }
`;
export const FooterMenuComponent = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1.2em;
  background-color: #fff;
`;
export const ClearComponent = styled.div`
  clear: both;
  height: 60px;
`;
export const FooterMenuItem = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  color: ${(props) => props.theme.color.primary};

  svg {
    height: 1.4em;
  }
  a {
    margin-top: 0.3em;
  }
`;

export const Container = styled.div`
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0.6em;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);

  @media only screen and (min-width: 769px) {
    width: 50vw;
    padding: 0.6em 5vw; /* Espaçamento de 20vw nos lados esquerdo e direito */
    left: auto; /* Remover o left que estava centralizando anteriormente */
    transform: none; /* Remover o transform que estava centralizando anteriormente */
  }
`;

// export const TogglePage = styled(Link)<{ active?: boolean }>`
// 	display: flex;
// 	flex-direction: column;
// 	color: ${props => props.theme.globalStyles.components.navibar.iconsColor};
// 	text-decoration: none;
// 	gap: .2em;
// `;

export const TogglePage = styled.button<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.primary};
  text-decoration: none;
  gap: 0.2em;
  background: none;
  border: none;
  cursor: pointer;
  flex: 1; /* Para ocupar 33% da largura disponível */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &:active {
    transform: translateY(2px);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 0) 60%);
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2em;
`;

export const ToggleText = styled.span`
  font-size: 1em;
`;

export const TopContainer = styled.div`
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 40vw;
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
`;

export const CalendarButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.1em; /* Ajustei o valor do padding para evitar que a sombra seja cortada */
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: 0px -8px 10px -5px rgba(0, 0, 0, 0.1); /* Adicione esta linha para a sombra na parte superior */
  background-color: white;
  color: ${(props) => props.theme.color.primary};
`;

export const CalendarIcon = styled(FontAwesomeIcon)`
  font-size: 1.9em;
`;

export const IconContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  &:active {
    transform: translateY(2px);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 0) 60%);
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

export const IconContainerCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
`;



