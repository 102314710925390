import GetScheduleFilter from '../../../domain/filter/GetScheduleFilter';
import DeleteSchedule from '../../../domain/model/DeleteSchedule';
import RecurrenceBlock from '../../../domain/model/RecurrenceBlock';
import RecurringAppointment from '../../../domain/model/RecurringAppointment';
import SaveSchedule from '../../../domain/model/SaveSchedule';
import Schedule from '../../../domain/model/Schedule';
import api from '../api';
import { getEstablishmentId } from './storageService';

export const getSchedules = () => {
  
  return api.get('/schedules', { params: {
    establishmentId: getEstablishmentId()
  } });
};

export const getByFilters = (filter: GetScheduleFilter) => {
  filter.establishmentId = getEstablishmentId();
  return api.get<Schedule[]>(`/schedules?`, { params: filter });
};

export const getRecurrences = (colaboradorId: Number) => {
  return api.get<RecurrenceBlock[]>(`/recurring-appointment/${colaboradorId}`);
};

export const deleteRecurrence = (recurrenceId: Number) => {
  return api.delete(`/recurring-appointment/${recurrenceId}`);
};


export const addSchedule = (data: SaveSchedule) => {
  data.establishmentId = getEstablishmentId();
  return api.post(`/schedules`, data);
};

export const deleteSchedule = (data: DeleteSchedule) => {
  data.establishmentId = getEstablishmentId();
  return api.delete(`/schedules`, {
    headers: {
      ...data,
    },
  });
};

export const createRecurringAppointment = (data: RecurringAppointment) => {
  return api.post(`/recurring-appointment`, data);
};
