import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import { default as DeleteEstablishmentImageInput, default as DeleteEstablishmentImageUseCase } from '../../@core/application/establishment/deleteEstablishmentImage';
import GetEstablishmentBySite from '../../@core/application/establishment/getEstablishmentBySite';
import UploadEstablishmentImage from '../../@core/application/establishment/uploadEstablishmentImage';
import Category from '../../@core/domain/model/Category';
import Colaborator from '../../@core/domain/model/Colaborator';
import Service from '../../@core/domain/model/Service';
import ImageGallery from '../../@core/domain/ui/ImageGallery';
import RegisterServiceForm from '../../@core/domain/ui/RegisterServiceForm';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import { GalleryImages } from '../../components/Gallery';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Container,
  GalleryImagesLoader,
  GalleryImagesLoaderContainer,
  Header,
  HeaderContainer,
  Instructiontext
} from './styles';
import FooterMenu from '../../components/Menus/FooterMenu';
import { DragGallery } from '../../components/DragGallery';
import Positions, { Position } from '../../@core/domain/model/Positions';
import OrganizeCarouselImages from '../../@core/application/establishment/organizeCarouselImages'
import OrganizeCarouselImagesInput from '../../@core/application/establishment/organizeCarouselImages';

const initialService: RegisterServiceForm = {
  name: '',
  description: '',
  collaboratorsOptions: [],
  price: 0,
  genre: '',
  duration: '',
  ageGroup: '',
  categoryId: 0,
  specializationService: false,
};
export default function CarouselHandle() {
  const { id } = useParams();

  const [serviceForm, setServiceForm] = useState<RegisterServiceForm>(initialService);
  const [service, setService] = useState<Service>();
  const [newImages, setNewImages] = useState<ImageGallery[]>([]);
  const [images, setImages] = useState<ImageGallery[]>([]);
  const [removedImages, setRemovedImages] = useState<ImageGallery[]>([]);
  const [establishmentId, setEstablishmentId] = useState<number>(Number(id));

  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [serviceLoading, setServiceLoading] = useState(true);
  const [categories, setCategories] = useState([] as Category[]);
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const navigate = useNavigate();


  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: serviceForm,
    onSubmit: async (values: RegisterServiceForm) => {

      console.log("service",service)
      await updateCarousel(newImages, removedImages);
    },
  });

  const findEstablishmentBySiteName = useCallback(
    async () => {
      const tenant = getTenantStorage();

      if(!checkUserAuthenticated()){
        navigate(`/${tenant}/login`)
        return;
      }

      const getUseCase = new GetEstablishmentBySite();
      getUseCase
        .execute(tenant ?? "")
        .then((response: any) => {
          const establishment = response.data;
          console.log("configurando valores iniciais ", establishment)
          
          const carouselImages = establishment.images.filter((img: any) => img.type == "SLIDE")

          setServiceLoading(false);
          setLoading(false);
          setImages(carouselImages);
        })
        .catch((err: any) => {
          setLoading(false);
          setServiceLoading(false);
          if(err?.response?.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [setService, setLoading]
  );


  useEffect(() => {
    findEstablishmentBySiteName();
  }, []);

  const uploadEstablishmentImages = useCallback(
    async (images: ImageGallery[]) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }
      
      if (images.length == 0) return;
      console.log(
        'Realizando upload de imagens. Imagens quantity: ',
        images.length
      );
      console.log('Realizando upload de imagens. Imagens: ', images);

      const uploadEstablishmentImage = new UploadEstablishmentImage();

      const formData = new FormData();
      formData.append('establishmentId', getEstablishmentId() ?? '');
      formData.append('imageType', 'SLIDE');

      images.forEach((img) => {
        formData.append('fileImages', img.file || '');
      });

      return await uploadEstablishmentImage
        .execute(formData)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          showAlert.error('Erro ao fazer upload da imagem');
          if(err.response.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    []
  );

  const removeEstablishmentImages = useCallback(
    async (images: ImageGallery[]) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      if (images.length == 0) return;

      const deleteEstablishmentImageUseCase = new DeleteEstablishmentImageUseCase();
      const establishmentId = getEstablishmentId();

      for (let i = 0; i < images.length; i++) {
        const imageId = images[i].id ?? 0;

        await deleteEstablishmentImageUseCase
          .execute({
            establishmentId: establishmentId,
            imageId: imageId,
          } as DeleteEstablishmentImageInput)
          .then((res) => {
            console.log('Imagem removida com sucesso: ', imageId);
          })
          .catch((err) => {
            console.log('Falha ao remover imagem: ', imageId);
            if(err.response?.status == 401){
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          });
      }
    },
    []
  );

  const updatePositions = useCallback(async (uploadedImages: any) => {

    console.log("imagesPositions", images)
    
    const positions = images.map((position: any, index) => {
        let id = 0;
        
        if(position.isNew){
          console.log("position nova", position)
          const uploadedImage = uploadedImages.filter((uploadedImage: any) => uploadedImage.imageValue === position.valueImage)[0]
          console.log("uploadedImage", uploadedImage)

          id = uploadedImage?.storageResult?.filesSuccessfully?.length > 0 ? uploadedImage.storageResult.filesSuccessfully[0].id : undefined;
          console.log("idGerado", id)
        } else {
          id = position.id; 
        }

        return {
          id: id,
          position: index
        } as Position
    });
    
    const organizeServiceImagesUseCase = new OrganizeCarouselImages();

    await organizeServiceImagesUseCase.execute({
      positions: {
        positions: positions
      } as Positions
    } as OrganizeCarouselImagesInput).then(res => {
    })
  }, [images])

  const updateCarousel = useCallback(
    async (
      newImages: ImageGallery[],
      removedImages: ImageGallery[]
    ) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      setServiceLoading(true)
      setLoading(true);
      await removeEstablishmentImages(removedImages);
      setRemovedImages([]);


      const uploadedImages = [] as any[];

      for (let i = 0; i < newImages.length; i++) {
        let newImageIndex = 0
        
        //Obter posição
        for (let j = 0; j < images.length; j++) {
          if(images[j].valueImage == newImages[i].valueImage){
            newImageIndex = j
          }
        }
        
        //Realizar upload da imagem
        const storageResult = await uploadEstablishmentImages([{
          ...newImages[i],
          position: newImageIndex
        }]);

        //Salvar resultado com ID da imagem
        uploadedImages.push({
          ...storageResult,
          imageValue: newImages[i].valueImage
        });
      }

      await updatePositions(uploadedImages)

      setNewImages([])

      await findEstablishmentBySiteName();

      showAlert.success('Carousel atualizado com sucesso');

      setServiceLoading(false);
      setLoading(false);
    },
    [images, navigate, removeEstablishmentImages, setLoading, updatePositions, uploadEstablishmentImages]
  );


  const addImageCallback = useCallback((newImageValue: string | ArrayBuffer | null, file: File) => {
    if(validateImage(file)){
      let newImg = { 
        id: Math.floor(Math.random() * 1000000), 
        valueImage: newImageValue, 
        isNew: true, file 
      } as ImageGallery;
      setNewImages(prevImages => [...prevImages, newImg]);
    }
  }, [setNewImages]);

  const validateImage = (image: File) => {
    const MAX_FILE_SIZE_MB = 8;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    console.log("tamanho imagem: ", image.size)
    console.log("MAX_FILE_SIZE_BYTES: ", MAX_FILE_SIZE_BYTES)

    if(image.size > MAX_FILE_SIZE_BYTES){
      console.log(`Imagem excede o tamanho máximo de ${MAX_FILE_SIZE_MB}mb.`)
      showAlert.error('Imagem excede o tamanho máximo de 8mb.');
      return false;
    }

    return true
  };

  const removeImageCallback = useCallback((image: ImageGallery) => {
    if (image.isNew) {
      setNewImages(prevImages => prevImages.filter(img => img.id !== image.id));
    }

    if (!image.isNew) {
      setImages(prevImages => prevImages.filter(img => img.id !== image.id));
      setRemovedImages((prevImages => [...prevImages, image]));
    }

  }, [setNewImages]);

  return (
    <Container>
      <HeaderContainer>
        <Header>Carousel</Header>
        <Instructiontext>Gerencie as imagens que irão aparecer no carousel da tela inicial do aplicativo</Instructiontext>
      </HeaderContainer>

      {serviceLoading ? (
        <GalleryImagesLoaderContainer>
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
          <GalleryImagesLoader />
        </GalleryImagesLoaderContainer>
      ) : (
        <DragGallery
          images={images}
          imagesPositionsCallback={setImages}
          addImageCallback={addImageCallback}
          removeImageCallback={removeImageCallback}
        />
      )}

      <Form>
        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Confirmar
        </Button>
      </Form>
      <FooterMenu />
    </Container>
  );
}
