import styled from 'styled-components';

export const ErrorImage = styled.img`
  width: 240px;
  height: 172px;
  align-self: center;
`;
export const ConnectionImage = styled.img`
  width: 144px;
  height: 172px;
  align-self: center;
`;
export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 80vh;
  padding: 1em;
`;
