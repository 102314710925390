import { AxiosResponse } from 'axios';
import RefreshToken from '../../domain/model/RefreshToken';
import * as oauthService from '../../infrastructure/api/services/oauthService';
import IUseCase from '../IUseCase';
import { getRefreshTokenStorage } from '../../infrastructure/api/services/storageService';


export default class RefreshTokenUseCase
  implements IUseCase<void, Promise<AxiosResponse<void>>>
{
	execute() {
		var refreshToken = getRefreshTokenStorage();
		
		var body = {
			refreshToken
		} as RefreshToken
		
    return oauthService.refreshToken(body); 
  }
}
