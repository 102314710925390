import Colaborator from '../../../domain/model/Colaborator';
import api from '../api';
import { getEstablishmentId } from './storageService';

export const doRegister = (colaborator: Colaborator) => {
  const establishmentId = getEstablishmentId();
  colaborator.establishmentId = Number(establishmentId) || 0;
  return api.post('/colaborators', colaborator, {});
};

export const doUpdate = (colaborator: Colaborator) => {
  const establishmentId = getEstablishmentId();
  colaborator.establishmentId = Number(establishmentId) || 0;
  return api.put(`/colaborators/${colaborator.id}`, colaborator, {});
};

export const getColaborators = () => {
  return api.get('/colaborators', {
    headers: {
      establishmentId: getEstablishmentId(),
    },
  });
};
export const uploadAvatar = (data: FormData) => {
  return api.post(`/storage/colaborator-profile-image`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteCoworker = (id: number) => {
  return api.delete(`/colaborators/${id}`);
};
