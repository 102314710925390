import beelezaLogoImage from '../assets/images/beeleza.jpeg';

export const normalizeBucketURI = (item: string) => {
  const URI_BUCKET = 'https://beeleza.s3.sa-east-1.amazonaws.com/';
  return URI_BUCKET + item;
};

export const normalizeBucketURIFallback = (type: string, item?: string) => {
  if(!item){
    if(type == "LOGO"){
      return beelezaLogoImage;
    }

    if(type == "FINAL_CLIENT"){
      console.log("entrou")
      return beelezaLogoImage;
    }

    if(type == "SERVICE"){
      return beelezaLogoImage;
    }

    if(type == "COWORK"){
      return beelezaLogoImage;
    }

    if(type == "COLABORATOR"){
      return beelezaLogoImage;
    }
  } else {
    const URI_BUCKET = 'https://beeleza.s3.sa-east-1.amazonaws.com/' + item;
    return URI_BUCKET;
  }
};
