import IUseCase from '../IUseCase';
import * as themeService from '../../infrastructure/api/services/themeService';
import { AxiosResponse } from 'axios';
import SaveThemeJson from '../../domain/model/SaveTheme';
import SaveTheme from '../../domain/model/SaveTheme';
import Theme from '../../domain/model/Theme';

export default class GetCustomization
  implements IUseCase<void, Promise<Theme>>
{
  async execute() {
    const response = await themeService.getCustomizations();
    const themeConfigs = JSON.parse(atob(response.data.styles)) as Theme;
    return themeConfigs;
  }
}
