import { Container, Loader } from './styles';
import beelezaLogo from '../../assets/images/beeleza.jpeg';
import { Box, CircularProgress } from '@mui/material';


function GradientCircularProgress({ size } : any) {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#5D17EB" />
            <stop offset="100%" stopColor="#5D17EB" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress size={size} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </>
  );
}

export default function Loading() {
  return (
    <>
      <Container />
      <Loader>
        <GradientCircularProgress size={70} />
      </Loader>
    </>
  );
}
