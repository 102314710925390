import {
  useCallback,
  useEffect,
  useState
} from 'react';

import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import { checkUserAuthenticated } from '../../components/PrivateRoute';

import {
  faCalendar,
  faComment,
  faLayerGroup,
  faMoneyBill,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import AddScheduleCase from '../../@core/application/schedule/addScheduleCase';
import Colaborator from '../../@core/domain/model/Colaborator';
import RegisterScheduleForm, {
  MapToSaveSchedule,
} from '../../@core/domain/ui/RegisterScheduleForm';
import {
  getEstablishment
} from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DateTimePickerBox from '../../components/DateTimePicker';
import { Form } from '../../components/Form/style';
import SelectBox from '../../components/Selectbox';
import Textbox from '../../components/TextboxMUI';
import ScheduleValidation from './scheduleValidation';


import { ProgressSpinner } from 'primereact/progressspinner';
import { FaArrowLeftLong } from 'react-icons/fa6';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import GetFinalClient from '../../@core/application/final-client/getFinalClients';
import GetServices from '../../@core/application/services/getServices';
import FinalClient from '../../@core/domain/model/FinalClient';
import Service from '../../@core/domain/model/Service';
import {
  ButtonContainer,
  Content,
  Header,
  HeaderTitle,
  IconContainer
} from './styles';

import { Option } from 'react-multi-select-component';
import MultipleSelect from '../../components/MultipleSelect';
import { FormControlLabel, Switch } from '@mui/material';
import FooterMenu from '../../components/Menus/FooterMenu';
import ScheduleWithFinalClientNotRegistedValidation from './scheduleWithFinalClientNotRegistedValidation';

export default function AddSchedule() {
  const [ isLoading, setLoading ] = useState(false);
  const showAlert = useAlert();
  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [finalClients, setFinalClients] = useState([] as FinalClient[]);
  const [isClientRegistered, setClientRegistered] = useState(true);
  const [services, setServices] = useState([] as Service[]);


  const navigate = useNavigate();

  const [registerScheduleForm, setRegisterScheduleForm] =
    useState<RegisterScheduleForm>({
      services: [],
      serviceId: '',
      date: null,
      observation: null,
      colaboratorId: '',
      scheduleType: 'WITH_COLABORATOR',
      paymentForm: 'LOCAL',
      establishmentId: parseInt(getEstablishment() as string),
      finalClientId: '',
      finalClientNotRegisteredName: '',
      finalClientNotRegisteredEmail: ''
    });

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: registerScheduleForm,
    validationSchema: isClientRegistered ? ScheduleValidation : ScheduleWithFinalClientNotRegistedValidation,
    onSubmit: (values: RegisterScheduleForm) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      setLoading(true)
      console.log("values", values)

      const saveSchedule = MapToSaveSchedule(values, services);

      if(isClientRegistered){
        saveSchedule.scheduleType ="WITH_COLABORATOR";
      } else {
        saveSchedule.scheduleType = "WITH_CLIENT_NOT_REGISTRED";
        saveSchedule.finalClientNotRegistered!.establishmentId = getEstablishmentId(); 
      }

      console.log("saveSchedule", saveSchedule)
      const addCase = new AddScheduleCase();
      addCase
        .execute(saveSchedule)
        .then(() => {
          setLoading(false);
          showAlert.success('Agendamento realizado com sucesso!');
          navigate(`/minha-agenda`)
        })
        .catch((err) => {
          setLoading(false);
          console.log('err', err);
          showAlert.error(err.response.data.erros[0]);
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
  });

  const findServices = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetServices();
    getUseCase.execute().then((response) => {
      setServices(response.data.items);
    }).catch(err => {
      if (err.response.status == 401) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
      };
    });
  }, []);

  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetColaborator();
    getUseCase.execute().then((response: any) => {
      setColaborators(response.data);
    }).catch((err: any) => {
      console.log("Algum erro ocorreu na obtenção dos colaboradores", err)
    });
  }, []);

  const findFinalClients = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getFinalClients = new GetFinalClient();
    getFinalClients
      .execute(undefined)
      .then((response: any) => {
        setFinalClients(response.data.items);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  useEffect(() => {
    findServices();
    findColaborators();
    findFinalClients();
  }, []);


  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <Content>
      <Header>
        <IconContainer>
          <FaArrowLeftLong onClick={() => {
            navigate(`/minha-agenda`)
          }}/>
        </IconContainer>
        <HeaderTitle>AGENDAR PARA CLIENTE</HeaderTitle>
      </Header>
      
      <Form>
      <FormControlLabel control={<Switch defaultChecked />} onChange={() => setClientRegistered(!isClientRegistered)} 
          label="Cliente cadastrado no app" />


        {isClientRegistered ?  (
          <SelectBox
          placeholder="Cliente*"
          name="finalClientId"
          value={form.values.finalClientId}
          setValue={form.handleChange}
          icon={faUser}
          error={form.errors.finalClientId}
          items={finalClients.map((finalClient) => {
            return { value: finalClient.id, label: finalClient.name };
          })}
        />
        ) : (
         <>
           <Textbox
            label="Nome do cliente*"
            placeholder="Nome do cliente não cadastrado*"
            name="finalClientNotRegisteredName"
            value={form.values.finalClientNotRegisteredName}
            setValue={form.handleChange}
            error={form.errors.finalClientNotRegisteredName}
          />

          <Textbox
            label="Email do cliente"
            placeholder="Email do cliente não cadastrado"
            name="finalClientNotRegisteredEmail"
            value={form.values.finalClientNotRegisteredEmail}
            setValue={form.handleChange}
            error={form.errors.finalClientNotRegisteredEmail}
          />
         </>
        )}

        <DateTimePickerBox
          label="Data do agendamento*"
          placeholder="Data do agendamento"
          name="date"
          type="datetime-local"
          icon={faCalendar}
          value={form.values.date}
          setValue={(newValue: string) => { form.setFieldValue("date", newValue) }}
          error={form.errors.date}
        />

        <SelectBox
          placeholder="Colaborador*"
          name="colaboratorId"
          value={form.values.colaboratorId}
          setValue={form.handleChange}
          icon={faLayerGroup}
          error={form.errors.colaboratorId}
          items={colaborators.map((colaborator) => {
            return { value: colaborator.id, label: colaborator.name };
          })}
        />     

        <MultipleSelect
          label="Escolha os serviços*"
          name="services"
          value={form.values.services}
          setValue={(options: Option[]) =>
            {
              console.log("options", options)
              form.setFieldValue(
                'services',
                options.map((option) => {
                  return option;
                })
              )
            }
          }
          options={services.map((service) => {
            return { value: service.id, label: service.name };
          })}
        />


        <SelectBox
          placeholder="Forma de pagamento*"
          name="paymentForm"
          value={form.values.paymentForm}
          setValue={form.handleChange}
          icon={faMoneyBill}
          items={[
            { value: "LOCAL", label: "Local" }
          ]}
        />

        <Textbox
          placeholder="Observação"
          name="observation"
          icon={faComment}
          value={form.values.observation}
          setValue={form.handleChange}
          error={form.errors.observation}
        />
        <ButtonContainer>
          <Button colorType="primary" onClick={() => !isLoading && form.submitForm()}>
            {isLoading ? <ProgressSpinner style={{width: '30px', height: '30px'}}/> : "Agendar"} 
          </Button>
        </ButtonContainer>
        
      </Form>
      <FooterMenu />

    </Content>
  );
}
