import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import RegisterUseCase from '../../@core/application/auth/registerUseCase';
import { setTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import dictionary from '../../config/dictionary';
import {
  Content,
  Divider,
  Form,
  Header,
  HeaderContainer,
  IconContainer,
  PreviewLabel,
  PreviewLinkBaseText,
  PreviewLinkContainer,
  PreviewLinkContent,
  PreviewLinkOtherText
} from './styles';

import { Check } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';
import { FaRegCopy } from "react-icons/fa6";
import { FiMapPin } from "react-icons/fi";
import { IoStorefrontOutline } from "react-icons/io5";
import { TbUserPlus } from "react-icons/tb";
import styled from 'styled-components';
import CreateAddressUseCase from '../../@core/application/address/createAddress';
import GetAllCitiesUseCase from '../../@core/application/address/getCities';
import GetStatesUseCase from '../../@core/application/address/getStates';
import City from '../../@core/domain/model/City';
import CreateAddress from '../../@core/domain/model/CreateAddress';
import State from '../../@core/domain/model/State';
import RegisterEstablishmentForm, { MapToAddress, MapToRegister } from '../../@core/domain/ui/RegisterEstablishmentForm';
import SelectBox from '../../components/Selectbox';
import Textbox from '../../components/TextboxMUI';
import TimePickerBox from '../../components/TimePickerBox';
import validationStep1 from './validationStep1';
import validationStep2 from './validationStep2';
import validationStep3 from './validationStep3';
import validationStep4 from './validationStep4';


export default function RegisterPage() {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);


  const [stepFormValidation, setStepFormValidation] = useState<any>(validationStep1);
  const [allCompleted, setAllCompleted] = useState(false);
  const [changeStepAux, setChangeStepAux] = useState(-1);


  const initialValues: RegisterEstablishmentForm = {
    nameSite: '',
    establishmentType: null,
    description: '',
    socialName: '',
    fantasyName: '',
    name: '',
    telephone: '',
    email: '',
    cnpj: null,
    matriz: '',
    facebookURL: '',
    gmailURL: '',
    instagramURL: '',
    whatsappURL: '',
    street: '',
    neighborhood: '',
    number: 0,
    complements: '',
    cep: '',
    addressType: '',
    city: null,
    state: null,
    ownerName: '',
    ownerEmail: '',
    ownerPassword: '',
    ownerDescription: '',
    ownerImagePerfil: '',
    ownerStartLunchTime: '',
    ownerEndLunchTime: '',
    ownerStartWork: null,
    ownerEndWork: null,
    ownerAge: '',
    ownerFacebookURL: '',
    ownerGmailURL: '',
    ownerInstagramURL: '',
    ownerWhatsappURL: '',
    ownerPasswordRepeat: '',
  };

  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: stepFormValidation,
    onSubmit: (value: RegisterEstablishmentForm) => {
      console.log("activeStep", activeStep)

      if (activeStep === 0) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
        if (!emailRegex.test(value.gmailURL ?? "")) {
          registerForm.setFieldError("gmailURL", "Email no formato incorreto. Exemplo correto: beeleza@gmail.com");
          return;
        }

        if(value.cnpj && value.cnpj !== ""){
          if (!cnpjRegex.test(value.cnpj)) {
            registerForm.setFieldError("cnpj", "CNPJ no formato incorreto");
            return;
          }
        }
      }

      if (activeStep === 2) {

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[A-Z]).{6,50}$/;

        if (!emailRegex.test(value.ownerEmail)) {
          registerForm.setFieldError("ownerEmail", "Email no formato incorreto. Exemplo correto: beeleza@gmail.com");
          return;
        }

        if (!passwordRegex.test(value.ownerPassword)) {
          registerForm.setFieldError("ownerPassword", "Senha fraca demais. A senha deve conter pelo menos uma letra (maiúscula ou minúscula). Deve conter pelo menos um dígito numérico. Deve conter pelo menos uma letra maiúscula. Deve ter entre 6 e 50 caracteres de comprimento.");
          return;
        }

        if (value.ownerPassword !== value.ownerPasswordRepeat) {
          registerForm.setFieldError("ownerPasswordRepeat", "Senha está diferente")
          return;
        }
      }

      let newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted)

      if (changeStepAux != -1) {
        console.log("Simples mudança de step com verificação")
        setActiveStep(changeStepAux);
        setChangeStepAux(-1)
        return;
      }

      handleNext();
      if (allCompleted || isLastStep()) {
        doRegister(value);
      }
    },
  });

  
  const doRegister = useCallback((value: RegisterEstablishmentForm) => {
    let register = MapToRegister(value);
    register.nameSite = normalizeLink(register.nameSite) ?? ""

    register = removeAllBlankFieldsToNull(register)
    register = removeAllSpecialCharacters(register)

    const registerUseCase = new RegisterUseCase();
    registerUseCase
      .execute(register)
      .then((res) => {
        setTenantStorage(register.nameSite)
        console.log("res.data",  res.data)
        associateAddress(MapToAddress(value), res.data.id);
      })
      .catch((err) => {
        if (err.response.status == 409) {
          if (err.response.data[0] == "Nome do site já se encontra cadastrado") {
            showAlert.error('Link do aplicativo já existe. Altere para um diferente');
            setActiveStep(4)
          } else {
            showAlert.error('Algum erro ocorreu. Tente novamente');
          }
        } else {
          showAlert.error('Algum erro ocorreu. Tente novamente');
        }
      });
  }, []);

  const associateAddress = (address: CreateAddress, establishmentId: number) => {
    const createAddressUseCase = new CreateAddressUseCase()

    const input = {
      ...address,
      establishmentId: establishmentId
    } as CreateAddress;

    createAddressUseCase.execute(input)
      .then(res => {
        navigate(`/novo-estabelecimento`);
      }).catch(err => {
        showAlert.error('Algum erro ocorreu no cadastro do endereço. Tente novamente');
      })
  }

  const removeAllBlankFieldsToNull = (jsonObject: any): any => {
      // Percorra todas as propriedades do objeto
      for (const key in jsonObject) {
          if (jsonObject.hasOwnProperty(key)) {
              // Verifique se o valor da propriedade é uma string vazia
              if (jsonObject[key] === "") {
                  // Substitua o valor vazio por null
                  jsonObject[key] = null;
              }
          }
      }

      return jsonObject
  };

  const removeAllSpecialCharacters = (jsonObject: any) => {
    // Percorra todas as propriedades do objeto
    for (const key in jsonObject) {
        if(key == "telephone" || key == "cnpj")
        if (jsonObject.hasOwnProperty(key)) {
            // Verifique se o valor da propriedade é uma string
            if (typeof jsonObject[key] === 'string') {
                // Remova todos os caracteres especiais usando a expressão regular corrigida
                jsonObject[key] = jsonObject[key]?.replace(/[./,:()\-\s]/g, '');
            }
        }
    }

    return jsonObject;
  };


  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const steps = ['Negócio', 'Endereço', 'Proprietário(a)', 'Aplicativo'];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {

    if(isLastStep()){
      setActiveStep(activeStep)
    } else {
      setActiveStep(activeStep + 1);
    }
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    registerForm.submitForm();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette?.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette?.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(95deg, #D5A3FF 0%, #5D17EB 50%, #2B0075 100%)',
        transition: 'background-image 2s ease', // Adicionando a transição
        transitionDelay: "2"
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(95deg, #D5A3FF 0%, #5D17EB 50%, #2B0075 100%)',
        transition: 'background-image 2s ease', // Adicionando a transição,
        transitionDelay: "2"
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette?.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette?.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '0%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(136deg, #5D17EB 0%, #5D17EB 50%, #5D17EB 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(136deg, #5D17EB 0%, #5D17EB 50%, #5D17EB 100%)',
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <IconContainer><IoStorefrontOutline /></IconContainer>,
      2: <IconContainer><FiMapPin /></IconContainer>,
      3: <IconContainer><TbUserPlus /></IconContainer>,
      4: <IconContainer><FiMapPin /></IconContainer>,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const getAllStates = () => {
    const getStatesUseCase = new GetStatesUseCase();

    getStatesUseCase.execute().then(res => {
      setStates(res.data)
    }).catch(err => {
      showAlert.error('Algum erro ocorreu reinicie o aplicativo por favor.');
    })
  }

  const getCitiesByState = (stateId: number) => {
    const getAllCitiesUseCase = new GetAllCitiesUseCase();

    getAllCitiesUseCase.execute({ stateId: stateId }).then((res: any) => {
      setCities(res.data)
    }).catch(err => {
      showAlert.error('Algum erro ocorreu reinicie o aplicativo por favor.');
    })
  }


  React.useEffect(() => {
    console.log("registerForm.values", registerForm.values)
    let allCompleted = true;
    for (let i = 0; i < steps.length; i++) {
      if (!completed[i]) {
        allCompleted = false
      }
    }

    setAllCompleted(allCompleted)
    console.log("completed", completed)
    console.log("allCompleted", allCompleted)
  }, [registerForm.values])


  React.useEffect(() => {
    if (activeStep === 0) {
      console.log("ativando validação step 1")
      setStepFormValidation(validationStep1)
    }

    if (activeStep === 1) {
      console.log("ativando validação step 2")
      setStepFormValidation(validationStep2)
    }


    if (activeStep === 2) {
      console.log("ativando validação step 3")
      setStepFormValidation(validationStep3)
    }

    if (activeStep === 3) {
      console.log("ativando validação step 4")
      setStepFormValidation(validationStep4)
    }
  }, [activeStep])

  React.useEffect(() => {
    getAllStates();
  }, [])

  React.useEffect(() => {
    getCitiesByState(registerForm.values.state ?? 0);
  }, [registerForm.values.state])

  const normalizeLink = (link?: string) => {
    if (!link) return
    // Substituir espaços por hífens
    let normalizedLink = link.replace(/\s+/g, '-');

    // Converter para minúsculas
    normalizedLink = normalizedLink.toLowerCase();

    return normalizedLink;
  };

  const copyToClipboard = (basePath: string) => {
    navigator.clipboard.writeText(basePath + registerForm.values.nameSite);
    setCopied(true);
    showAlert.success("Link copiado")
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  return (
    <Content>

      <HeaderContainer>
        <Header>{dictionary.register.welcomeTitle}</Header>
      </HeaderContainer>

      <Box sx={{ width: '100%' }}>
        <div style={{ overflowX: 'auto' }}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label, index: number) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel onClick={() => {

                  const isNextStep = (index - activeStep) === 1;
                  const isPreviousStep = index < activeStep

                  if(isNextStep || isPreviousStep){
                    setChangeStepAux(index)
                    registerForm.submitForm()
                  }
                }} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <React.Fragment>
            {activeStep == 0 ? (
              <Form>
                <Textbox
                  label="Nome *"
                  placeholder="Nome do estabelecimento"
                  name="name"
                  value={registerForm.values.name}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.name}
                />

                <Textbox
                  label="Telefone *"
                  placeholder="Telefone do estabelecimento"
                  name="telephone"
                  mask="PHONE"
                  value={registerForm.values.telephone}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.telephone}
                />

                <Textbox
                  label="Email para contato *"
                  placeholder="exemplo@gmail.com"
                  name="gmailURL"
                  value={registerForm.values.gmailURL}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.gmailURL}
                />

                <SelectBox
                  label="Categoria *"
                  placeholder="Categoria do estabelecimento"
                  name="establishmentType"
                  value={registerForm.values.establishmentType}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.establishmentType}
                  items={[
                    {
                      value: "BARBERSHOP",
                      label: "Barbearia"
                    },
                    {
                      value: "BEAUTY_SALON",
                      label: "Salão de Beleza"
                    },
                    {
                      value: "BEAUTY_CLINIC",
                      label: "Clínica de Estética"
                    },
                    {
                      value: "OTHER",
                      label: "Outros"
                    }
                  ]}
                />

                <Textbox
                  label="CNPJ"
                  placeholder="CNPJ do estabelecimento"
                  name="cnpj"
                  mask="CNPJ"
                  value={registerForm.values.cnpj}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.cnpj}
                />

                <Divider />

                <TimePickerBox
                  label="Inicio horário de expediente *"
                  placeholder="Inicio horário de expediente"
                  name="startWork"
                  showLabel={true}
                  type="time"
                  value={registerForm.values.startWork}
                  setValue={(newValue: string) => { registerForm.setFieldValue("startWork", newValue) }}
                  error={registerForm.errors.startWork}
                />

                <TimePickerBox
                  label="Fim horário de expediente *"
                  placeholder="Fim horário de expediente"
                  name="endWork"
                  showLabel={true}
                  type="time"
                  value={registerForm.values.endWork}
                  setValue={(newValue: string) => { registerForm.setFieldValue("endWork", newValue) }}
                  error={registerForm.errors.endWork}
                />

                <Divider />


                <Textbox
                  label="Link instagram"
                  placeholder="https://www.instagram.com/beeleza-app/"
                  name="instagramURL"
                  value={registerForm.values.instagramURL}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.instagramURL}
                />

                <Textbox
                  label="Link facebook"
                  placeholder="https://www.facebook.com/beeleza-app"
                  name="facebookURL"
                  value={registerForm.values.facebookURL}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.facebookURL}
                />

                <Textbox
                  label="Whatsapp número"
                  placeholder="19982902475"
                  name="whatsappURL"
                  mask="PHONE"
                  value={registerForm.values.whatsappURL}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.whatsappURL}
                />

              </Form>
            ) : (<></>)}

            {activeStep == 1 ? (
              <Form>
                <Textbox
                  label="CEP *"
                  placeholder="CEP do estabelecimento"
                  name="cep"
                  value={registerForm.values.cep}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.cep}
                />

                <Textbox
                  label="Logradouro *"
                  placeholder="Nome da rua ou avenida"
                  name="street"
                  value={registerForm.values.street}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.street}
                />

                <Textbox
                  label="Número *"
                  placeholder="Número, identificação do estabelecimento"
                  name="number"
                  type="number"
                  value={registerForm.values.number}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.number}
                />

                <Textbox
                  label="Bairro *"
                  placeholder="Bairro do estabelecimento"
                  name="neighborhood"
                  value={registerForm.values.neighborhood}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.neighborhood}
                />

                <SelectBox
                  label="Estado *"
                  placeholder="Escolha o estado"
                  name="state"
                  value={registerForm.values.state}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.state}
                  items={states.map((state) => {
                    return { value: state.id, label: state.UF };
                  })}
                />

                <SelectBox
                  label="Cidade *"
                  placeholder="Escolha a cidade"
                  name="city"
                  value={registerForm.values.city}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.city}
                  items={cities.map((city) => {
                    return { value: city.id, label: city.name };
                  })}
                />
              </Form>
            ) : (<></>)}

            {activeStep == 2 ? (
              <Form>
                <Textbox
                  placeholder="Nome do propriétario(a) *"
                  name="ownerName"
                  value={registerForm.values.ownerName}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.ownerName}
                />

                <Textbox
                  label="Email *"
                  placeholder="exemplo@gmail.com.br"
                  name="ownerEmail"
                  value={registerForm.values.ownerEmail}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.ownerEmail}
                />

                <Textbox
                  label="Senha *"
                  placeholder="**********"
                  type="password"
                  name="ownerPassword"
                  value={registerForm.values.ownerPassword}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.ownerPassword}
                />

                <Textbox
                  label="Repita a senha *"
                  placeholder="**********"
                  type="password"
                  name="ownerPasswordRepeat"
                  value={registerForm.values.ownerPasswordRepeat}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.ownerPasswordRepeat}
                />

                <Textbox
                  placeholder="Idade *"
                  name="ownerAge"
                  type="number"
                  value={registerForm.values.ownerAge}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.ownerAge}
                />

                <Divider />

                <TimePickerBox
                  label="Inicio horário de expediente *"
                  placeholder="Inicio horário de expediente do propietário"
                  name="ownerStartWork"
                  showLabel={true}
                  type="time"
                  value={registerForm.values.ownerStartWork ?? undefined}
                  setValue={(newValue: string) => { registerForm.setFieldValue("ownerStartWork", newValue) }}
                  error={registerForm.errors.ownerStartWork}
                />

                <TimePickerBox
                  label="Fim horário de expediente *"
                  placeholder="Fim horário de expediente do propietário"
                  name="ownerEndWork"
                  showLabel={true}
                  type="time"
                  value={registerForm.values.ownerEndWork ?? undefined}
                  setValue={(newValue: string) => { registerForm.setFieldValue("ownerEndWork", newValue) }}
                  error={registerForm.errors.ownerEndWork}
                />


                <Divider />

                <TimePickerBox
                  label="Inicio horário de almoço *"
                  placeholder="Inicio horário de almoço"
                  showLabel={true}
                  name="ownerStartLunchTime"
                  type="time"
                  value={registerForm.values.ownerStartLunchTime}
                  setValue={(newValue: string) => { registerForm.setFieldValue("ownerStartLunchTime", newValue) }}
                  error={registerForm.errors.ownerStartLunchTime}
                />

                <TimePickerBox
                  label="Fim horário de almoço *"
                  placeholder="Fim horário de almoço"
                  showLabel={true}
                  name="ownerEndLunchTime"
                  type="time"
                  value={registerForm.values.ownerEndLunchTime}
                  setValue={(newValue: string) => { registerForm.setFieldValue("ownerEndLunchTime", newValue) }}
                  error={registerForm.errors.ownerEndLunchTime}
                />
              </Form>
            ) : (<></>)}

            {activeStep == 3 ? (
              <Form>
                <Textbox
                  placeholder="Link do aplicativo"
                  name="nameSite"
                  value={registerForm.values.nameSite}
                  setValue={registerForm.handleChange}
                  error={registerForm.errors.nameSite}
                />


                <PreviewLinkContainer>
                  <PreviewLabel>Link para acessar seu aplicativo:</PreviewLabel>
                  <PreviewLinkContent>
                    <PreviewLinkBaseText>www.beeleza.shop/</PreviewLinkBaseText>
                    <PreviewLinkOtherText>
                      {normalizeLink(registerForm.values.nameSite)}
                    </PreviewLinkOtherText>
                    <IconContainer onClick={() => copyToClipboard("www.beeleza.shop/")}>
                      <FaRegCopy />
                    </IconContainer>
                  </PreviewLinkContent>
                </PreviewLinkContainer>

                <PreviewLinkContainer>
                  <PreviewLabel>Link para acessar o gerenciador:</PreviewLabel>
                  <PreviewLinkContent>
                    <PreviewLinkBaseText>www.beeleza.shop/</PreviewLinkBaseText>
                    <PreviewLinkOtherText>
                      {normalizeLink(registerForm.values.nameSite)}
                    </PreviewLinkOtherText>
                    <IconContainer onClick={() => copyToClipboard("www.beeleza.shop/")}>
                      <FaRegCopy />
                    </IconContainer>
                  </PreviewLinkContent>
                </PreviewLinkContainer>

              </Form>
            ) : (<></>)}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, padding: '1em' }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Voltar
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleComplete} sx={{ mr: 1 }}>
                {allCompleted || isLastStep()
                  ? 'Finalizar'
                  : 'Próximo'}
              </Button>
            </Box>
          </React.Fragment>
        </div>
      </Box>


    </Content>
  );
}
