import styled, { keyframes } from "styled-components";

interface CheckboxProps {
  checked?: boolean;
}


export const Container = styled.div`
	width: 100%
	height: 100%;
`;

export const GalleryList = styled.div`
	display: flex;
  flex-wrap: wrap;
  align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
 
  /* Define a largura máxima dos itens para que haja no máximo 3 itens por linha */
  & > * {
    flex: 0 0 calc(33.33% - 1em); /* 33.33% menos o espaço entre os itens */
    max-width: calc(33.33% - 1em); /* Largura máxima de cada item */
    margin: 0.5em; /* Espaçamento entre os itens */
  }
`;


export const Title = styled.span`
	font-family: ${props => props.theme.font.rufina};
	color: ${props => props.theme.color.white};
	letter-spacing: 1px;
	font-size: .8em;
`;

export const Name = styled.span`
	color: ${props => props.theme.color.orange};
`;


const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Empty = styled.div`
  width: 100%;
  padding: 8px; /* Ajuste o valor conforme necessário */
  border-radius: 2em;
  text-align: center; /* Alinha o texto ao centro */
  color: #666; /* Cor do texto cinza */
  animation: ${slideIn} 0.5s ease-in-out; /* Adiciona a animação de deslizamento */
`;

export const Image = styled.img<CheckboxProps>`
	width: 28vw;
	height: 8em;
  object-fit: cover;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const LoaderCard = styled.div`
  border-radius: 20px;
  width: calc(33.33% - 2%);
	margin-bottom: 2%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  background-size: 200% 100%;
  animation: ${shineAnimation} 1.5s linear infinite;
  height: 8em;
`;

export const ContainerImage = styled.div`
  position: relative;
  justify-content: center;
  cursor: grab;
`;

export const TrashContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: -2px; /* Ajuste conforme necessário */
`

export const TrashIcon = styled.div`
  background-color: white;
  border: none;
  font-size: 1.4em;
  transition: 0.2s all ease-in-out;
  border-radius: 50%; /* torna o botão redondo */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
`

export const CoverContainer = styled.div`
  position: absolute;
  bottom: 10px; /* Altere conforme necessário para ajustar a altura */
  right: 50%;
  transform: translateX(50%);
  margin: -2px; /* Ajuste conforme necessário */
  background-color: rgb(93, 23, 235, 0.7);
  font-size: 0.8em;
  font-weight: bold; /* Define a fonte como negrito */
  transition: 0.2s all ease-in-out;
  border-radius: 0.8em; /* Torna o botão redondo */
  width: 4em;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255, 0.8);
  box-shadow: 0px 2px 4px rgb(93, 23, 235, 0.4), 0px 0px 10px rgb(93, 23, 235, 0.7); /* Adiciona o efeito de desfoque preto */

`;

export const ContainerAddImage = styled.label`
  display: flex;
  background-color: #e0e0e0; /* Fundo cinza claro */
  width: calc(33.33% - 2%);
  margin-bottom: 2%;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  gap: 0.5em;
  height: 8em;
  cursor: pointer; /* Altera o cursor ao passar o mouse */

  /* Estilo para quando o elemento está ativo (clicado) */
  :active {
    transform: translateY(2px); /* Efeito de pressionamento */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ao pressionar */
  }

`;

export const AddIconContainer = styled.div`
  font-size: 2.5em;
  font-weight: bold; /* Torna o ícone mais grosso */
  color: white; /* Define a cor do ícone como branca */
`;

export const TextAddImage = styled.div`
  text-align: center; /* Centraliza o texto horizontalmente */
  color: #b0b0b0; /* Cor cinza mais clara */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Altere para space-between ou space-around */
  background-color: white;
  border: ${(props) => props.theme.color.borderUnfocused};
  height: 3.5em;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  padding-left: 1em;
  padding-right: 0.5em;
  gap: 1em;
  &:focus {
    border-color: ${(props) => props.theme.color.primary};
  }
`;

export const IconContainer = styled.div`
  color: ${(props) => props.theme.color.inputEndIconColor};
  font-size: 1.5em;
`;
