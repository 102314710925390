import { DatePicker, DateTimePicker, deDE, LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ChangeEvent, useCallback } from 'react';
import {
  Container,
  ErrorMessage,
  StyledDatePicker
} from './styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import dayjs, { Dayjs } from 'dayjs'
import { formatDate, formatDateWithSeconds } from '../../utils/HoursUtils';
import { DateTime } from 'luxon';

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  setValue: (e: any) => void;
  name?: string;
  showLabel?: boolean;
  icon?: any;
  iconEnd?: any;
  onClickEnd?: () => void;
  onClick?: () => void;
}

export default function DateTimePickerBox({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  icon,
  iconEnd,
  onClick,
  onClickEnd,
  showLabel
}: TextboxParams) {


  const handleChange = (event: any) => {
    console.log("handleChange", formatDateWithSeconds(event))
    setValue(formatDateWithSeconds(event));
  };


  return (
    <Container id={`${id}-container`}>
      <LocalizationProvider  dateAdapter={AdapterLuxon} adapterLocale={'pt-BR'} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DateTimePicker
            label={label}
            onChange={(newValue) => handleChange(newValue)}
            value={value ? DateTime.fromISO(value) : null}
            disabled={disabled}
            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
            sx={{
              width: '100%',
              color: 'grey',
              textDecorationColor: 'grey',
              backgroundColor: 'white',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Adiciona uma sombra
              borderRadius: '0px', // Borda arredondada
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda do TextField
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda ao passar o mouse sobre o TextField
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda quando o TextField está focado
              }
            }}
          />
        </LocalizationProvider>
        {error && (
          <ErrorMessage showPassword={isPassword}>{error}</ErrorMessage>
        )}
    </Container>
  );
}
