import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import { AxiosResponse } from 'axios';
import Schedule from '../../domain/model/Schedule';
import GetScheduleFilter from '../../domain/filter/GetScheduleFilter';

export default class GetSchedulesByFilterCase
  implements IUseCase<GetScheduleFilter, Promise<AxiosResponse<Schedule[]>>>
{
  execute(filter: GetScheduleFilter) {
    return scheduleService.getByFilters(filter);
  }
}
