import { AxiosResponse } from 'axios';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import IUseCase from '../IUseCase';

export default class DeleteRecurrenceUseCase
  implements IUseCase<number, Promise<AxiosResponse>>
{
  execute(id: number) {
    return scheduleService.deleteRecurrence(id);
  }
}
