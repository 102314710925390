import {
  faCalendar,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import GetCategories from '../../@core/application/category/getCategories';
import {
  default as GetFinalClient,
  default as SearchFinalClientInput,
} from '../../@core/application/final-client/getFinalClients';
import CreateNotificationUseCase from '../../@core/application/notifications/createNotificationCase';
import Category from '../../@core/domain/model/Category';
import FinalClient from '../../@core/domain/model/FinalClient';
import SendNotificationForm, { MapToNotification } from '../../@core/domain/ui/SendNotificationForm';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DateTimePickerBox from '../../components/DateTimePicker';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import SelectBox from '../../components/Selectbox';
import SelectBoxSearch from '../../components/SelectboxSearch';
import Textarea from '../../components/Textarea';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import { formatDateWithSeconds } from '../../utils/HoursUtils';
import {
  Container,
  Header,
  Form,
  HeaderContainer,
  Instructiontext
} from './styles';
import validationNotificationSend from './validation';
import FooterMenu from '../../components/Menus/FooterMenu';

const initialCreateNotificationForm: SendNotificationForm = {
  title: '',
  content: '',
  type: '',
  target: '',
  idFinalClient: null,
  categoryId: 0,
  initialDate: '',
  establishmentId: 0,
  saveCache: false
};

export default function NotificationSend() {
  const { id } = useParams();

  const [notificationForm, setCreateNotificationForm] = useState<SendNotificationForm>(initialCreateNotificationForm);
  const [customers, setCustomers] = useState<FinalClient[]>([]);
  const [categories, setCategories] = useState([] as Category[]);
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const navigate = useNavigate();


  const sendNotificationForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: validationNotificationSend,
    initialValues: notificationForm,
    onSubmit: async (values: SendNotificationForm) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const notification = MapToNotification(values);
      notification.saveCache = true;
      notification.type = "OFFER";

      if (notification.target == "ALL") {
        notification.categoryId = null
        notification.idFinalClient = null
      }

      if (notification.target == "SPECIFIC") {
        notification.categoryId = null
        if (!notification.idFinalClient) {
          sendNotificationForm.errors.idFinalClient = "Campo Obrigatório"
          return;
        }

      }

      if (notification.target == "GROUP_BY_CATEGORY") {
        notification.idFinalClient = null
        if (!notification.categoryId) {
          sendNotificationForm.errors.categoryId = "Campo Obrigatório"
          return;
        }
      }

      notification.initialDate = formatDateWithSeconds(notification.initialDate)
      notification.idFinalClient = customers.filter(customer => customer.name == String(notification.idFinalClient))[0]?.id

      const createNotificationUseCase = new CreateNotificationUseCase();
      setLoading(true);

      console.log("notification", notification)

      await createNotificationUseCase.execute(notification)
        .then(() => {
          showAlert.success("Notificação agendada com sucesso");
          sendNotificationForm.resetForm()
          setLoading(false)
        })
        .catch(err => {
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
            return;
          };
          console.log(err)

          if (err.response.status == 404) {
            err.response.data.erros.forEach((err: string) => {
              showAlert.error(err);
            })
          }

          setLoading(false)
        })
    },
  });

  const findCategories = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getCategoriesUseCase = new GetCategories();

    getCategoriesUseCase
      .execute()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        console.log('Erro ao requisitar categorias', err);
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  const findCustomers = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getAllFinalClientUseCase = new GetFinalClient();
    getAllFinalClientUseCase
      .execute({
        terms: undefined,
      } as SearchFinalClientInput)
      .then((res: any) => {
        setCustomers(res.data.items);
      }).catch(err => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);


  useEffect(() => {
    findCategories();
    findCustomers();
  }, []);


  useEffect(() => {
    console.log("sendNotificationForm.values.target", sendNotificationForm.values.target)
  }, [sendNotificationForm.values.target])

  return (
    <>
      <HeaderContainer>
        <Header>Notificações</Header>
        <Instructiontext>Notifique seus clientes sobre promoções, ofertas, avisos o que você desejar :)</Instructiontext>
      </HeaderContainer>
      <Container>
        <Form>
          <Textbox
            placeholder="Titulo da notificação"
            name="title"
            type="text"
            icon={faLayerGroup}
            value={sendNotificationForm.values.title}
            setValue={sendNotificationForm.handleChange}
            error={sendNotificationForm.errors.title}
          />


          <DateTimePickerBox
            label="Data da notificação"
            placeholder="Data da notificação"
            name="initialDate"
            type="datetime-local"
            icon={faCalendar}
            value={sendNotificationForm.values.initialDate}
            setValue={(newValue: string) => { sendNotificationForm.setFieldValue("initialDate", newValue) }}
            error={sendNotificationForm.errors.initialDate}
          />

          <SelectBox
            placeholder="Tipo da notificação"
            name="target"
            value={sendNotificationForm.values.target}
            setValue={sendNotificationForm.handleChange}
            icon={faLayerGroup}
            error={sendNotificationForm.errors.target}
            items={[
              {
                value: "ALL",
                label: "Para todos usuários"
              },
              {
                value: "SPECIFIC",
                label: "Para um cliente específico"
              },
              {
                value: "GROUP_BY_CATEGORY",
                label: "Para uma categoria de serviço"
              }
            ]}
          >
          </SelectBox>


          {sendNotificationForm.values.target == "GROUP_BY_CATEGORY" ? (
            <SelectBox
              placeholder="Categoria do serviço"
              name="categoryId"
              value={sendNotificationForm.values.categoryId}
              setValue={sendNotificationForm.handleChange}
              icon={faLayerGroup}
              error={sendNotificationForm.errors.categoryId}
              items={categories.map((category) => {
                return { value: category.id, label: category.category };
              })}
            />
          ) : (<></>)}

          {sendNotificationForm.values.target == "SPECIFIC" ? (
            <SelectBoxSearch

              label="Cliente"
              placeholder="Escolha o cliente"
              name="idFinalClient"
              value={sendNotificationForm.values.idFinalClient}
              setValue={(e: string) => {
                console.log("configurando valor ", e)
                sendNotificationForm.setFieldValue("idFinalClient", customers?.filter(c => c.id.toString() == e)[0]?.name)
              }}
              // icon={faLayerGroup}
              error={sendNotificationForm.errors.idFinalClient}
              items={customers.map((customer) => {
                return { value: customer.id, label: customer.name };
              })}
            />
          ) : (<></>)}

          <Textarea
            placeholder="Conteúdo da notificação"
            name="content"
            initialSize={8}
            value={sendNotificationForm.values.content}
            setValue={sendNotificationForm.handleChange}
            error={sendNotificationForm.errors.content}
            limit={200}
          // icon={faFileText}
          />

          <Button
            onClick={() => {
              sendNotificationForm.submitForm();
            }}
            colorType="primary"
          >
            Enviar notificação
          </Button>
        </Form>
        <FooterMenu />
      </Container>
    </>
  );
}
