import { AxiosResponse } from 'axios';
import * as confirmationAccountService from '../../infrastructure/api/services/confirmationAccountService';
import IUseCase from '../IUseCase';

export default class ConfirmationColaboratorAccountUseCase
  implements IUseCase<number, Promise<AxiosResponse<void>>>
{
  execute(id: number) {
    return confirmationAccountService.confirmationColaboratorAccount(id);
  }
}
