import NoConnectionImage from '../../assets/images/noConmection.png';
import Button from '../../components/Button';
import {
  ModuleText,
  ModuleTitle,
} from '../../components/DashboardWrapper/style';
import { ConnectionImage, ErrorWrapper } from './styles';

export default function NoConnection() {
  return (
    <ErrorWrapper>
      <ModuleTitle>Sem Conexão</ModuleTitle>
      <ModuleText>
        Não há conexão com a internet, sugerimos verificar a conectividade
        WIFI ou ativar os dados móveis.
      </ModuleText>

      <ConnectionImage src={NoConnectionImage} />

      <Button colorType="primary">Atualizar</Button>
    </ErrorWrapper>
  );
}
