import { AxiosResponse } from 'axios';
import SaveSchedule from '../../domain/model/SaveSchedule';
import IUseCase from '../IUseCase';
import Establishment from '../../domain/model/Establishment';
import * as addressService from '../../infrastructure/api/services/addressService';
import Address from '../../domain/model/Address';

export default class UpdateAddressUseCase
  implements IUseCase<Address, Promise<AxiosResponse>>
{
  execute(address: Address) {
    return addressService.updateAddress(address.establishmentId ?? 0, address.id ?? 0, address);
  }
}
