import React from 'react';
import { BackgroundImage, Container } from './styles';

export default function Background({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Container>
      <BackgroundImage />
      {children}
    </Container>
  );
}
