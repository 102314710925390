import { getEstablishmentId } from '../../infrastructure/api/services/storageService';
import RegisterCategory from '../model/RegisterCategory';

export default interface CategoryForm {
  category?: string ;
}
export const MapToRegister = (form: CategoryForm): RegisterCategory => {
  return {
    category: form.category ?? '',
    establishmentId: getEstablishmentId() as string,
  };
};
