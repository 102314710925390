import styled, { keyframes } from 'styled-components';
import background from '../../assets/images/default_back.png';
import CustomizeLayoutForm from '../../@core/domain/ui/CustomizeLayoutForm';

export const BarberName = styled.h2`
  color: ${(props) => props.theme.color.primary};
  text-align: center;
  font-family: 'Sedan SC';
`;

export const FeatureContainer = styled.div`
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);

  width: 100%;
  padding: 1.5em;
  font-weight: 400;
  span {
    margin-left: 10px;
  }
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  .items-wrapper {
    margin-top: 1.7em;
  }
`;

export const Item = styled.div`
  display: flex;
  padding: 0.5em;
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  span {
    text-decoration: underline;
  }
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const CustomItemsLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: ${props => "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent)"};
  background-size: 200% 100%;
  width: 100%;
  height: 58vh;
`;

export const FormLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  margin-top: 15px;
`;

export const FormLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: ${props => "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent)"};
  background-size: 200% 100%;
  width: 100%;
  height: 3em;
`;

export const ViewItemsWrapper = styled.div<CustomizeLayoutForm>`
  background: ${(props) => (props.theme === 'ligth' ? "rgba(226, 223, 224, 0.6)"  : 
  "linear-gradient(180deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%), rgba(0, 0, 0, 0.64);")};
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  letter-spacing: 0.05em;
  gap: 20px;
  border-radius: ${(props) => (props.border === 'radius' ? '30px' : 'none')};
`;
interface WrapperBackGround {
  backgroundBase64?: string;
}

export const CustomItemsWrapper = styled.div<WrapperBackGround>`
  background-image: url(${(props) =>
    props.backgroundBase64 && props.backgroundBase64.length > 0
      ? props.backgroundBase64
      : background});
  padding: 30px;
  background-size: cover;
  max-width: 450px; /* Definindo o tamanho máximo de largura */
`;

export const EstablishmentLogo = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 100%;
`;

export const CustomText = styled.h1`
  font-size: 24px;
  color: ${(props) => (props.theme === 'ligth' ? "black" : "#fff")};
  font-family: 'Sedan SC';
  font-weight: 500;
`;

export const ColorPickerContainer = styled.div`
  margin-top: 1em;
`

export const ColorComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza os itens verticalmente */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
  color: grey;
  width: 100%;
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  span {
    margin-left: 1.7em;
  }
`;

export const ImagesComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza os itens verticalmente */
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.08);
  color: ${(props) => props.theme.color.dark};
  width: 100%;
  padding-left: 0.7em;
  padding-right: 0.7em;
  height: 2.8em;
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  span {
    margin-left: 1.7em;
  }
`;

export const Container = styled.div`
  display: flex; /* Centralizar o conteúdo */
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-bottom: 5em;
  width: 100%;
  justify-content: center; /* Centralizar o conteúdo */
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.2em;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Itens igualmente separados */
  align-items: center; /* Centraliza os itens verticalmente */
`

export const IconAndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; /* Centraliza os itens verticalmente */
  gap: 0.4em;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; /* Centraliza os itens verticalmente */
  justify-content: center; 
  font-size: 1.2em;
`

export const TextWithSquareColored = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; /* Centraliza os itens verticalmente */
  gap: 0.5em;

`

interface ColorComponentProps {
  color?: string;
  border?: string;
}


export const SquareColored =  styled.div<ColorComponentProps>`
  height: 0.8em;
  width: 0.8em;
  background-color: ${(props) => props.color}
`


export const PreVisualizerColorSquare = styled.div<ColorComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  text-align: center; /* Centraliza o texto horizontalmente */
  border-radius: ${(props) => (props.border === 'radius' ? '30px' : 'none')};
  color: white;
  font-size: 20px;
  font-weight: Bold;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;
  padding: 14px;
  width: 100%;
  background-color: ${(props) => props.color};
  box-shadow: 0 0 15px 1px ${(props) => props.color}; /* Adicionando a borda esfumaçada roxa */
`;
