import styled from 'styled-components';

interface StyleItemProps {
  isActive: boolean;
}

export const BarberName = styled.h2`
  color: ${(props) => props.theme.color.primary};
  text-align: center;
  font-family: 'Sedan SC';
`;

export const ContainerPadding = styled.div`
  display: flex;
  justify-content: space-between; /* Para colocar os elementos em lados opostos */
  align-items: center; /* Para centralizar verticalmente */
`;

export const Container = styled.div`
  padding: 1em;
`;

export const FeatureContent = styled.div`
  display: flex;
  justify-content: space-between; /* Para colocar os elementos em lados opostos */
  align-items: center; /* Para centralizar verticalmente */
`;

export const IconAndTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
`;

export const TitleContainer = styled.div`
  font-size: 17px;
`;


export const FeatureContainer = styled.div`
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1em;
  margin-top: 1em;
  width: 100%;
  padding: 1em 1.5em 1em 1.5em;
  font-weight: 400;
  span {
    margin-left: 10px;
  }
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  .items-wrapper {
    margin-top: 1.7em;
  }

`;

export const Item = styled.div<StyleItemProps>`
  display: flex;
  padding: 0.5em;
  opacity: ${(props) => (props.isActive ? '1' : '0.5')};
  svg {
    color: ${(props) => props.theme.color.primary};
  }
  span {
    text-decoration: underline;
  }
`;

export const LogoEstablishmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.4em;
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 100%;
`;
export const LogoffText = styled.p`
  margin-top: 2em;
  color: ${(props) => props.theme.color.primary};
  text-align: center;
  a {
    margin-left: 10px;
  }
`;
