import ChildrenProps from '../../@core/domain/ui/ChildrenProp';
import { Wrapper } from './style';

export default function DashboardWrapper({ children }: ChildrenProps) {
  return (
    <Wrapper>
        {children}
    </Wrapper>
  );
}
