import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface CheckboxProps {
  selected?: boolean;
}

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 2em;
  overflow-y: scroll;
  padding: 1em;
  margin-left: -30px;
  margin-right: -5vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const AddCoworker = styled.div``;

export const ImageCoworker = styled.img<CheckboxProps>`
  border: 8px solid rgba(128, 128, 128, 0.2);
  box-shadow: ${props => (props.selected ? `0 0 12px 1px #5d17eb;` : 'none')};
  transition: all 0.1s ease-in-out;
  width: 6em;
  height: 6em;
  object-fit: cover;
`;

interface WrapperItem {
  active: boolean;
}

export const ItemsButtonWrapper = styled.div<WrapperItem>`
  position: relative;
  width: 110px;
  gap: 2em;
  text-align: center;
`;

export const ItemButtonDiv = styled.div`
  height: 100%;
  width: 6em;
  gap: 0.2em
`;

export const ItemButton = styled.div<CheckboxProps>`
  box-shadow: ${props => (props.selected ? `0 0 12px 1px #5d17eb;` : 'none')};
  display: flex;
  justify-content: center;
  color: grey;
  transition: all 0.1s ease-in-out;
  background-color: #e6e6e6;
  width: 90px;
  width: 110px;
  height: 90px;
  &:first-child {
    margin-left: 20px;  // Ajuste o valor conforme necessário
  }
`;

export const ItemImage = styled.button`
  display: flex;
  flex-direction: row; /* Correção: era "flex-direction: row:" */square
  align-items: center;
  justify-content: center;
  background-color: #e9e9e9;
  border: none;
  padding: 1em;
  font-family: 'Sedan SC';
  font-size: 18px;
  
  svg {
    color: ${(props) => props.theme.color.primary};
  }
`;

export const ItemText = styled.div`
  font-family: 'Sedan SC';
  width: 100%;
  word-wrap: break-word;
  white-space: pre-line;
  text-align: center;
`;

export const IconButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  color: grey;
  transition: all 0.2s ease-in-out;
  width: 80px;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.6em;
`;
