import IUseCase from '../IUseCase';
import * as registerService from '../../infrastructure/api/services/oauthService';
import Login from '../../domain/model/Login';
import { AxiosResponse } from 'axios';
import LoginReturn from '../../domain/model/LoginReturn';

export default class LoginUseCase
  implements IUseCase<Login, Promise<AxiosResponse<LoginReturn>>>
{
  execute(login: Login) {
    return registerService.doLogin(login);
  }
}
