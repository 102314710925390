import Address from '../../../domain/model/Address';
import City from '../../../domain/model/City';
import State from '../../../domain/model/State';
import Establishment from '../../../domain/model/Establishment';

import api from '../api';
import CreateAddress from '../../../domain/model/CreateAddress';


export const getAllAddressByEstablishmentId = (id: number) => {
  return api.get<Establishment>(`/address-establishment/establishment/${id}`);
};

export const updateAddress = (establishmentId: number, addressId: number, data: Address) => {
  return api.put<Establishment>(`/address-establishment/establishment/${establishmentId}/update/${addressId}`, data);
};

export const createAddress = (establishmentId: number, data: CreateAddress) => {
  return api.post<Establishment>(`/address-establishment/establishment/${establishmentId}/create`, data);
};

export const getAllStates = () => {
  return api.get<State[]>(`/states`);
};

export const getAllCities = (stateId: number) => {
  return api.get<City[]>(`/cities/state/${stateId}`);
};
