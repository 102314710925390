import { useNavigate } from 'react-router-dom';
import {
  ContainerGif,
  Container,
  Content,
  Title,
  Text,
  ButtonReturn
} from './styles';

import emailConfirmation from '../../assets/svgs/emailConfirmation.gif';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';

export default function FinishedRegisterPage() {
  const navigate = useNavigate();
  

  return (
     <Container>
      <ContainerGif>
        <img src={emailConfirmation} alt="Formulário concluído" />
      </ContainerGif>
      <Content>
        <Title>Cadastro de estabelecimento QUASE finalizado</Title>
        <Text>Verifique sua caixa de entrada e clique no link fornecido para confirmar seu e-mail.</Text>
        <ButtonReturn onClick={() => {
           const tenant = getTenantStorage();
           navigate(`/${tenant}/login`)
        }}>Ir para login</ButtonReturn>
      </Content>
     </Container>
  );
}
