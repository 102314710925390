import React, { createContext, useState } from 'react';

interface ContextProps {
  setRefreshToken: (refreshToken: boolean) => (void | {});
  refreshToken: boolean;
}

interface ContextProps {
  setRefreshToken: (refreshToken: boolean) => (void | {});
  refreshToken: boolean;
}

export const RefreshTokenContext = createContext<ContextProps>({
  setRefreshToken: () => {},
  refreshToken: false
});

interface RefreshTokenContextProps {
  children: React.ReactNode
}

export default function RefreshTokenContextWrapper({ children }: RefreshTokenContextProps) {
  const [refreshToken, setRefreshToken] = useState<boolean>(false);

  return (
    <RefreshTokenContext.Provider value={{ refreshToken, setRefreshToken }}>
      {children}
    </RefreshTokenContext.Provider>
  )
}