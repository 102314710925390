import {
  faCheck,
  faChevronDown,
  faChevronUp,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureItem } from '../../@core/domain/ui/FeatureItem';
import { FeatureContainer, FeatureContent, IconAndTitle, IconContainer, Item, TitleContainer } from './styles';

interface props {
  title: string;
  icon: IconDefinition;
  iconx?: JSX.Element;
  expand: Number;
  setExpand: any;
  index: number;
  items: FeatureItem[];
}
export default function Feature({ title, icon, items, iconx, expand, setExpand, index }: props) {
  // const [showItems, setShowItems] = useState(false);
  const navigate = useNavigate();

  const itemsMemo = useMemo(() => {
    if (expand == index) {
      return items.map((item) => (
        <Item
          isActive={item.active}
          onClick={() => (item.active ? navigate(item.route) : () => {})}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>{item.text}</span>
        </Item>
      ));
    }
  }, [expand, index, items, navigate]);
  return (
    <>
      <FeatureContainer onClick={() => setExpand(expand === index ? -1 : index)}>
        <FeatureContent>
          <IconAndTitle>
            <IconContainer>
              {iconx}
            </IconContainer>
            <TitleContainer>
              {title}
            </TitleContainer>
          </IconAndTitle>
            
          <FontAwesomeIcon
            icon={expand === index ? faChevronUp : faChevronDown}
            onClick={() => setExpand(expand === index ? -1 : index)}
          />
        </FeatureContent>
         
        {expand == index && <div className="items-wrapper">{itemsMemo}</div>}
      </FeatureContainer>
    </>
  );
}
