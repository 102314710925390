import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationNotificationSend = Yup.object().shape({
  title: Yup.string().required(dictionary.global.validation.required),
  initialDate: Yup.string().required(dictionary.global.validation.required),
  content: Yup.string().max(200, dictionary.global.validation.maxLength.replace("{0}", "200")).required(dictionary.global.validation.required),
  target: Yup.string().required(dictionary.global.validation.required).min(2, dictionary.global.validation.required)
});

export default validationNotificationSend;
