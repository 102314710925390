import Error404Image from '../../assets/images/404.png';
import Button from '../../components/Button';
import {
  ModuleText,
  ModuleTitle,
} from '../../components/DashboardWrapper/style';
import { ErrorImage, ErrorWrapper } from './styles';

export default function Error404() {
  return (
    <ErrorWrapper>
      <ModuleTitle>Erro 404</ModuleTitle>
      <ModuleText>
        Essa página não existe. Verifique se informou o nome do
        estabelecimento na URL :)
      </ModuleText>

      <ErrorImage src={Error404Image} />

      <Button colorType="primary">Atualizar</Button>
    </ErrorWrapper>
);
}
