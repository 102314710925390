import { useCallback, useContext, useEffect, useState } from 'react';
import { VscWarning } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import DeleteServiceUseCase from '../../@core/application/services/deleteService';
import { default as GetServices, default as GetServicesInput } from '../../@core/application/services/getServices';
import OrganizeServicesUseCase, { OrganizeServicesInput } from '../../@core/application/services/organizeServices';
import Service from '../../@core/domain/model/Service';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import DialogModal from '../../components/DialogModal';
import { Form } from '../../components/Form/style';
import FooterMenu from '../../components/Menus/FooterMenu';
import PlusButton from '../../components/PlusButton';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import { SortItems } from '../../components/SortItems';
import LoadingContextContent from '../../contexts/LoadingContext';
import { BsFileMedical } from "react-icons/bs";

import {
  AdviseContainer,
  ButtonOrderContainer,
  ButtonSaveOrder,
  FormScroll,
  Header,
  HeaderContainer,
  IconAdviseContainerWarning,
  Instructiontext,
  ServiceLoader,
  ServiceWrapper,
  TextActiveAccount
} from './styles';
import { useAlert } from 'react-alert';

export default function Services() {
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service>();
  const [dialogModal, setDialogModal] = useState<boolean>(false);
  const [serviceLoading, setServiceLoading] = useState(false);

  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContextContent);

  const showAlert = useAlert();


  const closeLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const openLoad = useCallback(() => {
    setLoading(true);
  }, [setLoading]);


  const retrieveServices = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getServices = new GetServices();
    setServiceLoading(true);

    getServices
      .execute({ perPage: 99999 } as GetServicesInput)
      .then((response) => {
        setServices(response.data.items);
        setServiceLoading(false);
      })
      .catch((err) => {
        setServiceLoading(false);
        if (err?.response?.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  useEffect(() => {
    retrieveServices();
  }, [retrieveServices]);

  const reset = useCallback(() => {
    retrieveServices();
    setSelectedService(undefined);
  }, [retrieveServices]);


  const orderServices = useCallback(async (service: Service | null) => {
    const organizeService = new OrganizeServicesUseCase()
          
    console.log("items", services)

    const positions = {
      positions: services.filter(item => item.id !== service?.id).map((item: any, index: number) => {
        return {
          "id": item.id,
          "position": index
        }
      })
    } as OrganizeServicesInput;

    console.log("positions", positions)
    
    
    await organizeService.execute(positions)
      .then(res => {
      }).catch(err => {
        console.log("Erro ao organizar serviços: ", err)
      })
  }, [services])

  const removeService = useCallback((service: Service) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      setDialogModal(false);
      setServiceLoading(true);

      const deleteService = new DeleteServiceUseCase();
      deleteService
        .execute(service.id || 0)
        .then(() => {
            showAlert.success('Excluido com sucesso.');
            orderServices(service)
            reset();          
        })
        .catch((err) => {
          reset();
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [services, reset]
  );

  return (
    <ServiceWrapper>
      <HeaderContainer>
        <Header>Serviços</Header>
        <Instructiontext>
          Crie, edite ou remova seus serviços
        </Instructiontext>
      </HeaderContainer>

      <PlusButton
          onClick={() => navigate("/criar-servico")}
        >
          Adicionar Serviço
      </PlusButton>

      <AdviseContainer>
        <IconAdviseContainerWarning>
          <BsFileMedical />
        </IconAdviseContainerWarning>
        <TextActiveAccount>
          Ordene os serviços conforme sua preferência, recomendamos que coloque os mais utilizados em primeiro lugar.
        </TextActiveAccount>
      </AdviseContainer>

      <Form>
        <FormScroll>
          {serviceLoading ? [1, 2, 3, 4, 5, 6].map(item => <ServiceLoader key={item} />) :
            <SortItems items={services} setItems={setServices} setSelectedService={setSelectedService} setDialogModal={setDialogModal}/>
          }
        </FormScroll>        
      </Form>

      <ButtonOrderContainer>
        <ButtonSaveOrder
            onClick={async () => {
              setServiceLoading(true);
              await orderServices(null)
              showAlert.success('Ordenado com sucesso.');
              setServiceLoading(false);
            }}
          >
            Salvar ordenação
        </ButtonSaveOrder>
      </ButtonOrderContainer>

    {dialogModal ? (
      <DialogModal isOpen={dialogModal}
        setIsOpen={setDialogModal}
        title={"IMPORTANTE"}
        message={"Ao excluir um serviço, não será possível recuperá-lo"}
        callback={() => removeService(selectedService as Service)}
        callbackReject={() => { setSelectedService(undefined) }}
      />
    ) : (<></>) }
      

      <FooterMenu />
    </ServiceWrapper>
  );
}
