import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationStep1 = Yup.object().shape({
  name: Yup.string().required(dictionary.login.validation.required),
  gmailURL: Yup.string().required(dictionary.login.validation.required),
  telephone: Yup.string().required(dictionary.login.validation.required),
  establishmentType: Yup.string().nullable().required(dictionary.login.validation.required),
  startWork: Yup.string().required(dictionary.login.validation.required),
  endWork: Yup.string().required(dictionary.login.validation.required)
});

export default validationStep1;
