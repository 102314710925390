import IUseCase from '../IUseCase';
import { AxiosResponse } from 'axios';
import UploadResponse from '../../domain/model/UploadResponse';
import { uploadEstablishmentImages } from '../../infrastructure/api/services/establishmentService';

export default class UploadEstablishmentImage
  implements IUseCase<FormData, Promise<AxiosResponse<UploadResponse>>>
{
  execute(input: FormData) {
    return uploadEstablishmentImages(input);
  }
}
