import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationStep2 = Yup.object().shape({
  cep: Yup.string().required(dictionary.login.validation.required),
  street: Yup.string().required(dictionary.global.validation.required).min(4, dictionary.global.validation.minLength.replace("{0}", "4")).max(200, dictionary.global.validation.maxLength.replace("{0}", "200")),
  number: Yup.number().nullable().required(dictionary.global.validation.required).moreThan(0, dictionary.global.validation.required),
  neighborhood: Yup.string().required(dictionary.global.validation.required).min(4, dictionary.global.validation.minLength.replace("{0}", "4")).max(200, dictionary.global.validation.maxLength.replace("{0}", "200")),
  state: Yup.string().nullable().required(dictionary.login.validation.required),
  city: Yup.string().nullable().required(dictionary.login.validation.required)
});

export default validationStep2;
