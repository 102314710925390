import IUseCase from '../IUseCase';
import * as categoryService from '../../infrastructure/api/services/categoryService';
import Category from '../../domain/model/Category';
import { AxiosResponse } from 'axios';

export default class GetCategories
  implements IUseCase<void, Promise<AxiosResponse<Category[]>>>
{
  execute(): Promise<AxiosResponse<Category[]>> {
    return categoryService.getCategories();
  }
}
