import SaveTheme from '../../../domain/model/SaveTheme';
import UploadResponse from '../../../domain/model/UploadResponse';
import api from '../api';
import * as storageService from './storageService';

export const saveTheme = (theme: SaveTheme) => {
  return api.post(
    `establishments/${storageService.getEstablishmentId()}/customizations`,
    theme
  );
};

export const uploadLogo = (data: FormData) => {
  return api.post<UploadResponse>(`/storage/establishment-images`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadBackground = (data: FormData) => {
  return api.post<UploadResponse>(`/storage/establishment-images`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCustomizations = () => {
  return api.get<SaveTheme>(
    `establishments/${storageService.getEstablishmentId()}/customizations`
  );
};
export const getTheme = () => {
  return api.get(
    `establishments/${storageService.getEstablishmentId()}/themes`
  );
};
