import styled from 'styled-components';
import GridCol from '../GridCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ActionTextProps {
  disable: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  justify-content: space-between;
  gap: 1em;
  @media only screen and (min-width: 700px) {
    width: 500px;
  }
  padding: 0.7em 1em;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomerImage = styled.img`
  width: 25vw;
  height: 25vw;
  object-fit: cover;
  border: 8px solid rgba(128, 128, 128, 0.2);

  @media only screen and (min-width: 700px) {
    width: 100px;
    height: 100px;
  }

`;

export const Name = styled.div`
  font-size: 1.2em;
  font-weight: normal;
  color: #3f3d56;
`;

export const Age = styled.div`
  margin-top: 0.5em;
  font-size: 0.8em;
  font-weight: normal;
  color: #656478;
`;

export const Information = styled.div`
  margin-top: 0.5em;
  font-size: 0.8em;
  font-weight: normal;
  color: #656478;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActionText = styled.div<ActionTextProps>`
  font-size: 0.5em;
  margin-top: 0.2em;
  font-weight: normal;
  color: ${(props) => (props.disable ? ` #ccc` : `#3F3D56`)};
`;

export const Soon = styled.div`
  margin-top: 0.5em;
  opacity: 0.4;
  font-size: 0.6em;
  background-color: #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 2em;
`;
