import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationStep3 = Yup.object().shape({
  ownerName: Yup.string().required(dictionary.login.validation.required)
    .min(4, dictionary.global.validation.minLength.replace("{0}", "4"))
    .max(200, dictionary.global.validation.maxLength.replace("{0}", "200")),
  ownerEmail: Yup.string().required(dictionary.login.validation.required),
  ownerPassword: Yup.string().required(dictionary.login.validation.required),
  ownerPasswordRepeat: Yup.string().required(dictionary.login.validation.required),
  ownerAge: Yup.number().required(dictionary.login.validation.required),
  ownerStartLunchTime: Yup.string().required(dictionary.login.validation.required),
  ownerEndLunchTime: Yup.string().required(dictionary.login.validation.required),
  ownerStartWork: Yup.string().nullable().required(dictionary.login.validation.required),
  ownerEndWork: Yup.string().nullable().required(dictionary.login.validation.required)
});

export default validationStep3;
