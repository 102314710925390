import Service from '../../../domain/model/Service';
import ServiceCreateResponse from '../../../domain/model/ServiceCreateResponse';
import api from '../api';
import { getEstablishment, getEstablishmentId } from './storageService';

export const associatePreviousService = (
  data: FormData,
  finalClientId: number
) => {
  return api.post(`/final-clients/${finalClientId}/previous-service`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getPreviousService = (finalClientId: number, serviceId?: number, bookedTimeId?: number) => {
  return api.get(`/final-clients/${finalClientId}/previous-service?serviceId=${serviceId}&bookedTimeId=${bookedTimeId}`);
};

export const deletePreviousService = (
  finalClientId: number,
  previousServiceId: number,
  imageId: number
) => {
  return api.delete(`/final-clients/${finalClientId}/previous-service/${previousServiceId}/images/${imageId}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
