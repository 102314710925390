import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 1em;
  padding-bottom: 4em;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WarningContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -5px;
  margin-top: -5px;
`;

export const IconContainerWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  color: ${(props) => props.theme.color.primary};
  padding: 0.2em;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
`;

export const WarningActiveAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
  padding: 1em;
`;

export const IconActiveAccountContainerWarning = styled.div`
  font-size: 1.4em;
  color: ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TextActiveAccount = styled.div`
  font-size: 0.9em;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
  padding-top: 2em;
`;


export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;

export const RemoveCoworkerContainer = styled.div`
  text-align: center;
  color: gray;
  font-weight: 300;
  padding-bottom: 1em;
`;

export const ColaboratorsLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const ColaboratorsLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 6em;
  height: 6em;
`;

export const IconContainer = styled.div`
  font-size: 1.2em;
  margin-right: 0.5em;
  display: flex;
  flex-direction: row; /* Correção: era "flex-direction: row:" */
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1px; /* Define a altura da linha */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ccc 10%, #ccc 90%, rgba(255, 255, 255, 0)); /* Gradiente nos lados esquerdo e direito */

  /* Adiciona uma sombra para destacar a linha */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;
