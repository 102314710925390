import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationAddressForm = Yup.object().shape({
  cep: Yup.string().matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido").required(dictionary.global.validation.required),
  street: Yup.string().required(dictionary.global.validation.required).min(4, dictionary.global.validation.minLength.replace("{0}", "4")).max(200, dictionary.global.validation.maxLength.replace("{0}", "200")),
  number: Yup.number().nullable().required(dictionary.global.validation.required),
  neighborhood: Yup.string().required(dictionary.global.validation.required).min(4, dictionary.global.validation.minLength.replace("{0}", "4")).max(200, dictionary.global.validation.maxLength.replace("{0}", "200")),
  city: Yup.string().nullable().required(dictionary.global.validation.required),
  state: Yup.string().nullable().required(dictionary.global.validation.required),
});

export default validationAddressForm;
