import * as Yup from 'yup';

const validationChange = Yup.object().shape({
  code: Yup.string().nullable().required(`Obrigatório`),
  newPassword: Yup.string()
    .required('Obrigatório')
    .min(6, 'A senha deve contér pelo menos 6 caracteres')
    .matches(/^[^a-zA-Z]*[a-zA-Z]+.*$/, 'Senha tem que contér uma letra')
    .matches(/^[^A-Z]*[A-Z]+.*$/, ' Senha deve contér uma letra maiscula')
    .matches(/^[^0-9]*[0-9]+.*$/, 'Senha tem que contér um número'),

  confirmNewPassword: Yup.string()
    .required('Obrigatório')
    .test(
      'newPassword',
      'As senhas devem ser iguais',
      function (confirmPassword: string | undefined) {
        return confirmPassword === this.parent.newPassword;
      }
    ),
});

export default validationChange;
