import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';
import Positions from '../../domain/model/Positions';


export default interface OrganizeServicesInput {
  serviceId: number;
  positions: Positions;
}

export default class OrganizeServiceImages implements IUseCase<OrganizeServicesInput, Promise<any>> {
  execute(organizeServicesInput: OrganizeServicesInput) {
    return serviceService.organizeServiceImages(organizeServicesInput.serviceId, organizeServicesInput.positions);
  }
}
