import styled, { keyframes } from 'styled-components';

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 1em;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
  padding-top: 2em;
  margin-bottom: 2.5em;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 5em;
`;

export const FormScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-height: 50vh;
  overflow-y: auto;
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: ${(props) => props.theme.color.borderUnfocused};
  font-size:  1.2em;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
  padding: 1em 1em;
  transition: all 0.2s ease-in-out;
  letter-spacing: 1px;
  background-color: white;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const ServiceLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 100%;
  height: 6em;
`;

export const IconStartContainer = styled.div`
  color: ${(props) => props.theme.color.primary};
  margin-right: 1em;
`;

export const IconEndContainer = styled.div`
  color: red;
  margin-left: auto; /* Isso empurra o IconEndContainer para o lado direito */
`;

export const Text = styled.div`
  margin-right: auto; /* Isso empurra o Text para o lado esquerdo */
`;


export const ButtonOrderContainer = styled.div`
  margin-top: 2em;
  padding-bottom: 5em;
`;

export const ButtonSaveOrder = styled.button`
  background-color: ${(props) => props.theme.color.primary};
  padding: 10px;
  color: ${(props) => props.theme.color.light};
  width: 100%;
  border: none;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 50px;
  cursor: pointer; /* Altera o cursor ao passar o mouse */

  /* Efeito de pressionamento */
  :active {
    transform: translateY(1px); /* Desloca o botão para baixo */
    box-shadow: 0 0 10px 2px rgba(93, 23, 235, 0.5); /* Sombra ao pressionar */
  }

  /* Efeito de hover */
  :hover {
    box-shadow: 0 0 20px 5px rgba(93, 23, 235, 0.3); /* Bordar esfumaçada */
  }
`;

export const AdviseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
  padding: 1em;
  margin-top: 1em;
`;

export const IconAdviseContainerWarning = styled.div`
  font-size: 1.4em;
  color: ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TextActiveAccount = styled.div`
  font-size: 0.9em;
`;
