import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1.2em;
  color: ${(props) => props.theme.color.white};
`;

export const SelectContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.input};
  padding-right: 1em;
  border: ${(props) => props.theme.color.borderUnfocused};
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.1);
`;

export const Select = styled.select`
  outline: none;
  font-size: ${(props) => (props.size ? `${props.size}em` : '1.2em')};
  color: ${(props) => props.theme.color.dark};
  width: 100%;
  letter-spacing: 1px;
  font-weight: 300;
  padding: 0.7em 1.5em;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.theme.input};
  border: ${(props) => props.theme.color.borderUnfocused};

  &:focus {
    border-color: ${(props) => props.theme.color.primary};
  }
  text-indent: 29px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ShowPassword = styled.button`
  background-color: ${(props) => props.theme.color.light};
  border: none;
  color: ${(props) => props.theme.color.borderUnfocused};
  font-size: 1.2em;
  align-self: flex-end;
  transform: translate(-16px, -40px);
  padding: 0.2em;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  height: 0px;

  &:hover {
    color: ${(props) => props.theme.color.primary};
  }
`;
export const ShowIcon = styled.button`
  background-color: ${(props) => props.theme.color.light};
  border: none;
  color: ${(props) => props.theme.color.primary};
  font-size: 1.2em;
  align-self: flex-start;
  transform: translate(-16px, -40px);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  height: 0px;
  margin-left: 1.8em;
`;

export const ErrorMessage = styled.small<{ showPassword?: boolean }>`
  color: ${(props) => props.theme.color.error};
  padding-top: ${(props) => (props.showPassword ? '0' : '6px')};
  letter-spacing: 1px;
`;
