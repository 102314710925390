import React from 'react';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ReactDOM from 'react-dom/client';
import { ScreenClassProvider } from 'react-grid-system';
import './assets/fonts/Pacifico.ttf';
import './assets/fonts/Sedan SC.ttf';
import './assets/index.css';
import Background from './container/BackgroundContainer';
import Offsite from './containers/Offside';
import { LoadingContext } from './contexts/LoadingContext';
import RefreshTokenContextWrapper from './contexts/refreshTokenContext';
import Layout from './layout';
import Routes from './routes';
import DashboardWrapper from './components/DashboardWrapper';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
root.render(
  <React.StrictMode>
    <PrimeReactProvider>

      <Layout>
        <AlertProvider template={AlertTemplate} {...options}>
          <LoadingContext>
            <RefreshTokenContextWrapper>
              <ScreenClassProvider>
                <Background>
                  <Offsite>
                    <DashboardWrapper>
                      <Routes />
                    </DashboardWrapper>
                  </Offsite>
                </Background>
              </ScreenClassProvider>
            </RefreshTokenContextWrapper>
          </LoadingContext>
        </AlertProvider>
      </Layout>
    </PrimeReactProvider>

  </React.StrictMode>
);
