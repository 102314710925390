import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as services from '../../infrastructure/api/services/servicesService';

export default interface DeleteServiceImageInput {
  serviceId: number;
  imageId: number;
}


export default class DeleteServiceImageUseCase
  implements IUseCase<DeleteServiceImageInput, Promise<any>>
{
  execute(input : DeleteServiceImageInput) {
    return services.deleteServiceImage(input.serviceId, input.imageId);
  }
}
