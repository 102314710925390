import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGray, Content } from './styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface Params {
  children: React.ReactNode;
  onClick?: () => void;
}
export default function PlusButton({ children, onClick }: Params) {
  return (
    <ButtonGray type="button" onClick={onClick}>
      <Content>
        <FontAwesomeIcon icon={faPlus} />
        {children}
      </Content>
    </ButtonGray>
  );
}
