import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const ValidationUpdateColaboratorForm = Yup.object().shape({
  name: Yup.string().max(30, dictionary.global.validation.maxLength.replace("{0}", "30")).required(dictionary.global.validation.required),
  email: Yup.string().required(dictionary.global.validation.required).max(100, dictionary.global.validation.maxLength.replace("{0}", "100")),
  startLunchTime: Yup.string().required(dictionary.coworker.validation.startLunchTimeRequired),
  endLunchTime: Yup.string().required(dictionary.coworker.validation.endLunchTimeRequired),
  age: Yup.number().moreThan(15, dictionary.global.validation.minAge.replace("{0}", "16")).required(dictionary.global.validation.required),
});

export default ValidationUpdateColaboratorForm;
