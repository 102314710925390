import CustomizeLayoutForm from '../../@core/domain/ui/CustomizeLayoutForm';
import Button from '../../components/Button';
import {
  CustomItemsWrapper,
  CustomText,
  EstablishmentLogo,
  CustomItemsLoader,
  ViewItemsWrapper,
  PreVisualizerColorSquare,
} from './styles';
import defaultLogo from '../../assets/images/logo_barber.png';

export default function CustomView(theme: CustomizeLayoutForm) {
  
  if(theme.loadingCustomizations == true){
    return <CustomItemsLoader/>
  }

  return (
    <CustomItemsWrapper backgroundBase64={theme.background}>
        <ViewItemsWrapper {...theme}>
          <CustomText {...theme}>Modo Criativo</CustomText>
          <EstablishmentLogo
            src={theme.logo.length > 0 ? theme.logo : defaultLogo}
          />
          <PreVisualizerColorSquare color={theme.color} border={theme.border} >
            Pré-visualização
          </PreVisualizerColorSquare>
        </ViewItemsWrapper>
      </CustomItemsWrapper>
  )
}
