import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as analyticsService from '../../infrastructure/api/services/analyticsService';
import { AxiosResponse } from 'axios';
import ScheduleAnalytics from '../../domain/model/ScheduleAnalytics';

export interface GetQuantitySchedulesInput {
  initialIntervalDate: string;
  endIntervalDate: string;
  colaboratorId: number | null;
}

export default class GetQuantitySchedulesUseCase
  implements
    IUseCase<
      GetQuantitySchedulesInput,
      Promise<AxiosResponse<ScheduleAnalytics>>
    >
{
  execute(input: GetQuantitySchedulesInput) {
    return analyticsService.getQuantitySchedules(
      input.initialIntervalDate,
      input.endIntervalDate,
      input.colaboratorId
    );
  }
}
