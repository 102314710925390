import Establishment from '../../../domain/model/Establishment';
import Positions from '../../../domain/model/Positions';
import UploadResponse from '../../../domain/model/UploadResponse';
import api from '../api';
import { getEstablishmentId } from './storageService';

export const getEstablishmentBySite = (nameSite: string) => {
  return api.get<Establishment>(`/establishments`, {
    headers: {
      nameSite,
    },
  });
};

export const getEstablishmentById = (id: number) => {
  return api.get<Establishment>(`/establishments/${id}`);
};

export const updateEstablishment = (id: number, data: Establishment) => {
  return api.put<Establishment>(`/establishments/${id}`, data);
};

export const deleteImage = (establishmentId: Number, imageId: Number) => {
  return api.delete(`/storage/establishment-images/${establishmentId}/images/${imageId}`)
};

export const uploadEstablishmentImages = (data: FormData) => {
  return api.post<UploadResponse>(`/storage/establishment-images`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const organizeCarouselImages = (positions: Positions) => {
  return api.post(
    `/establishments/${getEstablishmentId()}/image-positions`,
    positions
  );
};
