import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';

export default class RegisterService
  implements IUseCase<Service, Promise<any>>
{
  execute(registerService: Service) {
    return serviceService.createServices(registerService);
  }
}
