import { ReactNode, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RefreshTokenUseCase from "../../@core/application/auth/refreshTokenUseCase";
import { APP_ROUTES } from "../../config/routes";
import LoadingContextContent from "../../contexts/LoadingContext";
import { RefreshTokenContext } from "../../contexts/refreshTokenContext";
import { getInformationFromJWTRefreshTokenStorage, getInformationFromJWTStorage, getKeepConnectingStorage, getTenantStorage, setFinalClientJWTStorage } from "../../@core/infrastructure/api/services/storageService";


type PrivateRouteProps = {
	children: ReactNode
}

export const checkUserAuthenticated = () => {
	const expirationToken = getInformationFromJWTStorage("exp");

	if(!expirationToken) return false;
	
	const currentTimeInSeconds = Math.floor(Date.now() / 1000);
	return expirationToken > currentTimeInSeconds;
}

export const getPermission = () => {
	return getInformationFromJWTStorage("permission");
}

export const getColaboratorId = () => {
	return getInformationFromJWTStorage("sub");
}

export const checkRefreshTokenIsValid = () => {
	const expirationToken = getInformationFromJWTRefreshTokenStorage("exp");

	if(!expirationToken) return false;
	
	const currentTimeInSeconds = Math.floor(Date.now() / 1000);
	return expirationToken > currentTimeInSeconds;
}

export const PrivateRoute = ({children} : PrivateRouteProps) => {
  const { refreshToken, setRefreshToken } = useContext(RefreshTokenContext);
  const { setLoading, loading } = useContext(LoadingContextContent);

  const navigate = useNavigate();


	const isUserAuthenticated = checkUserAuthenticated();
	const keepConnecting = getKeepConnectingStorage() == "true";

	const finalizeRefreshTokenFlow = useCallback(() => {
		setRefreshToken(true)
		setLoading(false)
	}, [setLoading, setRefreshToken])


	useEffect(() => {
    const tenant = getTenantStorage();

		if(isUserAuthenticated){
			console.log("Usuário autenticado.")
			finalizeRefreshTokenFlow()
			return;
		}

		console.log("Usuário não autenticado. Iniciando fluxo de refresh token")

		// if(!keepConnecting){
		// 	console.log("Mantenha-me conectado não habilitado. Direcionando para login")
		// 	finalizeRefreshTokenFlow();
		// 	navigate(`/${tenant}/login`)
		// 	return;
		// }

		console.log("Backoffice não possui feature de keep connecting. Realizando fluxo de refresh token")
		if(!checkRefreshTokenIsValid()){
			console.log("Refresh token EXPIRADO. Direcionando para tela de login")
			finalizeRefreshTokenFlow();
			navigate(`/${tenant}/login`)
			return;
		}

		console.log("Refresh token NÃO expirado. Continuando com fluxo de refresh token")
		
		const refreshTokenUseCase = new RefreshTokenUseCase();
		refreshTokenUseCase.execute().then((res: any) => {
			console.log("Fluxo de refresh token realizado com sucesso. Mantendo na mesma página com token atualizado")
			setFinalClientJWTStorage(res.data.token)
			finalizeRefreshTokenFlow()
		}).catch((err: any) => {
			console.log("Fluxo de refresh token falhou. Direcionando para tela de login")
			finalizeRefreshTokenFlow()
      navigate(`/${tenant}/login`)
		})
	}, [isUserAuthenticated, navigate, keepConnecting])

	return (
		<>
			{refreshToken ? children : setLoading(true)}
		</>
	)
}

export default PrivateRoute;
