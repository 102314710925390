import {
  faAddressCard,
  faBriefcase,
  faEnvelope,
  faLock,
  faPlus,
  faUser,
  faUtensils
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import Textbox from '../../components/TextboxMUI';

import { useFormik } from 'formik';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAlert } from 'react-alert';
import { CiImageOn } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import DeleteColaboratorUseCase from '../../@core/application/colaborator/deleteColaborator';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import RegisterColaborator from '../../@core/application/colaborator/registerColaborator';
import UpdateColaborator from '../../@core/application/colaborator/updateColaborator';
import UpdateAvatar from '../../@core/application/colaborator/uploadAvatar';
import Colaborator from '../../@core/domain/model/Colaborator';
import RegisterColaboratorForm, {
  MapToColaborator,
} from '../../@core/domain/ui/RegisterColaboratorForm';
import {
  getEstablishmentId,
  getTenantStorage
} from '../../@core/infrastructure/api/services/storageService';
import DialogModal from '../../components/DialogModal';
import {
  Icon,
  IconButton,
  ImageCoworker,
  ItemButton,
  ItemButtonDiv,
  ItemImage,
  ItemText,
  ItemsButtonWrapper,
  ItemsWrapper,
} from '../../components/Items/style';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import Textarea from '../../components/Textarea';
import TimePickerBox from '../../components/TimePickerBox';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  normalizeBucketURIFallback
} from '../../utils/BucketAccess';
import { formatDateWithHourAndMinutesOnly } from '../../utils/HoursUtils';
import validationColaboratorForm from './registerValidation';
import {
  ColaboratorsLoader,
  ColaboratorsLoaderContainer,
  Container,
  Divider,
  Header,
  HeaderContainer,
  IconActiveAccountContainerWarning,
  IconContainer,
  IconContainerWarning,
  Instructiontext,
  RemoveCoworkerContainer,
  TextActiveAccount,
  TextContainer,
  WarningActiveAccountContainer,
  WarningContainer,
} from './styles';
import validationUpdateColaboratorForm from './updateValidation';
import FooterMenu from '../../components/Menus/FooterMenu';
import { VscWarning } from "react-icons/vsc";

const initialColaborator: RegisterColaboratorForm = {
  name: '',
  email: '',
  password: '',
  passwordRepeat: '',
  description: '',
  imagePerfil: {},
  facebookURL: '',
  gmailURL: '',
  startLunchTime: '',
  endLunchTime: '',
  startWork: '',
  endWork: '',
  instagramURL: '',
  whatsappURL: '',
  age: '',
  id: 0,
};

export default function CoWorkers() {
  const [showForm, setShowForm] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [colaboratorsLoading, setColaboratorsLoading] = useState(true);
  const [dialogModal, setDialogModal] = useState<boolean>(false);

  const showAlert = useAlert();

  const navigate = useNavigate();

  const [colaboratorForm, setColaboratorForm] = useState<RegisterColaboratorForm>(initialColaborator);
  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [uploadImage, setUploadImage] = useState<File>();
  const inputFile = useRef<HTMLInputElement>(null);
  const { setLoading } = useContext(LoadingContextContent);

  const reset = useCallback(() => {
    setColaboratorForm(initialColaborator);
    setColaborators([]);
    setShowForm(false);
    registerForm.resetForm();
    setIsEdit(false);
    findColaborators();
    setLoading(false);
  }, []);

  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: colaboratorForm,
    validationSchema: isEdit
      ? validationUpdateColaboratorForm
      : validationColaboratorForm,
    onSubmit: (values: RegisterColaboratorForm) => {
      const avatar = inputFile.current?.files
        ? inputFile.current?.files[0]
        : null;
      const register = MapToColaborator(values, avatar);
      register.colaboratorRole = 'COLABORATOR';

      if (!isEdit) {
        if (
          registerForm.values['password'] !==
          registerForm.values['passwordRepeat']
        ) {
          registerForm.setErrors({ passwordRepeat: 'senha não coincide' });
          return;
        }

        setLoading(true)
        createColaborator(register);
      } else {
        setLoading(true)
        updateColaborator(register, avatar ?? undefined);
      }
    },
  });

  const updateForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: colaboratorForm,
    validationSchema: validationUpdateColaboratorForm,
    onSubmit: (values: RegisterColaboratorForm) => {
      const avatar = inputFile.current?.files
        ? inputFile.current?.files[0]
        : null;
      const register = MapToColaborator(values, avatar);
      register.colaboratorRole = 'COLABORATOR';

      updateColaborator(register, avatar ?? undefined);
    },
  });

  const createColaborator = useCallback((register: Colaborator) => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const registerColaborator = new RegisterColaborator();

    console.log("register", register)
    registerColaborator
      .execute(register)
      .then(() => {
        showAlert.success('Colaborador registrado');
        findColaborators();
        setUploadImage(undefined)
        reset();
      })
      .catch((err) => {
        setLoading(false)

        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  const updateColaborator = useCallback(
    (register: Colaborator, avatar?: File) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const updateColaborator = new UpdateColaborator();
      register.id = colaboratorForm.id;
      console.log("register", register)
      updateColaborator.execute(register).then(() => {
        if (avatar) {
          uploadAvatar(avatar, register.id ?? NaN);
        } else {
          showAlert.success('Colaborador atualizado');
        }
        findColaborators(!avatar);
      }).catch(err => {
        setLoading(false)
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
    },
    [colaboratorForm]
  );

  const findColaborators = useCallback((withLoading?: boolean) => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetColaborator();
    if (withLoading) setLoading(true);

    setColaboratorsLoading(true)

    getUseCase.execute().then((response) => {
      setColaboratorsLoading(false)
      if (withLoading) setLoading(false);
      setColaborators(response.data);
    }).catch(err => {
      setColaboratorsLoading(false);
      if (err.response.status == 401) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
      };
    });

  }, []);


  useEffect(() => {
    findColaborators(false);
  }, []);

  const uploadAvatar = useCallback(
    async (avatar: File, colaboratorId: number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const uploadAvatar = new UpdateAvatar();
      setLoading(true);

      const formData = new FormData();
      formData.append('establishmentId', getEstablishmentId() ?? '');
      formData.append('colaboratorId', colaboratorId.toString());
      formData.append('fileImage', avatar);

      await uploadAvatar
        .execute(formData)
        .then((res) => {
          findColaborators(true);
          showAlert.success('Colaborador atualizado');
          setUploadImage(undefined)
          if (inputFile.current) {
            inputFile.current.value = '';
          }
        })
        .catch((err) => {
          alert('Erro ao fazer upload da imagem');
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [setUploadImage]
  );

  const deleteCoworker = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const deleteUseCase = new DeleteColaboratorUseCase();
    setDialogModal(false);
    setLoading(true);


    if (colaboratorForm.id)
      deleteUseCase
        .execute(colaboratorForm.id)
        .then(() => {
          showAlert.success('Colaborador excluído');
          reset();
        })
        .catch((err) => {
          showAlert.error('Falha ao excluir colaborador');
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
  }, [colaboratorForm, showAlert]);

  useEffect(() => {
    console.log("registerForm.values.startLunchTime", registerForm.values.startLunchTime)

    console.log("registerForm.values.startLunchTime", formatDateWithHourAndMinutesOnly(String(registerForm.values.startLunchTime)))
  }, [registerForm.values.startLunchTime])

  return (
    <Container>
      <HeaderContainer>
        <Header>Colaboradores</Header>
        <Instructiontext>Informações dos seus colaboradores.</Instructiontext>
      </HeaderContainer>
      <ItemsWrapper>
        <ItemButton
          selected={colaboratorForm.id == initialColaborator.id}
          onClick={() => {
            setColaboratorForm(initialColaborator);
            setShowForm(true);
            setIsEdit(false);
          }}
        >
          <IconButton>
            <Icon icon={faPlus} />
          </IconButton>
        </ItemButton>

        {colaboratorsLoading ? [1, 2, 3, 4, 5, 6].map(item => {
          return (<ColaboratorsLoaderContainer>
            <ColaboratorsLoader key={item} />
          </ColaboratorsLoaderContainer>)
        }) : (
          colaborators.map((colaborator) => (
            <ItemsButtonWrapper active={false}>
              <ItemButtonDiv>
                <ImageCoworker
                  selected={colaboratorForm.id == colaborator.id}
                  src={normalizeBucketURIFallback(
                    'COWORK',
                    colaborator.imagePerfil
                  )}
                  onClick={() => {
                    setColaboratorForm({
                      ...colaborator,
                      ...colaborator.socialLinks,
                      startLunchTime: colaborator.startLunchTime,
                      endLunchTime: colaborator.endLunchTime,
                      startWork: colaborator.startWork,
                      endWork: colaborator.endWork,
                      passwordRepeat: '',
                    });
                    setShowForm(true);
                    setIsEdit(true);
                  }}
                />
                <TextContainer>
                  <ItemText>{colaborator.name}</ItemText>
                </TextContainer>
                {!colaborator.active ? (
                  <WarningContainer>
                    <IconContainerWarning>
                      <VscWarning />
                    </IconContainerWarning>
                  </WarningContainer>
                ) : (<></>)}


              </ItemButtonDiv>
            </ItemsButtonWrapper>
          ))
        )}
      </ItemsWrapper>
      {showForm && (
        <Form>
          {!colaboratorForm.active && isEdit ? (
            <WarningActiveAccountContainer>
              <IconActiveAccountContainerWarning>
                <VscWarning />
              </IconActiveAccountContainerWarning>
              <TextActiveAccount>
                Conta pendente de ativação. Verifique a caixa de entrada do email
              </TextActiveAccount>
            </WarningActiveAccountContainer>
          ) : (<></>)}

          {colaboratorForm.id ? (
            <RemoveCoworkerContainer
              onClick={() => setDialogModal(true)}
            >
              Excluir colaborador
            </RemoveCoworkerContainer>
          ) : (
            ''
          )}
          <Textbox
            placeholder="Nome do colaborador"
            name="name"
            icon={faUser}
            value={registerForm.values.name}
            setValue={registerForm.handleChange}
            error={registerForm.errors.name}
          />

          <Textbox
            label="Email"
            placeholder="exemplo@gmail.com.br"
            name="email"
            icon={faEnvelope}
            value={registerForm.values.email}
            setValue={registerForm.handleChange}
            error={registerForm.errors.email}
          />
          {!isEdit ? (
            <>
              <Textbox
                label="Senha"
                placeholder="**********"
                type="password"
                name="password"
                icon={faLock}
                value={registerForm.values.password}
                setValue={registerForm.handleChange}
                error={registerForm.errors.password}
              />

              <Textbox
                label="Repita a senha"
                placeholder="**********"
                type="password"
                name="passwordRepeat"
                icon={faLock}
                value={registerForm.values.passwordRepeat}
                setValue={registerForm.handleChange}
                error={registerForm.errors.passwordRepeat}
              />
            </>
          ) : (
            <></>
          )}

          <Textbox
            placeholder="Idade"
            name="age"
            type="number"
            icon={faAddressCard}
            value={registerForm.values.age}
            setValue={registerForm.handleChange}
            error={registerForm.errors.age}
          />

          <Divider />

          <TimePickerBox
            label="Inicio horário de almoço"
            placeholder="Inicio horário de almoço"
            showLabel={true}
            name="startLunchTime"
            type="time"
            icon={faUtensils}
            value={registerForm.values.startLunchTime}
            setValue={(newValue: string) => { registerForm.setFieldValue("startLunchTime", newValue) }}
            error={registerForm.errors.startLunchTime}
          />

          <TimePickerBox
            label="Fim horário de almoço"
            placeholder="Fim horário de almoço"
            showLabel={true}
            name="endLunchTime"
            type="time"
            icon={faUtensils}
            value={registerForm.values.endLunchTime}
            setValue={(newValue: string) => { registerForm.setFieldValue("endLunchTime", newValue) }}
            error={registerForm.errors.endLunchTime}
          />

          <Divider />


          <TimePickerBox
            label="Inicio horário de expediente"
            placeholder="Inicio horário de expediente"
            name="startWork"
            showLabel={true}
            type="time"
            icon={faBriefcase}
            value={registerForm.values.startWork}
            setValue={(newValue: string) => { registerForm.setFieldValue("startWork", newValue) }}
            error={registerForm.errors.startWork}
          />

          <TimePickerBox
            label="Fim horário de expediente"
            placeholder="Fim horário de expediente"
            name="endWork"
            showLabel={true}
            type="time"
            icon={faBriefcase}
            value={registerForm.values.endWork}
            setValue={(newValue: string) => { registerForm.setFieldValue("endWork", newValue) }}
            error={registerForm.errors.endWork}
          />

          <Divider />


          <Textbox
            label="Link instagram"
            placeholder="https://www.instagram.com/beeleza-app/"
            name="instagramURL"
            icon={faAddressCard}
            value={registerForm.values.instagramURL}
            setValue={registerForm.handleChange}
            error={registerForm.errors.instagramURL}
          />

          <Textbox
            label="Link facebook"
            placeholder="https://www.facebook.com/beeleza-app"
            name="facebookURL"
            icon={faAddressCard}
            value={registerForm.values.facebookURL}
            setValue={registerForm.handleChange}
            error={registerForm.errors.facebookURL}
          />

          <Textbox
            label="Whatsapp número"
            placeholder="19982902475"
            name="whatsappURL"
            icon={faAddressCard}
            value={registerForm.values.whatsappURL}
            setValue={registerForm.handleChange}
            error={registerForm.errors.whatsappURL}
          />

          <Textbox
            label="Email para contato"
            placeholder="exemplo@gmail.com"
            name="gmailURL"
            icon={faAddressCard}
            value={registerForm.values.gmailURL}
            setValue={registerForm.handleChange}
            error={registerForm.errors.gmailURL}
          />

          <Divider />

          <Textarea
            placeholder="Descrição do colaborador"
            name="description"
            value={registerForm.values.description}
            setValue={registerForm.handleChange}
            error={registerForm.errors.description}
            icon={faAddressCard}
            limit={255}
          />

          {isEdit && (
            <>
              <ItemImage
                type="button"
                onClick={() => inputFile.current?.click()}
              >
                <IconContainer>
                  <CiImageOn />
                </IconContainer>
                <span>Adicionar foto</span>
              </ItemImage>
              <input
                id="imagePerfil"
                ref={inputFile}
                hidden
                name="imagePerfil"
                type="file"
                accept="image/*"
              />
            </>
          )}

          <Button
            onClick={() => {
              registerForm.submitForm();
            }}
            colorType="primary"
          >
            {isEdit ? 'Atualizar' : 'Criar'}
          </Button>
        </Form>
      )}

      {dialogModal ? (
        <DialogModal isOpen={dialogModal}
          setIsOpen={setDialogModal}
          title={"IMPORTANTE"}
          message={"Ao excluir um colaborador, não será possível recuperá-lo."}
          callback={() => deleteCoworker()}
          callbackReject={() => { }}
        />
      ) : (<></>)}

      

      <FooterMenu />

    </Container>
  );
}
