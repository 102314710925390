import { AxiosResponse } from 'axios';
import * as addressService from '../../infrastructure/api/services/addressService';
import IUseCase from '../IUseCase';

export interface GetAllCitiesInput {
  stateId: number;
}

export default class GetAllCitiesUseCase implements IUseCase<GetAllCitiesInput, Promise<AxiosResponse>>
{
  execute(input: GetAllCitiesInput) {
    return addressService.getAllCities(input.stateId);
  }
}
