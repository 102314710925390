import {
  faAddressCard,
  faCalendar,
  faChartLine,
  faPowerOff,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { BsGraphUp } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { IoCalendarOutline, IoNotificationsOutline, IoStorefrontOutline } from "react-icons/io5";
import { MdMobileFriendly } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Establishment from '../../@core/domain/model/Establishment';
import { FeatureModel } from '../../@core/domain/ui/FeatureModel';
import {
  cleanAuthData,
  getEstablishment,
} from '../../@core/infrastructure/api/services/storageService';
import { normalizeBucketURIFallback } from '../../utils/BucketAccess';
import Feature from './feature';
import {
  BarberName,
  Container,
  Image,
  LogoEstablishmentContainer,
  LogoffText,
} from './styles';
import FooterMenu from '../../components/Menus/FooterMenu';


export default function Home() {
  const establishment = getEstablishment();
  const [expand, setExpand] = useState<Number>(-1);

  const Features: FeatureModel[] = [
    {
      name: 'Estabelecimento',
      icon: faUser,
      iconx: <IoStorefrontOutline />,
      items: [
        {
          text: 'Informações do estabelecimento',
          route: '/informacoes-do-estabelecimento',
          active: true,
        }
      ],
    },
    {
      name: 'Meu Aplicativo',
      icon: faUser,
      iconx: <MdMobileFriendly />,
      items: [
        {
          text: 'Link para meu aplicativo',
          route: '/link-meu-app',
          active: true,
        },
        {
          text: 'Descrição na tela inicial',
          route: '/informacoes-no-app',
          active: true,
        },
        {
          text: 'Imagens do carousel',
          route: '/imagens-carousel',
          active: true,
        },
        {
          text: 'Customizar',
          route: '/customizar',
          active: true,
        },
        {
          text: 'Publicar meu app na playstore',
          route: '/publicar-app-playstore',
          active: false,
        },
      ],
    },
    {
      name: 'Notificações',
      iconx: <IoNotificationsOutline />,
      items: [
        {
          text: 'Enviar notificões para meus clientes',
          route: '/enviar-notificacao',
          active: true,
        },
        {
          text: 'Histórico de notificações',
          route: '/',
          active: false,
        },
      ],
    },
    {
      name: 'Agenda',
      icon: faCalendar,
      iconx: <IoCalendarOutline />,
      items: [
        {
          text: 'Gerenciar minha agenda',
          route: '/minha-agenda',
          active: true,
        },
      ],
    },
    {
      name: 'Cadastros',
      icon: faAddressCard,
      iconx: <FaRegAddressCard />,
      items: [
        {
          text: 'Foto cliente',
          route: '/foto-meu-cliente',
          active: true,
        },
        {
          text: 'Categorias',
          route: '/categorias',
          active: true,
        },
        {
          text: 'Serviços',
          route: '/servicos',
          active: true,
        },
        {
          text: 'Colaboradores',
          route: '/colaboradores',
          active: true,
        },
        {
          text: 'Lojas',
          route: '/',
          active: false,
        },
      ],
    },
    {
      name: 'Relatórios',
      icon: faChartLine,
      iconx: <BsGraphUp />,
      items: [
        {
          text: 'Faturamento',
          route: '/faturamento',
          active: true,
        },
        {
          text: 'Painel',
          route: '/',
          active: false,
        },
        {
          text: 'Lista de Clientes',
          route: '/clientes',
          active: true,
        },
      ],
    },
  ];

  const navigate = useNavigate();
  const doLogout = useCallback(() => {
    const establishment = getEstablishment() as Establishment;
    cleanAuthData();
    navigate(`/${establishment.nameSite}/login`);
  }, []);
  return (
    <Container>
      <LogoEstablishmentContainer>
        <Image
          src={
            establishment.images.length > 0
              ? normalizeBucketURIFallback(
                'LOGO',
                establishment.images.filter(
                  (img: any) => img.type === 'LOGO'
                )[0].name
              )
              : normalizeBucketURIFallback('LOGO', undefined)
          }
        />
      </LogoEstablishmentContainer>

      <BarberName>{establishment?.name}</BarberName>

      {Features.map((feat, index) => (
        <Feature setExpand={setExpand} expand={expand} index={index} title={feat.name} icon={feat.icon} iconx={feat.iconx} items={feat.items} />
      ))}

      <LogoffText onClick={() => doLogout()}>
        <FontAwesomeIcon icon={faPowerOff} />
        <a>Sair do gerenciador</a>
      </LogoffText>
      
      <FooterMenu />

    </Container>
  );
}
