import api from '../api';
import { getEstablishmentId } from './storageService';

export const getQuantitySchedules = (
  initialInterval: string,
  endInterval: string,
  colaboratorId: number | null
) => {
  return api.get(`/analytics/schedules/quantity`, {
    params: {
      initialInterval: initialInterval,
      endInterval: endInterval,
      colaboratorId: colaboratorId
    },
    headers: {
      establishmentId: getEstablishmentId(),
    },
  });
};
