import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as associatePreviousService from '../../infrastructure/api/services/previousServiceService';

export default interface RemovePreviousServiceImageInput {
  finalClientId: number;
  previousServiceId: number;
  imageId: number;
}

export default class RemovePreviousServiceImageUseCase
  implements IUseCase<RemovePreviousServiceImageInput, Promise<any>>
{
  execute(input: RemovePreviousServiceImageInput) {
    return associatePreviousService.deletePreviousService(
      input.finalClientId,
      input.previousServiceId,
      input.imageId
    );
  }
}
