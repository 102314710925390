import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as services from '../../infrastructure/api/services/servicesService';

export default class UploadServiceImage
  implements IUseCase<FormData, Promise<any>>
{
  execute(register: FormData) {
    return services.uploadImage(register);
  }
}
