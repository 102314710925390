import {
  Container,
  Content,
  ErrorMessage,
  Select,
  Label,
  ShowPassword,
  ShowIcon,
  SelectContainer,
  IconContainer,
} from './styles';
import { useState, useCallback, ChangeEvent, ChangeEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Box, InputAdornment, SelectChangeEvent, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

interface OptionsParams {
  value?: string;
  label?: string;
}

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value: any;
  setValue: (e: React.ChangeEvent<any>) => void;
  children?: React.ReactNode;
  name?: string;
  icon?: IconDefinition;
  items?: any[];
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  defaultValue?: string;
  iconx?: JSX.Element;
}

export default function SelectBox({
  label,
  id,
  error,
  value,
  setValue,
  name,
  icon,
  iconx,
  placeholder,
  items,
  disabled,
  multiple,
  defaultValue,
  onChange,
}: TextboxParams) {
  // const handleInputChange = useCallback(
  //   (event: any) => {
  //     console.log("event", event.target)
  //     // setValue(event);

  //     // if (onChange) onChange(event);
  //   },
  //   [setValue, onChange]
  // );

  const handleChange = (event: React.ChangeEvent<any>) => {
    // setAge(event.target.value as string);
    // console.log("event", event.target.value as string)
    setValue(event);
  };

  const startAdornment = iconx ?  {
    startAdornment: (
      <IconContainer>
          <InputAdornment position="start">
          {iconx}
        </InputAdornment>
      </IconContainer>
    
    ),
  } : {};

  return (
    <Container id={`${id}-container`}>

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-select-currency"
            name={name}
            select
            disabled={disabled}
            label={label ?? placeholder}
            variant={"outlined"}
            placeholder={placeholder}
            onChange={(e: any) => handleChange(e)}
            InputProps={{
              ...startAdornment
            }}
            value={value}
            sx={{
              width: '100%',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Adiciona uma sombra
              backgroundColor: 'white',
              borderRadius: '0px', // Borda arredondada
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda do TextField
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda ao passar o mouse sobre o TextField
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda quando o TextField está focado
              },
            }}
          >
            <MenuItem value="">
              <em>Nenhuma opção</em>
            </MenuItem>
            {items?.map((option: OptionsParams) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
      </Box>

    </Container>
  );
}
