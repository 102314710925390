import moment from 'moment';
import SaveSchedule from '../model/SaveSchedule';

export default interface BlockScheduleForm {
  date: string | null;
  endBlockSchedule: string;
  title: string;
  observation: string;
  colaboratorId: number | null;
  scheduleType: string;
  paymentForm: string;
  establishmentId: number;
}
export const MapToSaveBlockSchedule = (
  form: BlockScheduleForm
): SaveSchedule => {
  return {
    date: moment(form.date).format('yyyy-MM-DDTHH:mm:ss'),
    title: form.title,
    endBlockSchedule: form.endBlockSchedule,
    observation: form.observation,
    colaboratorId: form.colaboratorId ?? 0,
    scheduleType: form.scheduleType,
    paymentForm: form.paymentForm,
    establishmentId: form.establishmentId,
  };
};
