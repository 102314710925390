import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Container,
  ErrorMessage,
  StyledTimePicker
} from './styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider, PickerChangeHandlerContext, TimeValidationError } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs'
import { addGenericYearDayAndMonthToHours, formatDate, formatDateWithHourAndMinutesOnly, formatDateWithSeconds } from '../../utils/HoursUtils';

interface TextboxParams {
  label?: string;
  id?: string;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: string;
  setValue: (e: any) => void;
  name?: string;
  showLabel?: boolean;
  icon?: any;
  iconEnd?: any;
  onClickEnd?: () => void;
  onClick?: () => void;
}

/*
  O componente a seguir possui as seguintes regras:
    - Toda entrada será no formato HH:MM
    - Toda saída será no formato HH:MM
  
  O componente MUI contudo possui o próprio formato para isso foi utilizado a função abaixo para normalizar o de HH:MM para formato MUI
    dayjs(addGenericYearDayAndMonthToHours(value))

  @Author: João Victor Pereira Teixeira
  @Date: 24/03/2024 - 16:14
*/

export default function TimePickerBox({
  label,
  id,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  icon,
  iconEnd,
  onClick,
  onClickEnd,
  showLabel
}: TextboxParams) {

  const handleChange = (event: any) => {
    const normalizedToHour = formatDateWithHourAndMinutesOnly(String(event))
    console.log("normalizedToHour", normalizedToHour )
    setValue(normalizedToHour);
  };

  return (
    <Container id={`${id}-container`}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledTimePicker
            onChange={(newValue) => handleChange(newValue)}
            value={value && value !== '' ? dayjs(addGenericYearDayAndMonthToHours(value)) : null}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Adiciona uma sombra
              borderRadius: '0px', // Borda arredondada
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda do TextField
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda ao passar o mouse sobre o TextField
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove a borda quando o TextField está focado
              },
            }}
            label={label}
            ampm={false}
          />
      </LocalizationProvider>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
    </Container>
  );
}
