import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';
import { AxiosResponse } from 'axios';

export default interface GetServicesInput {
  terms?: string;
  perPage?: number;
  page?: number;
  sort?: string;
}

export default class GetServices
  implements IUseCase<GetServicesInput, Promise<AxiosResponse<Service[]>>>
{
  execute(input?: GetServicesInput) {
    return serviceService.getServices(input);
  }
}
