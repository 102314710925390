import React from 'react';
import PrivateRoute from '../../components/PrivateRoute';
import { checkIsPublicRoute } from '../../config/routes';
import { extractPageAfterSiteName } from '../../utils/TenantUtils';
import { useLocation } from "react-router-dom";

interface PrivatePageContextWrapperProps {
  children: React.ReactNode
}

export default function PrivatePageContextWrapper({ children }: PrivatePageContextWrapperProps) {

	const location = useLocation()
  // console.log("location: ", location.pathname)
	const isPublicPage = checkIsPublicRoute(extractPageAfterSiteName(location.pathname));
  return (
    <>
			{isPublicPage && children}
			{!isPublicPage && (
				<PrivateRoute>
					{children}
				</PrivateRoute>
			)}
    </>
  )
}
