import IUseCase from '../IUseCase';
import * as themeService from '../../infrastructure/api/services/themeService';
import { AxiosResponse } from 'axios';
import UploadResponse from '../../domain/model/UploadResponse';

export default class UploadLogo
  implements IUseCase<FormData, Promise<AxiosResponse<UploadResponse>>>
{
  execute(register: FormData) {
    return themeService.uploadLogo(register);
  }
}
