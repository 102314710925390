import { AxiosResponse } from 'axios';
import * as scheduleService from '../../infrastructure/api/services/notificationService';
import IUseCase from '../IUseCase';
import Notification from '../../domain/model/Notification';

export default class CreateNotificationUseCase
  implements IUseCase<Notification, Promise<AxiosResponse>>
{
  execute(notification: Notification) {
    return scheduleService.createNotification(notification);
  }
}
