import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/establishmentService';
import Positions from '../../domain/model/Positions';


export default interface OrganizeCarouselImagesInput {
  positions: Positions;
}

export default class OrganizeCarouselImages implements IUseCase<OrganizeCarouselImagesInput, Promise<any>> {
  execute(organizeServicesInput: OrganizeCarouselImagesInput) {
    return serviceService.organizeCarouselImages(organizeServicesInput.positions);
  }
}
