import {
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FormikProvider, useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import GetAllAddressByEstablishmentId from '../../@core/application/address/getAllAddressByEstablishmentId';
import UpdateAddressUseCase from '../../@core/application/address/updateAddress';
import UpdateEstablishmentForm from '../../@core/domain/ui/UpdateEstablishmentForm';
import {
  getEstablishmentId,
  getTenantStorage
} from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import validationAddressForm from './validationAddress';
import Address from '../../@core/domain/model/Address';
import State from '../../@core/domain/model/State';
import City from '../../@core/domain/model/City';
import UpdateAddressForm from '../../@core/domain/ui/UpdateAddressForm';
import GetAllCitiesUseCase from '../../@core/application/address/getCities';
import GetStatesUseCase from '../../@core/application/address/getStates';
import SelectBox from '../../components/Selectbox';
import CreateAddressUseCase from '../../@core/application/address/createAddress';
import CreateAddress from '../../@core/domain/model/CreateAddress';


interface props {
  // initialValues: UpdateEstablishmentForm
  // callback: () => void

}
export default function EstablishmentAddressForm({
  // initialValues
  // callback
}: props) {
  const { setLoading } = useContext(LoadingContextContent);

  const showAlert = useAlert();

  const navigate = useNavigate();

  const initialService: UpdateAddressForm = {
    street: '',
    neighborhood: '',
    number: 0,
    complements: '',
    cep: '',
    city: 0,
    state: 0,
    addressType: 'ESTABLISHMENT'
  };

  const [updateEstablishmentForm, setUpdateEstablishmentForm] = useState<UpdateAddressForm>(initialService);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [isCreateAddress, setIsCreatedAddress] = useState<boolean>(false);


  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: updateEstablishmentForm,
    validationSchema: validationAddressForm,
    onSubmit: async (values: UpdateAddressForm) => {
      console.log("info", values)

      setLoading(true);

      if(isCreateAddress){
        createAddress(values)
      } else {
        updateAddress(values)
      }
     
    },
  });

  const createAddress = (values: UpdateAddressForm) => {
    const createAddressUseCase = new CreateAddressUseCase()

    const establishmentId = getEstablishmentId();

    const input = {
      ...values,
      city: values.city,
      establishmentId: establishmentId,
      addressType: "ESTABLISHMENT"
    } as CreateAddress;

    console.log("input", input)
    
    createAddressUseCase.execute(input)
      .then(res => {
        setLoading(false);
        findAddressByEstablishmentId();
        setIsCreatedAddress(false)
        showAlert.success("Endereço associado com sucesso")
      }).catch(err => {
        setLoading(false);
        if(err?.response?.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        } else {
          showAlert.error("Falha ao atualizar informações. Tente novamente")
        }
      })
  }

  const updateAddress = (values: UpdateAddressForm) => {
    const updateAddressUseCase = new UpdateAddressUseCase()

    const establishmentId = getEstablishmentId();

    const input = {
      ...values,
      city: values.city,
      establishmentId: establishmentId,
      addressType: "ESTABLISHMENT"
    } as Address;

    console.log("input", input)
    
    updateAddressUseCase.execute(input)
      .then(res => {
        setLoading(false);
        findAddressByEstablishmentId();
        showAlert.success("Endereço atualizado com sucesso")
      }).catch(err => {
        setLoading(false);
        if(err?.response?.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        } else {
          showAlert.error("Falha ao atualizar informações. Tente novamente")
        }
      })
  }



  const findAddressByEstablishmentId = useCallback(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const establishmentId = getEstablishmentId();

    const getAllAddressByEstablishmentId = new GetAllAddressByEstablishmentId();

    getAllAddressByEstablishmentId
      .execute(establishmentId)
      .then((res: any) => {
        var allAddress = res.data;
        setLoading(false);

        var address = {} as Address;

        if(allAddress.length > 0){
          address = {...allAddress[0]}
          setIsCreatedAddress(false)
        }
        
        if(allAddress.length == 0) {
          setIsCreatedAddress(true)
        }


        var updateEstablishmentForm = {
          ...address,
          city: address.city?.id,
          state: address.city?.state?.id,
        } as UpdateAddressForm;

        setUpdateEstablishmentForm(updateEstablishmentForm);

      })
      .catch((err: any) => {
        setLoading(false);
        if(err?.response?.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  },
  []
);

  const getAllStates = () => {
    const getStatesUseCase = new GetStatesUseCase();

    getStatesUseCase.execute().then(res => {
      setStates(res.data)
    }).catch(err => {
      showAlert.error('Algum erro ocorreu reinicie o aplicativo por favor.');
    })
  }

  const getCitiesByState = (stateId: number) => {
    const getAllCitiesUseCase = new GetAllCitiesUseCase();

    getAllCitiesUseCase.execute({stateId: stateId}).then((res: any) => {
      setCities(res.data)
    }).catch(err => {
      showAlert.error('Algum erro ocorreu reinicie o aplicativo por favor.');
    })
  }

  useEffect(() => {
    getAllStates();
  },[])

  useEffect(() => {
    getCitiesByState(registerForm.values.state ?? 0);
  },[registerForm.values.state])


  useEffect(() => {
    findAddressByEstablishmentId();
  }, [findAddressByEstablishmentId]);

  return (
    <FormikProvider value={registerForm}>
      <Form>
        <Textbox
          label="CEP"
          placeholder="CEP do estabelecimento"
          name="cep"
          icon={faUser}
          mask='CEP'
          value={registerForm.values.cep}
          setValue={registerForm.handleChange}
          error={registerForm.errors.cep}
        />
      
          <Textbox
            label="Logradouro"
            placeholder="Nome da rua ou avenida"
            name="street"
            value={registerForm.values.street}
            setValue={registerForm.handleChange}
            error={registerForm.errors.street}
          />

          <Textbox
            label="Número"
            placeholder="Número, identificação do estabelecimento"
            name="number"
            type="number"
            value={registerForm.values.number}
            setValue={registerForm.handleChange}
            error={registerForm.errors.number}
          />

          <Textbox
            label="Bairro"
            placeholder="Bairro do estabelecimento"
            name="neighborhood"
            value={registerForm.values.neighborhood}
            setValue={registerForm.handleChange}
            error={registerForm.errors.neighborhood}
          />

          <SelectBox
            label="Estado *"
            placeholder="Escolha o estado"
            name="state"
            value={registerForm.values.state}
            setValue={registerForm.handleChange}
            error={registerForm.errors.state}
            items={states.map((state) => {
              return { value: state.id, label: state.UF };
            })}
          />

          <SelectBox
            label="Cidade *"
            placeholder="Escolha a cidade"
            name="city"
            value={registerForm.values.city}
            setValue={registerForm.handleChange}
            error={registerForm.errors.city}
            items={cities.map((city) => {
              return { value: city.id, label: city.name };
            })}
          />

        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Salvar
        </Button>
      </Form>
    </FormikProvider>
  );
}
