import {
  faArrowTrendUp,
  faHistory,
  faImages,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FinalClient from '../../@core/domain/model/FinalClient';
import {
  Action,
  ActionText,
  ActionsContainer,
  Age,
  Container,
  Content,
  CustomerImage,
  Information,
  LeftSide,
  Name,
  RightSide,
  Soon,
} from './style';
import moment from 'moment';
import { normalizeBucketURI, normalizeBucketURIFallback } from '../../utils/BucketAccess';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/pt-br';

interface CustomerCardParams {
  onClick?: () => void;
  customer: FinalClient;
}

const calculateAge = (finalClient: FinalClient) => {
  const today = new Date();
  const birthDateObj = new Date(finalClient.birthDate);

  let age = today.getFullYear() - birthDateObj.getFullYear();

  // Adjust age based on the birthdate month and day
  if (
    today.getMonth() < birthDateObj.getMonth() ||
    (today.getMonth() === birthDateObj.getMonth() &&
      today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};

const formatDate = (createdAt: string): string => {
  const formattedDate = moment(createdAt).locale('pt-br').format('MMMM/YY');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export default function CustomerCard({ customer }: CustomerCardParams) {
  const openWhatsApp = (customer: FinalClient) => {
    const tel = customer.telephone
      .trim()
      .replace(/ /g, '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
    console.log(tel);
    const whatsappURL = `https://wa.me/${tel}`;
    window.open(whatsappURL, '_blank');
  };

  const navigate = useNavigate();


  return (
    <Container>
      <LeftSide>
        <CustomerImage src={normalizeBucketURIFallback("FINAL_CLIENT", customer?.image?.name)} />
      </LeftSide>
      <RightSide>
        <Name>{customer.name}</Name>
        <Age>
          {calculateAge(customer)} anos, entrou em{' '}
          {formatDate(customer.createdAt)}
        </Age>
        <ActionsContainer>
          <Action
            onClick={() => {
              openWhatsApp(customer);
            }}
          >
            <FontAwesomeIcon icon={faPhone} color="grey" />
            <ActionText disable={false}>Whatsapp</ActionText>
          </Action>
          <Action>
            <FontAwesomeIcon icon={faImages} color="#ccc" />
            <ActionText disable={true}>Galeria</ActionText>
          </Action>
          <Action>
            <FontAwesomeIcon icon={faHistory} color="#ccc" />
            <ActionText disable={true}>Histórico</ActionText>
          </Action>
          <Action>
            <FontAwesomeIcon icon={faArrowTrendUp} color="#ccc" />
            <ActionText disable={true}>Métricas</ActionText>
          </Action>
        </ActionsContainer>
      </RightSide>
    </Container>
  );
}
