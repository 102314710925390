const theme = {
  color: {
    primary: '#5D17EB',
    light: '#FFFFFF',
    dark: '#191919',
    gray: '#808080',
    borderUnfocused: '#C1C1C1',
    error: '#EB4C4C',
    backLoading: '#C1C1C1',
    darkInput: '#808080',
    orange: '#EA7000',
    darkGray: '#262626',
    red: '#C91416',
    menuItemBackground: '#5D17EB',
    selectInput: '#E6E6E6',
    selectInputColor: '#C91416',
    white: '#fff',
    mainBorder: '#EC6453',
    modalBody: '#262626',
    modalCloseButton: '#737373',
    grayButtonColor: '#E9E9E9',
    inputEndIconColor: '#737373',
  },
  dashTitle: '#3F3D56',
  input: '#fff',
  inputColor: '#737373',
  font: {
    archivo: `font-family: 'Roboto', sans-serif;`,
  },
};

export default theme;
