import styled from 'styled-components';
import { Link } from 'react-router-dom';
import background from '../../assets/images/background_register.png';


export const Content = styled.div`
  display: flex;
  padding: 1em;
  padding-top: 6em;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding-top: 3em;
    background-size: cover; 
    background-image: url(${background});
    background-position: center;
  }
`;

export const Bee8bBaseboardContainer = styled.div`
  display: flex;
  padding-top: 3em;
  padding-bottom: 3em;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;


export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

//TODO: Unificar todos headers e outros estilos em apenas um lugar
export const Header = styled.h1`
  color: ${(props) => props.theme.color.dark};
  font-size: 2.2em;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 2.2em;
    font-family: 'Sedan SC';
    font-weight: lighter;
  }
`;

export const Bee8bBaseboardTitle = styled.h1`
  color: ${(props) => props.theme.color.primary};
  font-size: 2.2em;

  @media only screen and (max-width: 768px) {
    font-size: 1.8em;
  }
`;

export const Bee8bBaseboardSubtitle = styled.h1`
  color: ${(props) => props.theme.color.gray};
  opacity: 0.8;
  @media only screen and (max-width: 768px) {
    font-size: 1.2em;
    opacity: 0.5;
  }
`;


export const Subheader = styled.span`
  color: ${(props) => props.theme.color.gray};
  font-size: 1em;
  letter-spacing: 1px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding:1em;
  margin-top: 2em;
`;

export const Partition = styled.div`
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const NextButton = styled.button`
  color: ${(props) => props.theme.color.light};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: ${(props) => props.theme.color.primary};
  outline: none;
  border: 2px solid ${(props) => props.theme.color.primary};
  padding: 0.8em;
  font-weight: bold;
  font-size: 2em;
  gap: 0.5em;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  box-shadow: 0 4px 8px 0 rgba(93, 23, 235, 1);
  margin-top: 30px;

  @media only screen and (max-width: 768px) {
    font-size: 1.6em;
  }
`;

export const ForgetPassword = styled(Link)`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.primary};
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    font-weight: 500;
  }
`;

export const DoNotHaveAccount = styled.span`
  width: 100%;
  text-align: center;
  padding-top: 10m;
  color: ${(props) => props.theme.color.dark};
  letter-spacing: 1px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
  }
`;

export const DoNotHaveAccountLink = styled(Link)`
  color: ${(props) => props.theme.color.primary};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    font-weight: 500;
  }
`;

export const IconContainer= styled.div`
  font-size: 1.5em; 
`;

export const Divider = styled.div`
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1px; /* Define a altura da linha */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ccc 10%, #ccc 90%, rgba(255, 255, 255, 0)); /* Gradiente nos lados esquerdo e direito */

  /* Adiciona uma sombra para destacar a linha */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;

export const ContainerGif = styled.div`
  height: 50vh;
`;

export const PreviewLabel = styled.div`
  font-weight: bold;
  font-size: 0.8em;
`;

export const PreviewLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.2em;
  gap: 0.5em;
`;

export const PreviewLinkContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Adiciona borda redonda */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra clara */
  border: 1px solid #ccc; /* Adiciona uma borda cinza fina */
  padding: 1em;
`;


export const PreviewLinkBaseText = styled.span`
  font-weight: bold;
  font-size: 1em; /* Aumente ou diminua conforme necessário */
  text-align: center; 
`;

export const PreviewLinkOtherText = styled.span`
  color: white;
  font-weight: bold;
  font-size: 1em; /* Aumente ou diminua conforme necessário */
  background-color: rgba(93, 23, 235, 0.8); /* Cor com 80% de opacidade */
  border-radius: 5px; /* Valor pode ser ajustado conforme desejado */
  padding: 5px; /* Adiciona um pouco de espaço interno para melhorar a aparência */
`;
