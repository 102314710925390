import Colaborator from '../../domain/model/Colaborator';
import IUseCase from '../IUseCase';
import * as previousService from '../../infrastructure/api/services/previousServiceService';
import { PreviousService } from '../../domain/model/PreviousService';
import { AxiosResponse } from 'axios';

export default interface GetPreviousServiceInput {
  bookedTimeId: number;
  serviceId?: number;
  finalClientId: number;
}

export default class GetPreviousServiceUseCase
  implements IUseCase<GetPreviousServiceInput, Promise<AxiosResponse<PreviousService[]>>>
{
  execute(input: GetPreviousServiceInput) {
    return previousService.getPreviousService(
      input.finalClientId,
      input.serviceId,
      input.bookedTimeId
    );
  }
}
