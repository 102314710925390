import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const validationServiceForm = Yup.object().shape({
  name: Yup.string().max(200, dictionary.global.validation.maxLength.replace("{0}", "200")).required(dictionary.global.validation.required),
  telephone: Yup.string().required(dictionary.global.validation.required),
  establishmentType: Yup.string().required(dictionary.global.validation.required),
  startWork: Yup.string().required(dictionary.global.validation.required),
  endWork: Yup.string().required(dictionary.global.validation.required),
});

export default validationServiceForm;
