import {
  useCallback,
  useEffect,
  useState
} from 'react';

import moment, { weekdays } from 'moment';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import { checkUserAuthenticated } from '../../components/PrivateRoute';

import {
  faCalendar,
  faComment,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import AddScheduleCase from '../../@core/application/schedule/addScheduleCase';
import BlockScheduleForm, {
  MapToSaveBlockSchedule,
} from '../../@core/domain/ui/BlockScheduleForm';
import { getEstablishmentId } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DateTimePickerBox from '../../components/DateTimePicker';
import { Form } from '../../components/Form/style';
import SelectBox from '../../components/Selectbox';
import Textbox from '../../components/TextboxMUI';
import BlockScheduleValidation from './blockScheduleValidation';



import { ProgressSpinner } from 'primereact/progressspinner';
import { FaArrowLeftLong } from 'react-icons/fa6';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import Colaborator from '../../@core/domain/model/Colaborator';
import {
  Content,
  Header,
  HeaderTitle,
  IconContainer,
  RecurrenceSelectContainer
} from './styles';
import RecurrenceSelect from '../../components/RecurrenceSelect';
import CreateRecurringAppointmentUseCase from '../../@core/application/schedule/createRecurringAppointmentUseCase';
import BlockRecurrenceScheduleForm, { MapToSaveRecurrenceBlockSchedule } from '../../@core/domain/ui/BlockRecurrenceScheduleForm';
import TimePickerBox from '../../components/TimePickerBox';

export default function RecurrenceBlockSchedule() {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [ isLoading, setLoading ] = useState(false);
  const [ time, setTime ] = useState<string[]>([]);



  const [blockScheduleForm, setBlockScheduleForm] = useState<BlockRecurrenceScheduleForm>(
    {
      title: "",
      initialTime: "",
      endTime: "",
      weekDays: [],
      colaboratorId: undefined
    }
  );

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: BlockScheduleValidation,
    initialValues: blockScheduleForm,

    onSubmit: (values: BlockRecurrenceScheduleForm) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const saveSchedule = MapToSaveRecurrenceBlockSchedule(values);
      saveSchedule.weekDays = time;

      const startDate = moment(saveSchedule.initialTime);
      const endTime = moment(saveSchedule.endTime);

      if (endTime.isBefore(startDate)) {
        form.setErrors({ endTime: 'A data de término deve ser posterior à data de início.' });
        return;
      }

      const createRecurringAppointmentUseCase = new CreateRecurringAppointmentUseCase();
      setLoading(true);
      createRecurringAppointmentUseCase
        .execute(saveSchedule)
        .then(() => {
          setLoading(false);
          showAlert.success('Agenda bloqueada com sucesso!');
          navigate(`/minha-agenda`)
        })
        .catch((err) => {
          setLoading(false);
          showAlert.error(err.response.data.erros[0]);
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
  });

  const mapWeekDays = (time: string[]) : string[] => {
    return []
  }

  useEffect(() => {
    console.log("time", time)
  }, [time])
  
  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetColaborator();
    getUseCase.execute().then((response: any) => {
      setColaborators(response.data);
    }).catch((err: any) => {
      console.log("Algum erro ocorreu na obtenção dos colaboradores", err)
    });
  }, []);

  useEffect(() => {
    findColaborators();
  }, []);

  return (
    <Content>
      <Header>
        <IconContainer>
          <FaArrowLeftLong onClick={() => {
            navigate(`/minha-agenda`)
          }}/>
        </IconContainer>
        <HeaderTitle>BLOQUEAR AGENDA</HeaderTitle>
      </Header>
       <Form>
          <RecurrenceSelectContainer>
            <RecurrenceSelect time={time} callbackTime={setTime} errorRequiredTime={false}/>
          </RecurrenceSelectContainer>

          <TimePickerBox
            label="Horário inicio"
            name="initialTime"
            showLabel={true}
            type="time"
            value={form.values.initialTime}
            setValue={(newValue: string) => {form.setFieldValue("initialTime", newValue)}}
            error={form.errors.initialTime}
          />

          <TimePickerBox
            label="Horário fim"
            name="endTime"
            showLabel={true}
            type="time"
            value={form.values.endTime}
            setValue={(newValue: string) => {form.setFieldValue("endTime", newValue)}}
            error={form.errors.endTime}
          />

          <Textbox
            placeholder="Título compromisso"
            name="title"
            icon={faLayerGroup}
            value={form.values.title}
            setValue={form.handleChange}
            error={form.errors.title}
          />

          <SelectBox
            placeholder="Colaborador"
            name="colaboratorId"
            value={form.values.colaboratorId}
            setValue={form.handleChange}
            icon={faLayerGroup}
            error={form.errors.colaboratorId}
            items={colaborators.map((colaborator) => {
              return { value: colaborator.id, label: colaborator.name };
            })}
          />

          <Button colorType="primary" onClick={() => form.submitForm()}>
            {isLoading ? <ProgressSpinner style={{width: '30px', height: '30px'}}/> : "Bloquear agenda"} 
          </Button>
        </Form>
    </Content>
  );
}
