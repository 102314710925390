import { AxiosResponse } from 'axios';
import SaveSchedule from '../../domain/model/SaveSchedule';
import IUseCase from '../IUseCase';
import Establishment from '../../domain/model/Establishment';
import * as establishmentService from '../../infrastructure/api/services/establishmentService';

export default interface DeleteEstablishmentImageInput {
  establishmentId: number;
  imageId: number;
}

export default class DeleteEstablishmentImageUseCase
  implements IUseCase<DeleteEstablishmentImageInput, Promise<AxiosResponse>>
{
  execute(deleteEstablishmentImageInput: DeleteEstablishmentImageInput) {
    return establishmentService.deleteImage(deleteEstablishmentImageInput.establishmentId, deleteEstablishmentImageInput.imageId);
  }
}
