import Category from '../../../domain/model/Category';
import RegisterCategory from '../../../domain/model/RegisterCategory';
import api from '../api';
import { getEstablishmentId } from './storageService';

export const createCategory = (register: RegisterCategory) => {
  return api.post<RegisterCategory>(
    `/establishments/${register.establishmentId}/categories`,
    register
  );
};

export const getCategories = () => {
  return api.get<Category[]>(
    `/establishments/${getEstablishmentId()}/categories`
  );
};

export const deleteCategory = (categoryId: string) => {
  return api.delete<void>(
    `/establishments/${getEstablishmentId()}/categories/${categoryId}`,
    {
      data: {},
    }
  );
};
