import { AxiosResponse } from 'axios';
import SaveSchedule from '../../domain/model/SaveSchedule';
import IUseCase from '../IUseCase';
import Establishment from '../../domain/model/Establishment';
import * as establishmentService from '../../infrastructure/api/services/establishmentService';
export default class GetEstablishmentBySite
  implements IUseCase<string, Promise<AxiosResponse<Establishment>>>
{
  execute(siteName: string) {
    return establishmentService.getEstablishmentBySite(siteName);
  }
}
