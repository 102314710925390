import {
  faClock,
  faDollar,
  faFileText,
  faLayerGroup,
  faPeopleGroup,
  faUsers,
  faVenusMars
} from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useAlert } from 'react-alert';
import { Option } from 'react-multi-select-component';
import { useNavigate } from 'react-router-dom';
import GetCategories from '../../@core/application/category/getCategories';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import RegisterService from '../../@core/application/services/registerService';
import UploadServiceImage from '../../@core/application/services/uploadServiceImage';
import Category from '../../@core/domain/model/Category';
import Colaborator from '../../@core/domain/model/Colaborator';
import ErrorApi from '../../@core/domain/model/ErrorApi';
import Service from '../../@core/domain/model/Service';
import ImageGallery from '../../@core/domain/ui/ImageGallery';
import RegisterServiceForm, {
  MapToService,
} from '../../@core/domain/ui/RegisterServiceForm';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import { GalleryImages } from '../../components/Gallery';
import MultipleSelect from '../../components/MultipleSelect';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import SelectBox from '../../components/Selectbox';
import Textarea from '../../components/Textarea';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Container,
  Header,
  HeaderContainer,
  IconContainer,
  Instructiontext
} from './styles';
import validationServiceForm from './validation';
import FooterMenu from '../../components/Menus/FooterMenu';
import { MdOutlineAttachMoney } from "react-icons/md";
import { DragGallery } from '../../components/DragGallery';
import Positions, { Position } from '../../@core/domain/model/Positions';
import OrganizeServicesInput from '../../@core/application/services/organizeServiceImages';
import OrganizeServiceImages from '../../@core/application/services/organizeServiceImages'

const initialService: RegisterServiceForm = {
  name: '',
  description: '',
  collaboratorsOptions: [],
  price: 0,
  genre: '',
  duration: '',
  ageGroup: '',
  categoryId: 0,
  specializationService: false,
};
export default function ServiceCreate() {
  const [serviceForm, setServiceForm] =
    useState<RegisterServiceForm>(initialService);
  const [services, setServices] = useState([] as Service[]);
  const [uploadImage, setUploadImage] = useState<File>();

  const [newImages, setNewImages] = useState<ImageGallery[]>([]);
  const [currentImages, setCurrentImages] = useState<ImageGallery[]>([]);
  const [removedImages, setRemovedImages] = useState<ImageGallery[]>([]);
  const [images, setImages] = useState<ImageGallery[]>([]);

  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [categories, setCategories] = useState([] as Category[]);
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  const navigate = useNavigate();

  const reset = useCallback(() => {
    setServiceForm(initialService);
    setUploadImage(undefined);
  }, []);

  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: serviceForm,
    validationSchema: validationServiceForm,
    onSubmit: (values: RegisterServiceForm) => {
      const service = MapToService(values);
      createService(service, newImages);
    },
  });

  const findCategories = useCallback(() => {

    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getCategoriesUseCase = new GetCategories();
    getCategoriesUseCase
      .execute()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
        console.log('Erro ao requisitar categorias', err);
      });
  }, []);

  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getColaboratorsUseCase = new GetColaborator();
    getColaboratorsUseCase
      .execute()
      .then((response: any) => {
        setColaborators(response.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
        console.log('Erro ao recuperar colaboradores');
      });
  }, []);

  useEffect(() => {
    findColaborators();
    findCategories();
  }, []);

  const uploadServiceImage = useCallback(
    async (images: ImageGallery[], serviceId: number) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      if (images.length == 0) return;

      const uploadImage = new UploadServiceImage();

      const formData = new FormData();
      formData.append('establishmentId', getEstablishmentId() ?? '');
      formData.append('serviceId', serviceId.toString());

      images.forEach((img) => {
        formData.append('fileImages', img.file || '');
        formData.append('position', img.position?.toString() || '');
      });

      return await uploadImage
        .execute(formData)
        .then((res) => { 
          return res.data
        })
        .catch((err) => {
          alert('Erro ao fazer upload da imagem');
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    []
  );

  const updatePositions = useCallback(async (serviceId: any, uploadedImages: any) => {

    console.log("imagesPositions", images)
    
    const positions = images.map((position: any, index) => {
        let id = 0;
        
        if(position.isNew){
          console.log("position nova", position)
          const uploadedImage = uploadedImages.filter((uploadedImage: any) => uploadedImage.imageValue === position.valueImage)[0]
          console.log("uploadedImage", uploadedImage)

          id = uploadedImage?.storageResult?.filesSuccessfully?.length > 0 ? uploadedImage.storageResult.filesSuccessfully[0].id : undefined;
          console.log("idGerado", id)
        } else {
          id = position.id; 
        }

        return {
          id: id,
          position: index
        } as Position
    });
    
    const organizeServiceImagesUseCase = new OrganizeServiceImages();

    await organizeServiceImagesUseCase.execute({
      serviceId,
      positions: {
        positions: positions
      } as Positions
    } as OrganizeServicesInput).then(res => {
    })
  }, [images])

  const createService = useCallback(
    (register: Service, images: ImageGallery[]) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const registerService = new RegisterService();
      setLoading(true);

      registerService
        .execute(register)
        .then(async (response) => {
          const uploadedImages = [] as any[];

          console.log("newImages", newImages)

          for (let i = 0; i < newImages.length; i++) {
            let newImageIndex = 0
            
            //Obter posição
            for (let j = 0; j < images.length; j++) {
              if(images[j].valueImage == newImages[i].valueImage){
                newImageIndex = j
              }
            }
            
            //Realizar upload da imagem
            const storageResult = await uploadServiceImage([{
              ...newImages[i],
              position: newImageIndex
            }], response.data.id!);

            //Salvar resultado com ID da imagem
            uploadedImages.push({
              ...storageResult,
              imageValue: newImages[i].valueImage
            });
          }

          await updatePositions(response.data.id, uploadedImages)
          
          navigate(`/servicos`);
          showAlert.success('Serviço cadastrado com sucesso');
          setLoading(false);
          reset();
        })
        .catch((errorResponse: AxiosError<ErrorApi>) => {
          errorResponse.response?.data.erros.forEach((error) => {
            showAlert.error(error);
          });

          if (errorResponse?.response?.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
          setLoading(false);
        });
    },
    [setLoading, navigate, newImages, updatePositions, showAlert, reset, uploadServiceImage]
  );


  useEffect(() => {
    setImages([
      ...currentImages.sort((a: any, b: any) => a.position - b.position),
      ...newImages,
    ])
  }, [currentImages, newImages])


  const validateImage = (image: File) => {
    const MAX_FILE_SIZE_MB = 8;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    console.log("tamanho imagem: ", image.size)
    console.log("MAX_FILE_SIZE_BYTES: ", MAX_FILE_SIZE_BYTES)

    if (image.size > MAX_FILE_SIZE_BYTES) {
      showAlert.error('Imagem excede o tamanho máximo de 8mb.');
      return false;
    }

    return true
  };


  const addImageCallback = useCallback(
    (newImageValue: string | ArrayBuffer | null, file: File) => {
      if (validateImage(file)) {
        let newImg = {
          id: Math.floor(Math.random() * 1000000),
          valueImage: newImageValue,
          isNew: true,
          file,
        } as ImageGallery;
        setNewImages((prevImages) => [...prevImages, newImg]);
      }
    },
    [setNewImages]
  );

  const removeImageCallback = useCallback(
    (image: ImageGallery) => {
      if (image.isNew) {
        setNewImages((prevImages) =>
          prevImages.filter((img) => img.id !== image.id)
        );
      }

      if (!image.isNew) {
        setCurrentImages((prevImages) =>
          prevImages.filter((img) => img.id !== image.id)
        );
        setRemovedImages((prevImages) => [...prevImages, image]);
      }
    },
    [setNewImages]
  );

  return (
    <Container>
      <HeaderContainer>
        <Header>Serviços</Header>
        <Instructiontext>Criação de novo serviço</Instructiontext>
      </HeaderContainer>


      <DragGallery
          images={images}
          imagesPositionsCallback={setImages}
          addImageCallback={addImageCallback}
          removeImageCallback={removeImageCallback}
        />

      <Form>
        <Textbox
          placeholder="Nome do serviço"
          name="name"
          icon={faFileText}
          value={registerForm.values.name}
          setValue={registerForm.handleChange}
          error={registerForm.errors.name}
        />

        <Textarea
          placeholder="Descrição do serviço"
          name="description"
          value={registerForm.values.description}
          setValue={registerForm.handleChange}
          error={registerForm.errors.description}
          icon={faFileText}
          limit={255}
        />

        <Textbox
          placeholder="Preço do serviço"
          name="price"
          type="number"
          icon={faDollar}
          value={registerForm.values.price}
          setValue={registerForm.handleChange}
          error={registerForm.errors.price}
          iconx={<IconContainer>
            <MdOutlineAttachMoney />
          </IconContainer>}
        />

        <Textbox
          placeholder="Duração do serviço (minutos)"
          name="duration"
          type="number"
          icon={faClock}
          value={registerForm.values.duration}
          setValue={registerForm.handleChange}
          error={registerForm.errors.duration}
        />

        <SelectBox
          placeholder="Categoria do serviço"
          name="categoryId"
          value={registerForm.values.categoryId}
          setValue={registerForm.handleChange}
          icon={faLayerGroup}
          error={registerForm.errors.categoryId}
          items={
            categories.map((category) => {
              return {
                value: category.id,
                label: category.category
              }
            })
          }
        />

        <MultipleSelect
          label="Escolha os colaboradores"
          name="collaboratorsOptions"
          value={registerForm.values.collaboratorsOptions}
          setValue={(options: Option[]) =>
            registerForm.setFieldValue(
              'collaboratorsOptions',
              options.map((option) => {
                return { value: option.value, label: option.label };
              })
            )
          }
          icon={faUsers}
          options={colaborators.map((colaborator) => {
            return { value: colaborator.id, label: colaborator.name };
          })}
        />

        <SelectBox
          placeholder="Genero"
          name="genre"
          value={registerForm.values.genre}
          setValue={registerForm.handleChange}
          error={registerForm.errors.genre}
          items={[
            {
              "value": "MASCULINE",
              "label": "Masculino"
            },
            {
              "value": "FEMALE",
              "label": "Feminino"
            },
            {
              "value": "NON_BINARY",
              "label": "Não-Binário"
            },
            {
              "value": "AGENDER",
              "label": "Agênero"
            },
            {
              "value": "BIGENDER",
              "label": "Bigênero"
            },
            {
              "value": "OTHERS",
              "label": "Outros"
            },
            {
              "value": "ALL_GENRE",
              "label": "Todos"
            }
        ]}
        />

        <SelectBox
          placeholder="Faixa etária"
          name="ageGroup"
          value={registerForm.values.ageGroup}
          setValue={registerForm.handleChange}
          icon={faPeopleGroup}
          error={registerForm.errors.ageGroup}
          items={[
            {
              value: "ADULT",
              label: "Adulto"
            },
            {
              value: "ADOLESCENT",
              label: "Adolescente"
            },
            {
              value: "KID",
              label: "Criança"
            },
            {
              value: "ALL",
              label: "Todos"
            }
          ]}
        />

        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Criar
        </Button>
      </Form>
      <FooterMenu />

    </Container>
  );
}
