import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ContainerDialogModal } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface DialogModalParams {
  isOpen: boolean;
  setIsOpen: any;
  message: string;
  callback: any;
  callbackReject: any;
  title: string;
}


export default function DialogModal({ isOpen, message, callback, setIsOpen, title, callbackReject }: DialogModalParams) {

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ContainerDialogModal>
      <React.Fragment>
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClose();
              callbackReject()
            }}>Voltar</Button>

            <Button onClick={() => {
              handleClose();
              callback()
            }}>Continuar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </ContainerDialogModal>
    
  );
}
