import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { AiOutlineMail } from 'react-icons/ai';
import { FaFont } from 'react-icons/fa';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import GetEstablishmentBySite from '../../@core/application/establishment/getEstablishmentBySite';
import ChangeUseCase from '../../@core/application/oauth/changeUseCase';
import ForgotUseCase from '../../@core/application/oauth/forgotUseCase';
import ErrorApi from '../../@core/domain/model/ErrorApi';
import ChangePasswordForm from '../../@core/domain/ui/ChangePasswordForm';
import ForgotPasswordForm from '../../@core/domain/ui/ForgotPasswordForm';
import { setEstablishment } from '../../@core/infrastructure/api/services/storageService';
import fromBee8b from '../../assets/images/from_BEE8B.png';
import Textbox from '../../components/TextboxMUI';
import TextboxPassword from '../../components/TextboxPasswordMUI';
import dictionary from '../../config/dictionary';
import LoadingContextContent from '../../contexts/LoadingContext';
import { generateMessages } from '../../utils/ResponseUtils';
import {
  Bee8bBaseboardContainer,
  Content,
  Form,
  Header,
  HeaderContainer,
  Image,
  Subheader,
} from '../login/styles';
import { SubmitButton } from './styles';
import validationForgot from './validation';
import validationChange from './validationChange';

export default function ForgotPassword() {
  const showAlert = useAlert();
  const { setLoading } = useContext(LoadingContextContent);
  const { nameSite } = useParams();
  const [showChangeForm, setShowChangeForm] = useState(false);
  const navigate = useNavigate();

  const enableLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const disableLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const formState: ForgotPasswordForm = {
    login: '',
  };

  const forgotPasswordForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: validationForgot,
    onSubmit: (value: ForgotPasswordForm) => {
      const forgotCase = new ForgotUseCase();
      enableLoading();
      forgotCase
        .execute({
          login: value.login,
          loginType: 'ESTABLISHMENT',
        })
        .then(() => {
          showAlert.success('Codígo para redefinição de senha enviado para o email!');
          disableLoading();
          setShowChangeForm(true);
        })
        .catch((responseError: AxiosError<ErrorApi>) => {
          disableLoading();
          showAlert.error(
            generateMessages(responseError.response?.data as ErrorApi)
          );
        });
    },
  });

  const formChangeState: ChangePasswordForm = {
    code: null,
    newPassword: '',
    confirmNewPassword: '',
  };
  const changePasswordForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: formChangeState,
    validationSchema: validationChange,
    onSubmit: (values: ChangePasswordForm) => {
      const changeCase = new ChangeUseCase();
      enableLoading();
      changeCase
        .execute({
          code: values.code || "0",
          newPassword: values.newPassword,
        })
        .then(() => {
          disableLoading();
          showAlert.success('Senha trocada com sucesso!');
          navigate(`/${nameSite}/login`);
        })
        .catch((responseError: AxiosError<ErrorApi>) => {
          disableLoading();
          showAlert.error(
            generateMessages(responseError.response?.data as ErrorApi)
          );
        });
    },
  });

  useEffect(() => {
    const getEstablishment = new GetEstablishmentBySite();

    getEstablishment
      .execute(nameSite as string)
      .then((response) => {
        const establishment = response.data;
        setEstablishment(JSON.stringify(establishment));
      })
      .catch(() => {});
  }, [nameSite]);

  return (
    <Content>
      <HeaderContainer>
        <Header>Esqueci minha senha</Header>
        <Subheader>
          {showChangeForm
            ? dictionary.forgotPassword.codeText
            : dictionary.forgotPassword.emailText}
        </Subheader>
      </HeaderContainer>

      {!showChangeForm && (
        <Form>
          <Textbox
            label={''}
            name="login"
            type="email"
            placeholder={dictionary.login.placeholderEmail}
            value={forgotPasswordForm.values.login}
            setValue={forgotPasswordForm.handleChange}
            error={forgotPasswordForm.errors.login}
            iconx={<AiOutlineMail />}
          />
          <SubmitButton
            type="button"
            onClick={() => forgotPasswordForm.submitForm()}
          >
            Recuperar senha
          </SubmitButton>
        </Form>
      )}

      {showChangeForm && (
        <Form>
          <Textbox
            label={dictionary.forgotPassword.code}
            name="code"
            placeholder={dictionary.forgotPassword.codeText}
            value={changePasswordForm.values.code}
            setValue={changePasswordForm.handleChange}
            error={changePasswordForm.errors.code}
            iconx={<FaFont />}
          />
          <TextboxPassword
            label={''}
            name="newPassword"
            type="password"
            placeholder={dictionary.forgotPassword.newPassword}
            isPassword
            value={changePasswordForm.values.newPassword}
            setValue={changePasswordForm.handleChange}
            error={changePasswordForm.errors.newPassword}
            iconx={<RiLockPasswordLine />}
          />
          <TextboxPassword
            label={''}
            name="confirmNewPassword"
            type="password"
            placeholder={dictionary.forgotPassword.confirmNewPassword}
            isPassword
            value={changePasswordForm.values.confirmNewPassword}
            setValue={changePasswordForm.handleChange}
            error={changePasswordForm.errors.confirmNewPassword}
            iconx={<RiLockPasswordLine />}
          />
          
          <SubmitButton
            type="button"
            onClick={() => changePasswordForm.submitForm()}
          >
            Alterar senha
          </SubmitButton>
        </Form>
      )}
      <Bee8bBaseboardContainer>
        <Image src={fromBee8b} />
      </Bee8bBaseboardContainer>
    </Content>
  );
}
