import { faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import CreateCategory from '../../@core/application/category/createCategory';
import DeleteCategory from '../../@core/application/category/deleteCategory';
import GetCategories from '../../@core/application/category/getCategories';
import Category from '../../@core/domain/model/Category';
import CategoryForm, {
  MapToRegister,
} from '../../@core/domain/ui/CategoryForm';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DialogModal from '../../components/DialogModal';
import { Form } from '../../components/Form/style';
import ItemList from '../../components/ItemList';
import PlusButton from '../../components/PlusButton';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  ButtonContainer,
  CategoryWrapper,
  FormScroll,
  Header,
  HeaderContainer,
  Instructiontext,
} from './styles';
import validationRegister from './validation';
import FooterMenu from '../../components/Menus/FooterMenu';

export default function Categories() {
  const [categoryForm, setCategoryForm] = useState<CategoryForm>({
    category: '',
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [dialogModal, setDialogModal] = useState<boolean>(false);

  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const showAlert = useAlert();

  const { setLoading } = useContext(LoadingContextContent);

  const navigate = useNavigate();

  const closeLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const openLoad = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const categoriesForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: categoryForm,
    validationSchema: validationRegister,
    onSubmit: (values: CategoryForm) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const registerCategory = MapToRegister(values);

      const createCategory = new CreateCategory();
      openLoad();
      createCategory
        .execute(registerCategory)
        .then((response) => {
          reset();
          closeLoad();
        })
        .catch((err) => {
          closeLoad();
          showAlert.error(err.response.data[0]);
          if(err.response.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
  });

  const retrieveCategories = useCallback(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getCategories = new GetCategories();
    openLoad();
    getCategories
      .execute()
      .then((response) => {
        setCategories(response.data);
        closeLoad();
      })
      .catch((err) => {
        closeLoad();
        if(err.response.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, [closeLoad, openLoad]);

  useEffect(() => {
    retrieveCategories();
  }, [retrieveCategories]);

  const reset = useCallback(() => {
    retrieveCategories();
    setDisableSave(true);
    setSelectedCategory(undefined);
    setShowConfirmationModal(false);
    categoriesForm.resetForm();
  }, [retrieveCategories]);

  const removeCategory = useCallback(
    (category: Category) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const deleteCategory = new DeleteCategory();
      openLoad();
      deleteCategory
        .execute(category.id)
        .then(() => {
          reset();
          closeLoad();
        })
        .catch((err) => {
          closeLoad();
          err.response?.data?.erros?.forEach((err: string) => {
            showAlert.error(err)
          })
          
          if(err.response.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    [reset]
  );

  return (
    <CategoryWrapper>
      <HeaderContainer>
        <Header>Categorias</Header>
        <Instructiontext>
          Gerencie suas categorias de serviço.
        </Instructiontext>
      </HeaderContainer>

      <Form>
        <FormScroll>
          {categories
            .slice()
            .reverse()
            .map((category) => (
              <ItemList
                value={category.category.length >= 18 ? category.category.substring(0, 18) + "..." : category.category}
                type="text"
                icon={faList}
                iconEnd={faTrash}
                onClickEnd={() => {
                  setSelectedCategory(category);
                  setDialogModal(true)
                }}
                // variant="filled"
                disabled
              />
            ))}
        </FormScroll>

        {!disableSave && (
          <Textbox
            name="category"
            placeholder="Nome da categoria"
            setValue={categoriesForm.handleChange}
            error={categoriesForm.errors.category}
            type="text"
            icon={faList}
          />
        )}
        {disableSave && (
          <PlusButton
            onClick={() => {
              setDisableSave(false);
            }}
          >
            Adicionar categoria
          </PlusButton>
        )}

        <ButtonContainer>
          <Button
            colorType="primary"
            disabled={disableSave}
            onClick={() => categoriesForm.submitForm()}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Form>

      {dialogModal ? (
        <DialogModal isOpen={dialogModal}
        setIsOpen={setDialogModal}
        title={"IMPORTANTE"}
        message={"Ao excluir uma categoria, não será possível recupera-la."}
        callback={() => removeCategory(selectedCategory as Category)}
        callbackReject={() => {
          setShowConfirmationModal(false);
          setSelectedCategory(undefined);
        }}
      />
      ) : (<></>)}
      

      <FooterMenu />
    </CategoryWrapper>
  );
}
