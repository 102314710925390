import { IMAGES_HOST } from '../../../config/constants';
import Theme from '../model/Theme';

export default interface CustomizeLayoutForm {
  theme: string;
  color: string;
  font: string;
  border: string;
  background: string;
  logo: string;
  logoToUpload: string;
  backToUpload: string;
  loadingCustomizations?: boolean
}

export const MapToTheme = (customLayout: CustomizeLayoutForm): Theme => {
  return {
    titles: {
      font: customLayout.font,
    },
    colors: {
      primary: customLayout.color,
      secondary: customLayout.color,
    },
    borders: {
      style: customLayout.border,
    },
    theme: {
      type: customLayout.theme,
    },
    background: {
      image:
        customLayout.backToUpload.length > 0
          ? customLayout.backToUpload
          : customLayout.background.replace(
              'https://beeleza.s3.sa-east-1.amazonaws.com/',
              ''
            ),
    },
    establishmentLogo: {
      imageUrl:
        customLayout.logoToUpload.length > 0
          ? customLayout.logoToUpload
          : customLayout.logo.replace(
              'https://beeleza.s3.sa-east-1.amazonaws.com/',
              ''
            ),
    },
  };
};

export const MapToForm = (theme: Theme): CustomizeLayoutForm => {
  return {
    theme: theme.theme.type,
    color: theme.colors.primary,
    font: theme.titles.font,
    border: theme.borders.style,
    background: `${IMAGES_HOST}${theme.background.image}`,
    logo: `${IMAGES_HOST}${theme.establishmentLogo.imageUrl}`,
    logoToUpload: '',
    backToUpload: ''
  };
};
