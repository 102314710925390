import { useFormik } from 'formik';
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useAlert } from 'react-alert';
import { FaRegCopy } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import RegisterLinkForm from '../../@core/domain/ui/RegisterLinkForm';
import { getEstablishmentId, getTenantStorage, setTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import {
  Container,
  DownloadQRCodeButton,
  DownloadQRCodeContainer,
  Header,
  HeaderContainer,
  IconContainer,
  Instructiontext,
  PreviewLinkBaseText,
  PreviewLinkContainer,
  PreviewLinkOtherText,
  QRCodeContainer
} from './styles';
import validationServiceForm from './validation';

import 'react-qr-code';

import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
import GetEstablishmentById from '../../@core/application/establishment/getEstablishmentById';
import UpdateEstablishmentUseCase from '../../@core/application/establishment/updateEstablishment';
import Establishment from '../../@core/domain/model/Establishment';
import DialogModal from '../../components/DialogModal';
import FooterMenu from '../../components/Menus/FooterMenu';

ReactDOM.render(
  <QRCode value="${value}" />,
  document.getElementById('root')
);


const initialService: RegisterLinkForm = {
  nameSite: ''
};

export default function LinkToApp() {
  const [serviceForm, setServiceForm] = useState<RegisterLinkForm>(initialService);
  const [dialogModal, setDialogModal] = useState<boolean>(false);
  const [currentEstablishment, setCurrentEstablishment] = useState<RegisterLinkForm>(initialService);
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const { setLoading } = useContext(LoadingContextContent);
  

 
  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: serviceForm,
    validationSchema: validationServiceForm,
    onSubmit: (values: RegisterLinkForm) => {
      console.log("info", values)

      const updateEstablishmentUseCase = new UpdateEstablishmentUseCase()
      setLoading(true);

      const input = {
        ...currentEstablishment,
        nameSite: normalizeLink(values.nameSite)
      } as Establishment

      console.log("input", input)
      
      updateEstablishmentUseCase.execute(input)
        .then(res => {
          setLoading(false);
          setTenantStorage(input.nameSite);
          findEstablishmentById();
          showAlert.success("Informações atualizadas com sucesso")
        }).catch(err => {
          setLoading(false);

          if(err?.response?.status == 409){
            showAlert.error(err?.response?.data.erros[0])
          } else if(err?.response?.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          } else {
            showAlert.error("Falha ao atualizar informações. Tente novamente")
          }
        })
    },
  });



  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://app.beeleza.shop/" + normalizeLink(registerForm.values.nameSite));
    setCopied(true);
    showAlert.success("Link copiado")
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  const normalizeLink = (link?: string) => {
    if(!link) return
    // Substituir espaços por hífens
    let normalizedLink = link.replace(/\s+/g, '-');
    
    // Converter para minúsculas
    normalizedLink = normalizedLink.toLowerCase();
    
    return normalizedLink;
  };

  const normalizeLinkWithSpaces = (link?: string) => {
    if(!link) return
    // Substituir espaços por hífens
    let normalizedLink = link.replaceAll('-', ' ');
    
    // Converter para minúsculas
    normalizedLink = normalizedLink.toLowerCase();
    
    return normalizedLink;
  };

  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");

    console.log("svg", svg)


    if(svg){
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
   
  };


  const findEstablishmentById = useCallback(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const establishmentId = getEstablishmentId();
    setLoading(true);


    const getEstablishmentByIdUseCase = new GetEstablishmentById();

    getEstablishmentByIdUseCase
      .execute(establishmentId)
      .then((response: any) => {
        var establishment = response.data;
        setLoading(false);
        setServiceForm({
          nameSite: normalizeLinkWithSpaces(establishment.nameSite)
        });

        setCurrentEstablishment(establishment)
      })
      .catch((err: any) => {
        setLoading(false);
        if(err?.response?.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  },
  []
);

useEffect(() => {
  console.log("AQUI 1")
  findEstablishmentById();
}, [findEstablishmentById]);


  return (
    <Container>
      <HeaderContainer>
        <Header>Link do Aplicativo</Header>
        <Instructiontext>Gerencie o link que seu cliente irá usar para entrar no seu aplicativo</Instructiontext>
      </HeaderContainer>

      <Form>
        <Textbox
          placeholder="Link do aplicativo"
          name="nameSite"
          value={registerForm.values.nameSite}
          setValue={registerForm.handleChange}
          error={registerForm.errors.nameSite}
        />

        <PreviewLinkContainer>
          <PreviewLinkBaseText>https://app.beeleza.shop/</PreviewLinkBaseText>
          <PreviewLinkOtherText>
            {normalizeLink(registerForm.values.nameSite)}
          </PreviewLinkOtherText>
          <IconContainer onClick={copyToClipboard}>
            <FaRegCopy/>
          </IconContainer>
        </PreviewLinkContainer>

        <QRCodeContainer>
          <QRCode
              id="QRCode"                
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={"https://app.beeleza.shop/" + normalizeLink(registerForm.values.nameSite)}
            />
        </QRCodeContainer>

        <DownloadQRCodeContainer>
            <DownloadQRCodeButton onClick={() => onImageCownload()}>Baixar QRCode</DownloadQRCodeButton>
        </DownloadQRCodeContainer>

        <Button
          onClick={() => {
            setDialogModal(true)
          }}
          colorType="primary"
        >
          Salvar
        </Button>
      </Form>


      {dialogModal ? (
         <DialogModal isOpen={dialogModal}
          setIsOpen={setDialogModal}
          title={"IMPORTANTE"}
          message={"Após esta modificação, todos os os clientes não poderão mais acessar o aplicativo através do link anterior. Portanto, é essencial comunicá-los sobre essa alteração."}
          callback={() => registerForm.submitForm()}
          callbackReject={() => {}}
        />
      ) : (<></>)}

     
      <FooterMenu />

    </Container>
  );
}
