import {
  getEstablishment,
  getEstablishmentId,
} from '../../infrastructure/api/services/storageService';
import Login from '../model/Login';

export default interface LoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const MapToLogin = (form: LoginForm): Login => {
  return {
    login: form.email,
    password: form.password,
    loginType: 'ESTABLISHMENT',
    establishmentId: parseInt(getEstablishmentId() as string),
  };
};
