
export const extractSiteName = (texto: string) => { //TODO: Deixar em inglês
	const regex = /\/([^\/]+)\//;  // Expressão regular para capturar a palavra entre as barras
	const resultado = regex.exec(texto);  // Executa a expressão regular no texto

	if (resultado && resultado[1]) {
			return resultado[1];  // Retorna a palavra capturada entre as aspas
	} else {
			return null;  // Retorna null se não encontrar uma correspondência
	}
}

export const extractPageAfterSiteName = (text: string) => {
	text = text.replace("/", "");
	const indiceDaPrimeiraBarra = text.indexOf('/');

  console.log("indiceDaPrimeiraBarra", indiceDaPrimeiraBarra)

	if (indiceDaPrimeiraBarra !== -1) {
			return text.substring(indiceDaPrimeiraBarra + 1);
	} else {
			return ""; // Retorna null se não houver nenhuma barra na string
	}
}
