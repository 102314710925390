import { AxiosResponse } from 'axios';
import RecurrenceBlock from '../../domain/model/RecurrenceBlock';
import * as scheduleService from '../../infrastructure/api/services/scheduleService';
import IUseCase from '../IUseCase';

export default class GetRecurrencesBlockUseCase
  implements IUseCase<Number, Promise<AxiosResponse<RecurrenceBlock[]>>>
{
  execute(colaboradorId: Number) {
    return scheduleService.getRecurrences(colaboradorId);
  }
}
