import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';
import {
  default as GetFinalClient,
  default as SearchFinalClientInput,
} from '../../@core/application/final-client/getFinalClients';
import CustomerCard from '../../components/CustomerCard';
import dictionary from '../../config/dictionary';
import LoadingContextContent from '../../contexts/LoadingContext';

import FinalClient from '../../@core/domain/model/FinalClient';
import AssociatePhotoForm from '../../@core/domain/ui/AssociatePhotoForm';
import { getEstablishmentId, getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import FooterMenu from '../../components/Menus/FooterMenu';
import {
  Content,
  CustomersContainer,
  Form,
  GalleryImagesLoader,
  GalleryImagesLoaderContainer,
  Header,
  HeaderContainer,
  Instructiontext,
} from './styles';

import {
  default as AssociatePreviousServiceInput,
  default as AssociatePreviousServiceUseCase,
} from '../../@core/application/final-client/associatePreviousServiceUseCase';

import {
  default as RemovePreviousServiceImageInput,
  default as RemovePreviousServiceImageUseCase,
} from '../../@core/application/final-client/removePreviousServiceImageUseCase';


import {
  default as GetPreviousServiceInput,
  default as GetPreviousServiceUseCase,
} from '../../@core/application/final-client/getPreviousServiceUseCase';
import GetSchedulesByFilterCase from '../../@core/application/schedule/getSchedulesByFilterCase';
import GetScheduleFilter from '../../@core/domain/filter/GetScheduleFilter';
import Schedule from '../../@core/domain/model/Schedule';
import ImageGallery from '../../@core/domain/ui/ImageGallery';
import { GalleryImages } from '../../components/Gallery';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import SelectBox from '../../components/Selectbox';
import SelectBoxSearch from '../../components/SelectboxSearch';
import { formatDate } from '../../utils/HoursUtils';
import validationAssociatePhotoForm from './validation';

export default function AssociatePhotoPage() {
  const { setLoading } = useContext(LoadingContextContent);
  const showAlert = useAlert();
  
  const [previousServiceLoading, setPreviousServiceLoading] = useState(false);
  const [customers, setCustomers] = useState<FinalClient[]>([]);
  const [newImages, setNewImages] = useState<ImageGallery[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<FinalClient>();
  const [currentImages, setCurrentImages] = useState<ImageGallery[]>([]);
  const [removedImages, setRemovedImages] = useState<ImageGallery[]>([]);
  const [schedules, setSchedules] = useState([] as Schedule[])
  const navigate = useNavigate();

  const { id } = useParams();

  const formState: AssociatePhotoForm = {
    serviceId: undefined,
    customerId: id ? Number(id) : undefined,
  };

  const associatePhotoForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: validationAssociatePhotoForm,
    onSubmit: async (value: AssociatePhotoForm) => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }
      
      setLoading(true);

      const bookedTimeId = value.scheduleId;

      for (let i = 0; i < newImages.length; i++) {
        const formData = new FormData();
        formData.append('serviceId', String(value.serviceId) ?? undefined);
        formData.append('establishmentId', getEstablishmentId());    
        formData.append('bookedTimeId', String(bookedTimeId))
        formData.append('fileImages', newImages[i].file || '');

        const associatePreviousServiceUseCase =
          new AssociatePreviousServiceUseCase();

        await associatePreviousServiceUseCase
          .execute({
            formData,
            finalClientId: value.customerId,
          } as AssociatePreviousServiceInput)
          .then((res) => {
            setNewImages([])
          })
          .catch((err) => {
            setLoading(false);
            if(err.response.status == 401){
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          });
      }

      await removePreviousServiceImages();
      await getPreviousService();

      setLoading(false);
    },
  });

  const removePreviousServiceImages = useCallback(async () => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    setLoading(true)
    if (removedImages.length == 0) return;

    console.log("Realizando remoção de imagens. Imagens quantity: ", removedImages.length)
    console.log("Realizando remoção de imagens. Imagens: ", removedImages)
    console.log("Realizando remoção de imagens. Service: ", associatePhotoForm.values.serviceId)

    const removePreviousServiceImageUseCase = new RemovePreviousServiceImageUseCase();

    for (let i = 0; i < removedImages.length; i++) {
      const imageId = removedImages[i].id ?? 0

      await removePreviousServiceImageUseCase
        .execute({
          finalClientId: associatePhotoForm.values.customerId,
          previousServiceId: removedImages[i].previousServiceId,
          imageId: imageId
        } as RemovePreviousServiceImageInput)
        .then((res) => {
          console.log("Imagem removida com sucesso: ", imageId)
        })
        .catch((err) => {
           if(err.response.status == 401){
              const tenant = getTenantStorage();
              navigate(`/${tenant}/login`)
            };
          console.log("Falha ao remover imagem: ", imageId)
        });
    }

    setLoading(false)

    setRemovedImages([])
  },
    [associatePhotoForm.values.customerId, removedImages]
  );


  const findCustomers = useCallback(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getAllFinalClientUseCase = new GetFinalClient();
    getAllFinalClientUseCase
      .execute({
        terms: undefined,
      } as SearchFinalClientInput)
      .then((res: any) => {
        setCustomers(res.data.items);
      }).catch(err => {
        if(err.response.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      });
  }, []);

  const findSchedules = useCallback(() => {
    console.log("buscaaaando")
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getSchedulesByFilterCase = new GetSchedulesByFilterCase();

    getSchedulesByFilterCase
      .execute({
        finalClientId: associatePhotoForm.values.customerId,
        initialIntervalDate: "2021-01-01T00:00:00",
        endIntervalDate: "2024-12-25T00:00:00"
      } as GetScheduleFilter)
      .then((res: any) => {
        setSchedules(res.data);
      }).catch(err => {
        if(err.response.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      })
  }, [associatePhotoForm.values.customerId]);

  useEffect(() => {
    findCustomers();
    findSchedules();
  }, []);

  useEffect(() => {
    getPreviousService();
    findSchedules();
  }, [associatePhotoForm.values])

  const getPreviousService = useCallback(() => {
    if(!checkUserAuthenticated()){
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getPreviousServiceUseCase = new GetPreviousServiceUseCase();

    const finalClientId = associatePhotoForm.values.customerId;
    const serviceId = associatePhotoForm.values.serviceId ?? "";
    const bookedTimeId = associatePhotoForm.values.scheduleId;

    if (!finalClientId) return;
    
    if (!serviceId) return;

    if (!bookedTimeId) return;

    setPreviousServiceLoading(true)

    getPreviousServiceUseCase
      .execute({finalClientId, serviceId, bookedTimeId} as GetPreviousServiceInput)
      .then(res => {
        setCurrentImages(res.data.flatMap((previousService: any) => {
          return previousService.images.map((img: any) => {
            return {
              ...img,
              previousServiceId: previousService.id
            } as ImageGallery
          })          
        }))
        setPreviousServiceLoading(false)
      })
      .catch(err => {
        setCurrentImages([])
        setPreviousServiceLoading(false)
        if(err.response.status == 401){
          const tenant = getTenantStorage();
          navigate(`/${tenant}/login`)
        };
      })
  }, [associatePhotoForm.values]);

  const addImageCallback = useCallback(
    (newImageValue: string | ArrayBuffer | null, file: File) => {
      let newImg = {
        id: Math.floor(Math.random() * 1000000),
        valueImage: newImageValue,
        isNew: true,
        file,
      } as ImageGallery;
      setNewImages((prevImages) => [...prevImages, newImg]);
    },
    [setNewImages]
  );

  const removeImageCallback = useCallback(
    (image: ImageGallery) => {
      if (image.isNew) {
        setNewImages((prevImages) =>
          prevImages.filter((img) => img.id !== image.id)
        );
      }

      if (!image.isNew) {
        setCurrentImages((prevImages) =>
          prevImages.filter((img) => img.id !== image.id)
        );
        setRemovedImages((prevImages) => [...prevImages, image]);
      }
    },
    [setNewImages]
  );

  const getServiceOptions = useCallback(
    () => {
      const scheduledId = associatePhotoForm.values.scheduleId;
      if(scheduledId){
        const scheduleSelected = schedules.filter(schedule => schedule.id == Number(associatePhotoForm.values.scheduleId));
        if(scheduleSelected.length > 0){
          return scheduleSelected[0].services.map((service) => {
            return {
              value: service.id?.toString() as string,
              name: service.name,
            };
          })
        }
      }

      return []
    },
    [associatePhotoForm.values.scheduleId]
  );





  return (
    <Content>
      <HeaderContainer>
        <Header>{dictionary.associatePhotoCustomer.title}</Header>
        <Instructiontext>
          {dictionary.associatePhotoCustomer.instruction}
        </Instructiontext>
      </HeaderContainer>
      <Form>
        <SelectBoxSearch
          label="Cliente"
          placeholder="Escolha o cliente"
          name="customerId"
          value={associatePhotoForm.values.customerId}
          setValue={(e: string) => {
            console.log("configurando valor ", e)
            associatePhotoForm.setFieldValue("customerId", e)
          }}
          icon={faLayerGroup}
          error={associatePhotoForm.errors.customerId}
          items={customers.map((customer) => {
            return { value: customer.id, label: customer.name };
          })}
          // placeholder="Escolha o cliente"
          // name="customerId"
          // initialValue={
          //   customers.filter(
          //     (customer) => customer.id == formState.customerId
          //   )[0]?.name
          // }
          // value={""}
          // icon={faLayerGroup}
          // error={associatePhotoForm.errors.customerId}
          // onChange={(selectedValue: String) => {
          //   associatePhotoForm.setFieldValue(
          //     'customerId',
          //     selectedValue.toString()
          //   );
          // }}
          // options={customers.map((customer) => {
          //   return {
          //     value: customer.id?.toString() as string,
          //     name: customer.name,
          //   };
          // })}
        />

        <SelectBox
          label="Agendamento"
          placeholder="Escolha o agendamento"
          name="scheduleId"
          disabled={associatePhotoForm.values.customerId === undefined ||  associatePhotoForm.values.customerId == null || associatePhotoForm.values.customerId == 0}
          value={associatePhotoForm.values.scheduleId}
          setValue={associatePhotoForm.handleChange}
          icon={faLayerGroup}
          error={associatePhotoForm.errors.scheduleId}
          items={schedules.map((schedule) => {
            return { value: schedule.id, label: formatDate(schedule.startTime) };
          })}
        />

      <SelectBox
        label="Serviço"
        placeholder="Serviço"
        name="serviceId"
        value={associatePhotoForm.values.serviceId}
        disabled={associatePhotoForm.values.scheduleId === undefined ||  associatePhotoForm.values.scheduleId == null || associatePhotoForm.values.scheduleId == 0}
        setValue={associatePhotoForm.handleChange}
        error={associatePhotoForm.errors.serviceId}
        icon={faLayerGroup}
        items={getServiceOptions().map((service) => {
          return { value: service.value, label: service.name};
        })}
      />

        {previousServiceLoading ? (
          <GalleryImagesLoaderContainer>
            <GalleryImagesLoader />
            <GalleryImagesLoader />
            <GalleryImagesLoader />
            <GalleryImagesLoader />
            <GalleryImagesLoader />
            <GalleryImagesLoader />
          </GalleryImagesLoaderContainer>
        ) : (
          <GalleryImages
            images={[
              ...currentImages.sort((a: any, b: any) => a.id - b.id),
              ...newImages,
            ]}
            addImageCallback={addImageCallback}
            removeImageCallback={removeImageCallback}
          />
        )}

        <Button
          colorType="primary"
          onClick={() => associatePhotoForm.submitForm()}
        >
          Salvar Histórico
        </Button>
      </Form>

      {selectedCustomer ? (
        <CustomersContainer>
          <CustomerCard customer={selectedCustomer} />
        </CustomersContainer>
      ) : (
        <></>
      )}
      <FooterMenu />
    </Content>
  );
}
