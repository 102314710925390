import styled, { css, keyframes } from 'styled-components';
import background from '../../assets/images/background_register.png';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 1em;
  padding-top: 6em;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding-top: 3em;
    background-size: cover;
    background-image: url(${background});
    background-position: center;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  text-align: center;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const MyScheduleContainer = styled.div`
  width: 100%;
  // max-height: 900px;
  // height: 900px;
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical quando necessário */
`;

export const SliderContainer = styled.div``;

export const Slider = styled.div`
  display: flex;
  gap: 1em;
  overflow-y: scroll;
  padding: 1em;
  align-items: center;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const Employee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;
`;

export const EmployeeImage = styled.img<{ selected?: boolean }>`
  width: 6em;
  height: 6em;
  border: 8px solid rgba(128, 128, 128, 0.2);
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 12px 1px #5d17eb;
    `}
`;

export const EmployeeName = styled.span`
  color: black;
  font-size: 0.8em;
  letter-spacing: 1px;
`;

export const BackofficeButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;

  /* Adicionando sombra para dar uma aparência flutuante */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
`;

export const TabContainer = styled.div`
  width: 100%;
`;

export const ContainerTab = styled.div`
`;

export const Instructiontext = styled.span`
  font-size: 0.9em;
  text-align: center;
`;

export const ColaboratorsLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const shineAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const ColaboratorsLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 6em;
  height: 6em;
`;


export const MyScheduleLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const MyScheduleLoader = styled.div`
  animation: ${shineAnimation} 1.5s linear infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  width: 90vw;
  height: 50vh;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1em;
  margin-bottom: 4em;
  z-index: 500; /* Valor alto para garantir que fique sobre todos os outros componentes */
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;

export const ViewerExtraContainer = styled.div`
  font-size: 0.8em;
  padding-bottom: 1em;
`;

// export const WarningContainer = styled.div`
//   margin-top: 1em;
//   width: 100%; /* Ajuste a largura conforme necessário */
//   padding: 1em;
//   background-color: lightyellow;
//   border-radius: 10px; /* Ajuste o valor para arredondar mais ou menos */
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 1em;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
//   padding: 1em;

// `;

export const ConfirmScheduleButton = styled.div`
  display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1em;
  color: ${(props) => props.theme.color.primary};
  align-items: center;
`;


export const WarningContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -5px;
  margin-top: -5px;
`;

export const IconContainerWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  color: ${(props) => props.theme.color.primary};
  padding: 0.2em;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
`;

export const WarningActiveAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma leve sombra */
  padding: 1em;
  margin-top: 1em;
`;

export const IconActiveAccountContainerWarning = styled.div`
  font-size: 1.4em;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CancelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const IconCancelContainer = styled.div`
  display: flex;
  align-items: center;
  color: #D44333;
  font-size: 1.5em;
`;

export const CancelButton = styled.button`
  background-color: white;
  border: 1px solid #ccc; /* cinza clara */
  border-radius: 5px; /* borda ligeiramente arredondada */
  padding: 10px 20px; /* padding para tamanho e espaçamento interno */
  color: black; /* cor do texto */
  cursor: pointer; /* cursor de pointer para indicar que é clicável */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px; /* tamanho da fonte */
  text-align: center;
  width: 40%;
  font-weight: 400; /* Definindo a fonte mais fina */

  &:hover {
    background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
  }

  &:active {
    background-color: #e0e0e0; /* cor de fundo ao clicar */
  }
`;

export const CancelRecurrenceButton = styled.button`
  background-color: white;
  border: 1px solid #ccc; /* cinza clara */
  border-radius: 5px; /* borda ligeiramente arredondada */
  padding: 10px 20px; /* padding para tamanho e espaçamento interno */
  color: black; /* cor do texto */
  cursor: pointer; /* cursor de pointer para indicar que é clicável */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px; /* tamanho da fonte */
  text-align: center;
  font-weight: 400; /* Definindo a fonte mais fina */

  &:hover {
    background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
  }

  &:active {
    background-color: #e0e0e0; /* cor de fundo ao clicar */
  }
`;
