import styled, { css } from 'styled-components';

interface DayProps {
  selected: boolean;
}

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


export const Label = styled.div`
  font-size: 12px; /* Tamanho da fonte pequeno */
  color: #333; /* Cor do texto */
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const DaysContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px; /* Espaçamento entre os dias */
`;


const Day = styled.div<DayProps>`
  width: 40px;
  height: 40px;
  background-color: #E0E0E0; /* Cor de fundo após selecionar */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Transição suave */

  ${(props) =>
    props.selected ?
    css`
      background-color: #5D17EB; /* Cor ao passar o mouse */
      color: white; /* Cor do texto após selecionar */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra leve */
    ` :
    css`
      background-color: #E0E0E0; /* Cor de fundo após selecionar */
      color: black; /* Cor do texto sem selecionar */
    `
  }
`;

export const Sunday = (props: any) => <Day {...props}>Dom</Day>;
export const Monday = (props: any) => <Day {...props}>Seg</Day>;
export const Tuesday = (props: any) => <Day {...props}>Ter</Day>;
export const Wednesday = (props: any) => <Day {...props}>Qua</Day>;
export const Thursday = (props: any) => <Day {...props}>Qui</Day>;
export const Friday = (props: any) => <Day {...props}>Sex</Day>;
export const Saturday = (props: any) => <Day {...props}>Sáb</Day>;


export const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
