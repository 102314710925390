import LoginReturn from '../../../domain/model/LoginReturn';

export const getEstablishmentId = () => {
  const establishment = JSON.parse(
    localStorage.getItem('admin_establishment') || '{}'
  );
  return establishment.id;
};

export const getEstablishment = () => {
  return JSON.parse(localStorage.getItem('admin_establishment') || '{}');
};

export const setEstablishment = (establishment: any) => {
  return localStorage.setItem('admin_establishment', establishment);
};

export const setLoginData = (loginReturn: LoginReturn) => {
  return localStorage.setItem('admin_auth_data', JSON.stringify(loginReturn));
};

export const getLoginData = () => {
  return localStorage.getItem('admin_auth_data');
};

export const cleanAuthData = () => {
  localStorage.removeItem('admin_establishment');
  localStorage.removeItem('admin_auth_data');
};

export const getInformationFromJWTStorage = (item: string) => {
	if (typeof window == "undefined") return;

  const authData = getLoginData();

  if(!authData) return {}

	var payload = JSON.parse(authData).token?.split(".")[1];
	if(!payload) return;

	var payloadObj = JSON.parse(atob(payload));
	return payloadObj[item];
};

export const getInformationFromJWTRefreshTokenStorage = (item: string) => {
	if (typeof window == "undefined") return;

  const authData = getLoginData();

  if(!authData) return "";

  console.log("authData 3", authData)

  var jwt = JSON.parse(authData).refreshToken;
	if(!jwt) return;

	var payload = jwt?.split(".")[1];
	if(!payload) return;

	var payloadObj = JSON.parse(atob(payload));
	return payloadObj[item];
};

export const setKeepConnectionStorage = (value: boolean) => {
	if (typeof window == "undefined") return;

  return localStorage.setItem('admin_keepConnecting', String(value));
};

export const getKeepConnectingStorage = () => {
	if (typeof window == "undefined") return;

  return localStorage.getItem('admin_keepConnecting');
};

export const getFinalClientJWTStorage = () => {
	if (typeof window == "undefined") return;

  return localStorage.getItem('admin_finalClientJWT');
};

export const setFinalClientJWTStorage = (tokenJWT: string) => {
	if (typeof window == "undefined") return;

  return localStorage.setItem('admin_finalClientJWT', tokenJWT);
};

export const getTenantStorage = () => {
	if (typeof window == "undefined") return;

  return localStorage.getItem('admin_tenant');
};

export const setTenantStorage = (tenant: string) => {
	if (typeof window == "undefined") return;

  return localStorage.setItem('admin_tenant', tenant);
};

export const getRefreshTokenStorage = () => {
	if (typeof window == "undefined") return;

  const authData = getLoginData();

  if(!authData) return {}

  return JSON.parse(authData).refreshToken;
};
