import moment from "moment";

export const formatDate = (date: string) => {
	return moment(String(date)).format(getDateFormat())
}

export const formatDateWithSeconds = (date: string) => {
	return moment(String(date)).format(getDateFormatWithSeconds())
}

export const formatADateObjectWithHourAndSecondsFormat = (date: Date) => {
	return moment(String(date)).format(getDateFormatWithSeconds())
}


export const formatDateWithHourAndMinutesOnly = (date: string) => {
	return moment(String(date)).format(getDateFormatWithHourAndMinutesOnly())
}

export const addGenericYearDayAndMonthToHours = (hours: string) => {
  return  "2999-03-24T" + hours + ":00";
}


export const getDateFormat = () => {
	return 'DD/MM/YYYY - HH:mm';
}

export const getDateFormatWithSeconds = () => {
	return 'YYYY-MM-DDTHH:mm:ss';
}

export const getDateFormatWithHourAndMinutesOnly = () => {
	return 'HH:mm';
}


export const getLongDateString = (dateString: string) => {
  const scheduleDate = {
    year: new Date(dateString).getFullYear(),
    month: new Date(dateString).toLocaleString('pt-BR', { month: 'long' }),
    day: new Date(dateString).getDate(),
    weekDay: new Date(dateString).toLocaleString('pt-BR', { weekday: 'long' }),
  };

  return `${scheduleDate.weekDay}, ${scheduleDate.day} de ${scheduleDate.month} de ${scheduleDate.year}`;
};

export const formatWeekDay = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };
  const formattedDate = new Date(date).toLocaleDateString('pt-BR', options);
  
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
