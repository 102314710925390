import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.color.dark};
  letter-spacing: 1px;
  align-self: flex-start;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessage = styled.small<{ showPassword?: boolean }>`
  color: ${(props) => props.theme.color.error};
  padding-top: ${(props) => (props.showPassword ? '0' : '6px')};
  letter-spacing: 1px;
  align-self: flex-start;
`;

export const StyledDatePicker = styled(DatePicker)`

}

`;
