import styled from 'styled-components';
import { Link } from 'react-router-dom';
import background from '../../assets/images/background_register.png';


export const ContainerGif = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #AD8AF5;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  border-top-left-radius: 2em; /* Bordas superiores esquerdas arredondadas */
  border-top-right-radius: 2em; /* Bordas superiores direitas arredondadas */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: -1.8em;
  padding: 1em;
  padding-top: 5em;
  background-color: white;
`;

export const Title = styled.div`
  font-size: 1.2em; /* Tamanho da fonte do título */
  font-weight: bold; /* Deixa o texto mais grosso */
  margin-bottom: 10px; /* Adiciona espaçamento abaixo do título */
  letter-spacing: 2px; /* Ajusta o espaçamento entre as letras */
  text-align: center; 
  `;

export const Text = styled.div`
  margin-top: 10px; /* Adiciona espaçamento acima do texto */
  font-size: 0.8em;
  text-align: center; 
  font-weight: 300;
`;

export const ButtonReturn = styled.div`
  width: 100%;
  padding: 10px 0; /* Adicione um preenchimento interno para melhorar a aparência */
  background-color: #5D17EB;
  color: white; /* Defina a cor do texto como branco para melhor legibilidade */
  border-radius: 2em; 
  text-align: center; 
  font-weight: bold; 
  margin-top: auto;
  margin-bottom: 10px;
  height: 2.5em;
`;












