import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import Textarea from '../Textarea';
import { ContainerForm } from './styles';
import Slide from '@mui/material/Slide';

interface DialogModalParams {
  isOpen: boolean;
  setIsOpen: any;
  description: string;
  callback: any;
  callbackReject: any;
  title: string;
  labelForm: string;
  submitButtonText: string;
  cancelButtonText: string;
  setTextForm: any;
  textFormValue: string;
  placeHolder: string;
  
}


export default function DialogFormModal({ isOpen, 
  description, 
  callback, 
  setIsOpen, 
  title, 
  callbackReject, 
  labelForm,
  submitButtonText, 
  cancelButtonText,
  setTextForm,
  textFormValue,
  placeHolder}: DialogModalParams) {

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTextForm(event.target.value);
    },
    [setTextForm]
  );

  return (
    <React.Fragment>
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const email = formJson.email;
          console.log(email);
          handleClose();
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
        <ContainerForm>
          <Textarea
            placeholder={placeHolder}
            label={labelForm}
            name="content"
            initialSize={8}
            value={textFormValue}
            setValue={handleInputChange}
            limit={255}
          />
        </ContainerForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={callbackReject}>{cancelButtonText}</Button>
        <Button onClick={callback}>{submitButtonText}</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
  );
}
