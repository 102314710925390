import {
  useCallback,
  useEffect,
  useState
} from 'react';

import moment from 'moment';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { getTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import { checkUserAuthenticated } from '../../components/PrivateRoute';

import {
  faCalendar,
  faComment,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import AddScheduleCase from '../../@core/application/schedule/addScheduleCase';
import BlockScheduleForm, {
  MapToSaveBlockSchedule,
} from '../../@core/domain/ui/BlockScheduleForm';
import { getEstablishmentId } from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import DateTimePickerBox from '../../components/DateTimePicker';
import { Form } from '../../components/Form/style';
import SelectBox from '../../components/Selectbox';
import Textbox from '../../components/TextboxMUI';
import BlockScheduleValidation from './blockScheduleValidation';



import { ProgressSpinner } from 'primereact/progressspinner';
import { FaArrowLeftLong } from 'react-icons/fa6';
import GetColaborator from '../../@core/application/colaborator/getColaborators';
import Colaborator from '../../@core/domain/model/Colaborator';
import {
  Content,
  Header,
  HeaderTitle,
  IconContainer
} from './styles';

export default function BlockSchedule() {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [colaborators, setColaborators] = useState([] as Colaborator[]);
  const [ isLoading, setLoading ] = useState(false);



  const [blockScheduleForm, setBlockScheduleForm] = useState<BlockScheduleForm>(
    {
      date: null,
      title: '',
      endBlockSchedule: '',
      observation: '',
      colaboratorId: null,
      scheduleType: 'BLOCK_SCHEDULE',
      paymentForm: 'LOCAL',
      establishmentId: parseInt(getEstablishmentId() as string),
    }
  );

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: BlockScheduleValidation,
    initialValues: blockScheduleForm,

    onSubmit: (values: BlockScheduleForm) => {
      if (!checkUserAuthenticated()) {
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const saveSchedule = MapToSaveBlockSchedule(values);
      const startDate = moment(saveSchedule.date);
      const endDate = moment(saveSchedule.endBlockSchedule);

      if (endDate.isBefore(startDate)) {
        form.setErrors({ endBlockSchedule: 'A data de término deve ser posterior à data de início.' }); // Definir o erro para o campo endBlockSchedule
        console.log("teste")
        return; // Abort
      }

      const addCase = new AddScheduleCase();
      setLoading(true);
      addCase
        .execute(saveSchedule)
        .then(() => {
          setLoading(false);
          showAlert.success('Agenda bloqueada com sucesso!');
          navigate(`/minha-agenda`)
        })
        .catch((err) => {
          setLoading(false);
          showAlert.error(err.response.data.erros[0]);
          if (err.response.status == 401) {
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
  });

  useEffect(() => {
    console.log("form.values.endBlockSchedule", form.values.endBlockSchedule)
  }, [form.values.endBlockSchedule])

  const findColaborators = useCallback(() => {
    if (!checkUserAuthenticated()) {
      const tenant = getTenantStorage();
      navigate(`/${tenant}/login`)
      return;
    }

    const getUseCase = new GetColaborator();
    getUseCase.execute().then((response: any) => {
      setColaborators(response.data);
    }).catch((err: any) => {
      console.log("Algum erro ocorreu na obtenção dos colaboradores", err)
    });
  }, []);

  useEffect(() => {
    findColaborators();
  }, []);

  return (
    <Content>
      <Header>
        <IconContainer>
          <FaArrowLeftLong onClick={() => {
            navigate(`/minha-agenda`)
          }}/>
        </IconContainer>
        <HeaderTitle>BLOQUEAR AGENDA</HeaderTitle>
      </Header>
       <Form>
          <Textbox
            placeholder="Título compromisso"
            name="title"
            icon={faLayerGroup}
            value={form.values.title}
            setValue={form.handleChange}
            error={form.errors.title}
          />

          <SelectBox
            placeholder="Colaborador"
            name="colaboratorId"
            value={form.values.colaboratorId}
            setValue={form.handleChange}
            icon={faLayerGroup}
            error={form.errors.colaboratorId}
            items={colaborators.map((colaborator) => {
              return { value: colaborator.id, label: colaborator.name };
            })}
          />

          <DateTimePickerBox
            label="Data inicio"
            placeholder="Data inicio"
            name="date"
            type="datetime-local"
            icon={faCalendar}
            value={form.values.date}
            setValue={(newValue: string) => { form.setFieldValue("date", newValue) }}
            error={form.errors.date}
          />

          <DateTimePickerBox
            label="Data fim"
            placeholder="Data fim"
            name="endBlockSchedule"
            type="datetime-local"
            icon={faCalendar}
            value={form.values.endBlockSchedule}
            setValue={(newValue: string) => { form.setFieldValue("endBlockSchedule", newValue) }}
            error={form.errors.endBlockSchedule}
          />

          <Textbox
            placeholder="Observação"
            name="observation"
            icon={faComment}
            value={form.values.observation}
            setValue={form.handleChange}
            error={form.errors.observation}
          />

          <Button colorType="primary" onClick={() => form.submitForm()}>
            {isLoading ? <ProgressSpinner style={{width: '30px', height: '30px'}}/> : "Bloquear agenda"} 
          </Button>
        </Form>
    </Content>
  );
}
