import {
  Container,
  Content,
  ErrorMessage,
  Input,
  Label,
  ShowEndIcon,
  ShowIcon,
  ShowPassword,
  StyledTextField,
} from './styles';
import { useState, useCallback, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  setValue?: (e: ChangeEvent<any>) => void;
  name?: string;
  showLabel?: boolean;
  icon?: any;
  iconx?: JSX.Element;
  iconEnd?: any;
  variant?:  'outlined' | 'filled' | 'standard' | undefined;
  onClickEnd?: () => void;
  onClick?: () => void;
}

export default function TextboxPassword({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
   iconx,
  icon,
  iconEnd,
  onClick,
  onClickEnd,
  showLabel,
  variant
}: TextboxParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue) setValue(event);
    },
    [setValue]
  );


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const startAdornment = iconx ?  {
    startAdornment: (
      <InputAdornment position="start">
        {iconx}
      </InputAdornment>
    ),
  } : {};

  const endAdornment = iconx ?  {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  } : {};
  

  return (
    <Container id={`${id}-container`}>
      <StyledTextField
        id={id}
        placeholder={placeholder}
        label={label ?? placeholder}
        variant={variant ?? "outlined"}
        type={showPassword ? 'text' : 'password'}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        onClick={onClick}
        InputProps={{
          ...startAdornment,
          ...endAdornment,
          sx: {
            '& input': {
              backgroundColor: 'white',
              borderRadius: '0', // Bordas quadradas
            }
          }
        }}
      />
        {error && (
          <ErrorMessage showPassword={isPassword}>{error}</ErrorMessage>
        )}
    </Container>
  );
}
