import {
  Container,
  Content,
  Counter,
  CounterExceeded,
  ErrorMessage,
  ShowIcon,
  ShowPassword,
  StyledTextArea,
  TextArea,
} from './styles';
import { useState, useCallback, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { StyledTextField } from '../TextboxMUI/styles';

interface TextboxParams {
  label?: string;
  id?: string;
  isPassword?: boolean;
  size?: number;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: string;
  setValue?: (e: ChangeEvent<any>) => void;
  name?: string;
  icon?: any;
  initialSize?:number;
  limit: number;
}

export default function Textarea({
  label,
  id,
  isPassword,
  placeholder,
  size,
  error,
  disabled,
  type,
  value,
  setValue,
  name,
  icon,
  initialSize,
  limit
}: TextboxParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue) setValue(event);
    },
    [setValue]
  );

  return (
    <Container id={`${id}-container`}>
   
      <StyledTextArea
        placeholder={placeholder}
        label={label ?? placeholder}
        variant={"outlined"}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        multiline
        rows={4}
      />
      
      {value == undefined || value?.length < limit ? (
        <Counter>
          {value?.length} / {limit}
        </Counter>
      ) : (
        <CounterExceeded>
          {value?.length} / {limit}
        </CounterExceeded>
      ) }
      
  
      {error && (
        <ErrorMessage showPassword={isPassword}>{error}</ErrorMessage>
      )}
  </Container>
  );
}
