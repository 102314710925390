import IUseCase from '../IUseCase';
import * as categoryService from '../../infrastructure/api/services/categoryService';
import RegisterCategory from '../../domain/model/RegisterCategory';

export default class CreateCategory
  implements IUseCase<RegisterCategory, Promise<any>>
{
  execute(register: RegisterCategory) {
    return categoryService.createCategory(register);
  }
}
