import Service from '../../domain/model/Service';
import IUseCase from '../IUseCase';
import * as serviceService from '../../infrastructure/api/services/servicesService';

export default class DeleteServiceUseCase
  implements IUseCase<number, Promise<any>>
{
  execute(serviceId: number) {
    return serviceService.deleteService(serviceId);
  }
}
