import { AxiosResponse } from 'axios';
import SaveSchedule from '../../domain/model/SaveSchedule';
import IUseCase from '../IUseCase';
import Establishment from '../../domain/model/Establishment';
import * as establishmentService from '../../infrastructure/api/services/establishmentService';
export default class UpdateEstablishmentUseCase
  implements IUseCase<Establishment, Promise<AxiosResponse>>
{
  execute(establishment: Establishment) {
    return establishmentService.updateEstablishment(establishment.id, establishment);
  }
}
