import moment from 'moment';
import Schedule from '../model/Schedule';
import DeleteSchedule from '../model/DeleteSchedule';
import { getEstablishment } from '../../infrastructure/api/services/storageService';
import { DATE_FORMAT } from '../../../config/constants';
import { GoDotFill } from "react-icons/go";
import Service from '../model/Service';

export default interface SchedulerEvent {
  event_id: number;
  title: string;
  start: Date;
  end: Date;
  finalClientId: number;
  finalClientName: string;
  finalClientPhone: string;
  isDefaulter: boolean;
  services: Service[];
  isBlock?: boolean;
  color?: string;
}

export const MapFromSchedule = (schedule: Schedule): SchedulerEvent => {
  let title = schedule.finalClientName == null || schedule.finalClientName === undefined ? schedule.title : schedule.finalClientName;
  title = `${schedule.isDefaulter ? "🟡" : ""} ${title} | Serviços: ${schedule.services.map(service => service.name).join(", ")} | obs: ${schedule.observations}`

  return {
    title: title,
    finalClientId: schedule.finalClientId,
    isDefaulter: schedule.isDefaulter,
    services: schedule.services,
    finalClientName: schedule.finalClientName,
    finalClientPhone: schedule.finalClientPhone,
    start: moment(schedule.startTime).toDate(),
    end: moment(schedule.endTime).toDate(),
    event_id: schedule.id,
  };
};

export const MapToDelete = (
  event: SchedulerEvent,
  colaboratorId: number,
  reason: string
): DeleteSchedule => {
  console.log("event.start", event.start)
  return {
    establishmentId: parseInt(getEstablishment() as string),
    colaboratorId: colaboratorId,
    finalClientId: event.finalClientId,
    initialIntervalDate: moment(event.start).format(DATE_FORMAT),
    endIntervalDate: moment(event.end).format(DATE_FORMAT),
    reason: reason
  };
};
