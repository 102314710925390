import {
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import UpdateEstablishmentUseCase from '../../@core/application/establishment/updateEstablishment';
import Colaborator from '../../@core/domain/model/Colaborator';
import Establishment from '../../@core/domain/model/Establishment';
import FinalClient from '../../@core/domain/model/FinalClient';
import Service from '../../@core/domain/model/Service';
import UpdateEstablishmentForm from '../../@core/domain/ui/UpdateEstablishmentForm';
import {
  getEstablishmentId,
  getTenantStorage
} from '../../@core/infrastructure/api/services/storageService';
import Button from '../../components/Button';
import { Form } from '../../components/Form/style';
import SelectBox from '../../components/Selectbox';
import Textbox from '../../components/TextboxMUI';
import LoadingContextContent from '../../contexts/LoadingContext';
import validationServiceForm from './validation';
import TimePickerBox from '../../components/TimePickerBox';
import { Divider } from './styles';
import { checkUserAuthenticated } from '../../components/PrivateRoute';
import GetEstablishmentById from '../../@core/application/establishment/getEstablishmentById';

interface props {
}
export default function EstablishmentDataForm({
}: props) {
  const { setLoading } = useContext(LoadingContextContent);

  const initialValues: UpdateEstablishmentForm = {
    nameSite: '',
    establishmentType: '',
    description: '',
    socialName: '',
    fantasyName: '',
    name: '',
    telephone: '',
    email: '',
    cnpj: '',
    matriz: '',
    facebookURL: '',
    gmailURL: '',
    instagramURL: '',
    whatsappURL: ''
  };
  

  const showAlert = useAlert();

  const navigate = useNavigate();

  const [updateEstablishmentForm, setUpdateEstablishmentForm] = useState<UpdateEstablishmentForm>(initialValues);


  const registerForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: updateEstablishmentForm,
    validationSchema: validationServiceForm,
    onSubmit: async (values: UpdateEstablishmentForm) => {
      console.log("info", values)

      const updateEstablishmentUseCase = new UpdateEstablishmentUseCase()
      setLoading(true);

      let input = {
        ...values,
        socialLinks: {
        facebookURL: values.facebookURL,
        gmailURL: values.gmailURL,
        instagramURL: values.instagramURL,
        whatsappURL: values.whatsappURL
      },
      } as Establishment

      input = removeAllSpecialCharacters(input)

      console.log("input", input)
      
      updateEstablishmentUseCase.execute(input)
        .then(res => {
          setLoading(false);
          findEstablishmentById();
          showAlert.success("Informações atualizadas com sucesso")
        }).catch(err => {
          setLoading(false);
          if(err?.response?.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          } else {
            showAlert.error("Falha ao atualizar informações. Tente novamente")
          }
        })  
    },
  });

  const removeAllSpecialCharacters = (jsonObject: any) => {
    // Percorra todas as propriedades do objeto
    for (const key in jsonObject) {
        if(key == "telephone" || key == "cnpj")
        if (jsonObject.hasOwnProperty(key)) {
            // Verifique se o valor da propriedade é uma string
            if (typeof jsonObject[key] === 'string') {
                // Remova todos os caracteres especiais usando a expressão regular corrigida
                jsonObject[key] = jsonObject[key]?.replace(/[./,:()\-\s]/g, '');
            }
        }
    }

    return jsonObject;
  };


  const findEstablishmentById = useCallback(() => {
      if(!checkUserAuthenticated()){
        const tenant = getTenantStorage();
        navigate(`/${tenant}/login`)
        return;
      }

      const establishmentId = getEstablishmentId();

      const getEstablishmentByIdUseCase = new GetEstablishmentById();

      getEstablishmentByIdUseCase
        .execute(establishmentId)
        .then((response: any) => {
          var establishment = response.data;
          setLoading(false);
          setUpdateEstablishmentForm({
          ...updateEstablishmentForm,
          ...establishment,
          ...establishment.socialLinks
          });
        })
        .catch((err: any) => {
          setLoading(false);
          if(err?.response?.status == 401){
            const tenant = getTenantStorage();
            navigate(`/${tenant}/login`)
          };
        });
    },
    []
  );

  useEffect(() => {
    console.log("AQUI 1")
    findEstablishmentById();
  }, [findEstablishmentById]);

  return (
    <FormikProvider value={registerForm}>
      <Form>
        <Textbox
          label="Nome"
          placeholder="Nome do estabelecimento"
          name="name"
          icon={faUser}
          value={registerForm.values.name}
          setValue={registerForm.handleChange}
          error={registerForm.errors.name}
        />
      
        <Textbox
          label="Telefone"
          placeholder="Telefone do estabelecimento"
          name="telephone"
          mask="PHONE"
          value={registerForm.values.telephone}
          setValue={registerForm.handleChange}
          error={registerForm.errors.telephone}
        />

        <SelectBox
          label="Categoria"
          placeholder="Categoria do estabelecimento"
          name="establishmentType"
          value={registerForm.values.establishmentType}
          setValue={registerForm.handleChange}
          error={registerForm.errors.establishmentType}
          items={[
            {
              value: "BARBERSHOP",
              label: "Barbearia"
            },
            {
              value: "BEAUTY_SALON",
              label: "Salão de Beleza"
            },
            {
              value: "BEAUTY_CLINIC",
              label: "Clínica de Estética"
            },
            {
              value: "OTHER",
              label: "Outros"
            }
          ]}
        />

        <Textbox
          label="CNPJ"
          placeholder="CNPJ do estabelecimento"
          name="cnpj"
          mask="CNPJ"
          value={registerForm.values.cnpj}
          setValue={registerForm.handleChange}
          error={registerForm.errors.cnpj}
        />     

        <Divider/>

        <TimePickerBox
          label="Inicio horário de expediente"
          placeholder="Inicio horário de expediente"
          name="startWork"
          showLabel={true}
          type="time"
          value={registerForm.values.startWork}
          setValue={(newValue: string) => {registerForm.setFieldValue("startWork", newValue)}}
          error={registerForm.errors.startWork}
        />

        <TimePickerBox
          label="Fim horário de expediente"
          placeholder="Fim horário de expediente"
          name="endWork"
          showLabel={true}
          type="time"
          value={registerForm.values.endWork}
          setValue={(newValue: string) => {registerForm.setFieldValue("endWork", newValue)}}
          error={registerForm.errors.endWork}
        />

        <Divider/>


        <Textbox
          label="Link instagram"
          placeholder="https://www.instagram.com/beeleza-app/"
          name="instagramURL"
          value={registerForm.values.instagramURL}
          setValue={registerForm.handleChange}
          error={registerForm.errors.instagramURL}
        />

        <Textbox
          label="Link facebook"
          placeholder="https://www.facebook.com/beeleza-app"
          name="facebookURL"
          value={registerForm.values.facebookURL}
          setValue={registerForm.handleChange}
          error={registerForm.errors.facebookURL}
        />

        <Textbox
          label="Whatsapp número"
          placeholder="19982902475"
          name="whatsappURL"
          mask="PHONE"
          value={registerForm.values.whatsappURL}
          setValue={registerForm.handleChange}
          error={registerForm.errors.whatsappURL}
        />

        <Textbox
          label="Email para contato"
          placeholder="exemplo@gmail.com"
          name="gmailURL"
          value={registerForm.values.gmailURL}
          setValue={registerForm.handleChange}
          error={registerForm.errors.gmailURL}
        />

        <Button
          onClick={() => {
            registerForm.submitForm();
          }}
          colorType="primary"
        >
          Salvar
        </Button>
      </Form>
    </FormikProvider>
  );
}
