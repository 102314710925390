import RecurringAppointment from '../model/RecurringAppointment';

export default interface BlockRecurrenceScheduleForm {
  title: string;
  initialTime: string;
  endTime: string;
  weekDays: string[];
  colaboratorId: number | null | undefined;
}
export const MapToSaveRecurrenceBlockSchedule = (
  form: BlockRecurrenceScheduleForm
): RecurringAppointment => {
  return {
    title: form.title,
    initialTime: form.initialTime,
    endTime: form.endTime,
    weekDays: form.weekDays,
    colaboratorId: form.colaboratorId ?? 0,
  };
};
