import * as Yup from 'yup';
import dictionary from '../../config/dictionary';

const ScheduleWithFinalClientNotRegistedValidation = Yup.object().shape({
  date: Yup.string().nullable().required(dictionary.global.validation.required),
  colaboratorId: Yup.string().required(dictionary.global.validation.required),
  finalClientNotRegisteredName: Yup.string().required(dictionary.global.validation.required)
});

export default ScheduleWithFinalClientNotRegistedValidation;
