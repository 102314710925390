import Address from "../model/Address";
import CreateAddress from "../model/CreateAddress";
import Register from "../model/Register";


export default interface RegisterEstablishmentForm { 
  nameSite?: string;
  establishmentType?: string | null;
  description?: string;
  socialName?: string;
  fantasyName?: string;
  name?: string;
  telephone?: string;
  email?: string;
  cnpj: string | null;
  matriz?: string;
  facebookURL?: string;
  gmailURL?: string;
  instagramURL?: string;
  whatsappURL?: string;
  startWork?: string;
  endWork?: string;
  street?: string;
  neighborhood?: string;
  number?: number;
  complements?: string;
  cep?: string;
  addressType?: string;
  state?: number | null;
  city?: number | null;
  ownerName: string;
  ownerEmail: string;
  ownerPassword: string;
  ownerDescription: string | undefined;
  ownerImagePerfil: any;
  ownerStartLunchTime: string
  ownerEndLunchTime: string
  ownerStartWork: string | null
  ownerEndWork: string | null
  ownerAge: any;
  ownerFacebookURL: string;
  ownerGmailURL: string;
  ownerInstagramURL: string;
  ownerWhatsappURL: string;
  ownerPasswordRepeat: string;
}


export const MapToRegister = (form: RegisterEstablishmentForm): Register => {

  return {
    name: form.name ?? "",
    nameSite: form.nameSite ?? "",
    cnpj: form.cnpj,
    email: form.gmailURL ?? "",
    establishmentType: String(form.establishmentType) ?? 0,
    telephone: form.telephone ?? "",
    startWork: form.startWork ?? "",
    endWork: form.endWork ?? "",
    owner: {
      name: form.ownerName,
      age: form.ownerAge,
      email: form.ownerEmail,
      login: form.ownerEmail,
      password: form.ownerPassword,
      startWork:  form.ownerStartWork,
      endWork: form.ownerEndWork,
      startLunchTime: form.ownerStartLunchTime,
      endLunchTime: form.ownerEndLunchTime,
      description: "Não preenchido",
      imagePerfil: {
        url: '',
      },
    },
    socialLinks: {
      facebookURL: form.facebookURL ?? "",
      gmailURL: form.gmailURL ?? "",
      instagramURL: form.instagramURL ?? "",
      whatsappURL: form.whatsappURL ?? "",
    }
  };
};

export const MapToAddress = (form: RegisterEstablishmentForm): CreateAddress => {
  return {
    street: form.street,
    neighborhood: form.neighborhood,
    number: form.number,
    complements: form.complements,
    city: form.city ?? 0,
    cep: form.cep,
    addressType: "ESTABLISHMENT",
  }
};

