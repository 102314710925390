import { useNavigate, useParams } from 'react-router-dom';
import {
  ContainerGif,
  Container,
  Content,
  Title,
  Text,
  ButtonReturn
} from './styles';

import completedRegister from '../../assets/svgs/finishedRegister.gif';
import { getTenantStorage, setTenantStorage } from '../../@core/infrastructure/api/services/storageService';
import { useEffect } from 'react';
import ConfirmationColaboratorAccountUseCase from '../../@core/application/confirmationAccount/confirmationColaboratorAccount';
import { useAlert } from 'react-alert';

export default function WelcomePage() {
  const navigate = useNavigate();
  const showAlert = useAlert();

  const { id, tenant } = useParams();
  
  useEffect(() => {
    setTenantStorage(tenant || "");

    const confirmationColaboratorAccountUseCase = new ConfirmationColaboratorAccountUseCase();

    confirmationColaboratorAccountUseCase.execute(Number(id ?? 0))
      .then(res => {})
      .catch(err => {
        showAlert.error('Algum erro ocorreu ao ativar sua conta. Tente novamente!');
      })
  }, [id, showAlert, tenant]);

  return (
     <Container>
      <ContainerGif>
        <img src={completedRegister} alt="Formulário concluído" />
      </ContainerGif>
      <Content>
        <Title>Como é bom ter você com a gente!</Title>
        <Text>Sua conta foi ativada com sucesso! Faça login agora para adicionar detalhes como serviços, colaboradores, imagens, logotipo, cores e outras opções de customização do app. Aproveite para personalizá-lo ao máximo!</Text>
        <ButtonReturn onClick={() => {
           const tenant = getTenantStorage();
           navigate(`/${tenant}/login`)
        }}>Ir para login</ButtonReturn>
      </Content>
     </Container>
  );
}
