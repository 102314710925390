import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import React, { useCallback, useEffect, useRef } from "react";
import { useAlert } from "react-alert";
import SortableList, { SortableItem } from "react-easy-sort";
import ImageGallery from "../../@core/domain/ui/ImageGallery";
import { normalizeBucketURIFallback } from "../../utils/BucketAccess";
import { AddIconContainer, Container, ContainerAddImage, ContainerImage, CoverContainer, Image, TextAddImage, TrashContainer, TrashIcon } from "./styles";
import "./styles.css";
import { CiTrash } from "react-icons/ci";

interface GalleryParams {
  images: ImageGallery[];
  addImageCallback: (newImageValue: string | ArrayBuffer | null, ref: File) => void;
  removeImageCallback: (image: ImageGallery) => void;
  imagesPositionsCallback: any;
}

export function DragGallery({
  images,
  addImageCallback,
  removeImageCallback,
  imagesPositionsCallback
}: GalleryParams) {

  const showAlert = useAlert();

  const newImageFile = useRef<HTMLInputElement>(document.createElement("input"));

  const changeNewImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileReader = new FileReader();
      const { files } = event.target;

      if (files?.length === 0) return;

      fileReader.onload = () => {
        if (newImageFile.current?.files)
          addImage(fileReader.result, newImageFile.current?.files[0])
      };

      fileReader.readAsDataURL(files![0]);
    },
    []
  );

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    imagesPositionsCallback((array: any) => arrayMove(array, oldIndex, newIndex));
  };

  const addImage = useCallback((newImageValue: string | ArrayBuffer | null, file: File) => {
    if (validateImage(file)) {
      let newImg = { 
        id: Math.floor(Math.random() * 1000000),
        valueImage: newImageValue, 
        isNew: true, 
        file 
      } as ImageGallery;
      imagesPositionsCallback((prevImages: any) => [...prevImages, newImg]);
    }

    addImageCallback(newImageValue, file)
  }, [addImageCallback]);

  const removeImage = useCallback((image: ImageGallery) => {
    console.log("image", image)
    console.log("items", images)

    if (image.isNew) {
      imagesPositionsCallback((prevImages: any) => prevImages.filter((img: any) => img.valueImage !== image.valueImage));
    }

    if (!image.isNew) {
      imagesPositionsCallback((prevImages: any) => prevImages.filter((img: any) => img.id !== image.id));
    }

    removeImageCallback(image)

  }, [images]);


  const validateImage = (image: File) => {
    const MAX_FILE_SIZE_MB = 8;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    console.log("tamanho imagem: ", image.size)
    console.log("MAX_FILE_SIZE_BYTES: ", MAX_FILE_SIZE_BYTES)

    if (image.size > MAX_FILE_SIZE_BYTES) {
      console.log(`Imagem excede o tamanho máximo de ${MAX_FILE_SIZE_MB}mb.`)
      showAlert.error('Imagem excede o tamanho máximo de 8mb.');
      return false;
    }

    return true
  };

  return (
    <Container>

      <SortableList
        onSortEnd={onSortEnd}
        className={"root"}
        draggedItemClassName={"dragged"}
      >
        {images.map((image, index) => (
          <SortableItem key={image.id}>
            <ContainerImage key={Math.random()}>
              <Image key={Math.random()} src={image.isNew ? image.valueImage : normalizeBucketURIFallback("SERVICE", image.name)} alt={"image"} />
              <TrashContainer>
                <TrashIcon>
                  <CiTrash onClick={() => removeImage(image)} />
                </TrashIcon>
              </TrashContainer>
              {index == 0 ? (
                <CoverContainer>
                  capa
                </CoverContainer>
              ) : (<></>)}             
            </ContainerImage>
          </SortableItem>
        ))}

          {images.length < 6 ? (
          <ContainerAddImage  htmlFor="imageService" key={Math.random()}>
            <AddIconContainer>
              <FontAwesomeIcon icon={faPlus} />
            </AddIconContainer>
            <TextAddImage>Adicionar Imagem</TextAddImage>
            <input
              id="imageService"
              ref={newImageFile || undefined}
              key={Math.random()} // Adiciona uma chave única para recriar o input quando necessário
              hidden
              name="imageService"
              type="file"
              accept="image/*"
              onChange={(event) => changeNewImage(event)}
            />
          </ContainerAddImage>
        ) : (<></>)}
      </SortableList>
    </Container>
  )
}
